import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../context";
import { useLocation, useNavigate } from "react-router-dom";
import useFetchObjects from "../../../../../api/useFetchObjects";
import useUpdate from "../../../../../api/useUpdate";

const EmployeeEdit = () => {
  const auth = useAuth();
  const token = auth?.user?.token;
  const location = useLocation();
  const userData = location.state;

  const [formData, setFormData] = useState({
    username: userData.username || "",
    email: userData.email || "",
    phone: userData.phone || "",
    first_name: userData.first_name || "",
    last_name: userData.last_name || "",
    salary_amount: userData.salary_amount || "",
    contract_date: userData.contract_date || "",
    available_contract_date: userData.available_contract_date || "",
    department: userData.department || "",
    position: userData.position || "",
    pharmacy_type: userData.pharmacy_type || "", // Added pharmacy_type
  });

  const [permissions, setPermissions] = useState({
    reception: userData.reception || false,
    pharmacy: userData.pharmacy || false,
    doctor: userData.doctor || false,
    nurse: userData.nurse || false,
    x_ray: userData.x_ray || false,
    ultrasounds: userData.ultrasounds || false,
    laboratory: userData.laboratory || false,
    echo: userData.echo || false,
    finance: userData.finance || false,
    hr: userData.hr || false,
    rooms: userData.rooms || false,
    operations: userData.operations || false,
  });

  const [limitedDataView, setLimitedDataView] = useState(
    userData.limited_data_view || false,
  );
  const [positionData, setPositionData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);

  const { data: positions, isSuccess: positionSuccess } = useFetchObjects(
    ["position"],
    "position",
    token,
  );
  const { data: departments, isSuccess: departmentSuccess } = useFetchObjects(
    ["departments"],
    "departments",
    token,
  );

  useEffect(() => {
    if (positionSuccess) setPositionData(positions);
  }, [positionSuccess, positions]);

  useEffect(() => {
    if (departmentSuccess) setDepartmentData(departments);
  }, [departmentSuccess, departments]);

  const { handleUpdate } = useUpdate("users", token, -1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePermissionChange = (e) => {
    const { name, checked } = e.target;
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [name]: checked,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedData = {
      ...formData,
      ...permissions,
      limited_data_view: limitedDataView,
    };
    handleUpdate(userData.id, updatedData);
  };

  return (
    <Container>
      <Box sx={{ paddingY: 4, maxWidth: "800px", marginX: "auto" }}>
        <Typography variant="h5" fontWeight={600} align="center" gutterBottom>
          Edit Employee
        </Typography>

        <Box sx={{ marginTop: 3 }}>
          <Typography fontWeight={500} gutterBottom>
            Personal Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="dense"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                type="email"
                variant="outlined"
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                type="number"
                variant="outlined"
                fullWidth
                margin="dense"
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ marginTop: 4 }}>
          <Typography fontWeight={500} gutterBottom>
            Office Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Salary Amount"
                name="salary_amount"
                value={formData.salary_amount}
                onChange={handleChange}
                type="number"
                variant="outlined"
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Contract Date"
                name="contract_date"
                value={formData.contract_date}
                onChange={handleChange}
                type="date"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Available Contract Date"
                name="available_contract_date"
                value={formData.available_contract_date}
                onChange={handleChange}
                type="date"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Position"
                name="position"
                value={formData.position}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="dense"
              >
                {positionData.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Department"
                name="department"
                value={formData.department}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="dense"
              >
                {departmentData.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Pharmacy Type"
                name="pharmacy_type"
                value={formData.pharmacy_type}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="dense"
              >
                <MenuItem value={1}>Pharmacy 1</MenuItem>
                <MenuItem value={2}>Pharmacy 2</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ marginTop: 4 }}>
          <Typography fontWeight={500} gutterBottom>
            Permissions
          </Typography>
          <Grid container spacing={2}>
            {Object.keys(permissions).map((permission) => (
              <Grid item xs={6} sm={4} md={3} key={permission}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions[permission]}
                      onChange={handlePermissionChange}
                      name={permission}
                    />
                  }
                  label={
                    permission.charAt(0).toUpperCase() +
                    permission.slice(1).replace("_", " ")
                  }
                />
              </Grid>
            ))}
            <Grid item xs={6} sm={4} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={limitedDataView}
                    onChange={(e) => setLimitedDataView(e.target.checked)}
                    name="limitedDataView"
                  />
                }
                label="Limited Data View"
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Update Employee
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default EmployeeEdit;
