import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Stack,
  Box,
  Chip,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Container,
  TextField,
  Button,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import SkeletonLoading from "./skeletonLoading/SkeletonLoading";
import ErrorPage from "../../../../Components/GeneralComponents/errorPage/ErrorPage";
import useUpdate from "../../../../api/useUpdate";
import { useTranslation } from "react-i18next";

const ApproveDiscount = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [searchTerm, setSearchTerm] = useState("");
  const [query, setQuery] = useState("");

  const { data, isLoading, isError, error, refetch } = useFetchObjects(
    ["collect-prescription"],
    `prescriptions/?search=${query}`,
    token
  );

  useEffect(() => {
    setQuery(searchTerm);
    refetch();
  }, [searchTerm, refetch]);

  const { handleUpdate, isLoading: updateLoading } = useUpdate(
    "prescriptions",
    token
  );

  const handleApproveDiscount = (id) => {
    const formData = new FormData();
    formData.append("is_discount_approved", true); // Set discount approval to true
    handleUpdate(id, formData);
  };

  const getPaymentStatusLabel = (status) => {
    switch (status) {
      case 1:
        return t("reception.pending");
      case 2:
        return t("reception.paid");
      case 3:
        return t("reception.canceled");
      default:
        return t("reception.unknown");
    }
  };

  const getPaymentStatusColor = (status) => {
    switch (status) {
      case 1:
        return "warning";
      case 2:
        return "success";
      case 3:
        return "error";
      default:
        return "default";
    }
  };

  if (isLoading) {
    return <SkeletonLoading />;
  }

  if (isError) {
    return <ErrorPage errorMessage={error.message} />;
  }

  return (
    <Container maxWidth="xl">
      <Box my={4}>
        <TextField
          fullWidth
          label={t("reception.searchPrescription")}
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        />
      </Box>

      <Grid container spacing={3} padding="20px" mt={3}>
        {data.length === 0 ? (
          <Typography>{t("reception.noMatching")}</Typography>
        ) : (
          data.map((prescription, index) => {
            const isPaid = prescription.payment_status === 2; // Check if the prescription is paid
            const isApproved = prescription.is_discount_approved;
            const hasDiscount = parseFloat(prescription.discount) > 0; // Check if discount exists

            return (
              <Grid item xs={12} md={6} key={index}>
                <Paper
                  elevation={isPaid ? 2 : 4}
                  sx={{
                    mb: 3,
                    padding: "24px",
                    borderRadius: "12px",
                    width: "100%",
                    boxShadow: isPaid
                      ? "0px 4px 12px rgba(0, 0, 0, 0.1)"
                      : "0px 8px 24px rgba(255, 0, 0, 0.3)",
                    backgroundColor: hasDiscount
                      ? "#fffde7" // Light yellow for discounted prescriptions
                      : isPaid
                      ? "#f9f9f9"
                      : "#ffebee",
                    borderLeft: hasDiscount
                      ? "4px solid #ffb300" // Yellow border for discounted prescriptions
                      : isPaid
                      ? "none"
                      : "4px solid #d32f2f",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color="primary"
                      sx={{ fontSize: isSmallScreen ? "1rem" : "1.25rem" }}
                    >
                      {t("reception.prescription")} #{prescription.id} -{" "}
                      {prescription.date}
                    </Typography>
                    <Box>
                      <Chip
                        label={getPaymentStatusLabel(
                          prescription.payment_status
                        )}
                        color={getPaymentStatusColor(
                          prescription.payment_status
                        )}
                        sx={{
                          fontWeight: "bold",
                          fontSize: isSmallScreen ? "0.8rem" : "0.9rem",
                        }}
                      />
                    </Box>
                  </Box>

                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ mt: 2 }}
                  >
                    <Typography
                      fontSize={isSmallScreen ? "0.85rem" : "1rem"}
                      color={!isPaid ? "error.main" : "text.primary"}
                    >
                      <strong>{t("reception.doctor")}:</strong>{" "}
                      {prescription.doctor_display}
                    </Typography>
                    <Typography fontSize={isSmallScreen ? "0.85rem" : "1rem"}>
                      <strong>{t("reception.patient")}:</strong>{" "}
                      {prescription.patient_display}
                    </Typography>
                    <Typography fontSize={isSmallScreen ? "0.85rem" : "1rem"}>
                      <strong>{t("reception.totalPrice")}:</strong>{" "}
                      {prescription.total_price} ؋
                    </Typography>
                    {hasDiscount && (
                      <Typography
                        fontSize={isSmallScreen ? "0.85rem" : "1rem"}
                        color="secondary"
                      >
                        <strong>{t("reception.discountAmount")}:</strong>{" "}
                        {prescription.discount} ؋
                      </Typography>
                    )}
                  </Stack>

                  {/* Approve Discount Button */}
                  {!isPaid && hasDiscount && !isApproved && (
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      spacing={2}
                      sx={{ mt: 2 }}
                    >
                      <Button
                        variant="contained"
                        fullWidth={isSmallScreen}
                        disabled={updateLoading}
                        startIcon={
                          updateLoading && <CircularProgress size={24} />
                        }
                        onClick={() => handleApproveDiscount(prescription.id)}
                        sx={{
                          minWidth: 150,
                          background:
                            "linear-gradient(90deg, #4caf50, #81c784)",
                          color: "#fff",
                          fontWeight: "bold",
                          borderRadius: "8px",
                          padding: "10px 20px",
                          transition: "0.3s",
                          "&:hover": {
                            background:
                              "linear-gradient(90deg, #81c784, #4caf50)",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                          },
                        }}
                      >
                        {updateLoading
                          ? t("reception.loading")
                          : t("reception.approveDiscount")}
                      </Button>
                    </Stack>
                  )}

                  {isApproved && (
                    <Typography
                      variant="body1"
                      color="success.main"
                      fontWeight="bold"
                      sx={{ mt: 2 }}
                    >
                      {t("reception.approved")}
                    </Typography>
                  )}

                  <Divider sx={{ mt: 2, mb: 2 }} />

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        fontWeight="bold"
                        color={!isPaid ? "error.main" : "text.primary"}
                      >
                        {t("reception.medicinePrescription")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {prescription.drugs && prescription.drugs.length > 0 ? (
                        <Box sx={{ overflowX: "auto" }}>
                          <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">
                                  {t("reception.medicine")}
                                </TableCell>
                                <TableCell align="center">
                                  {t("reception.dosage")}
                                </TableCell>
                                <TableCell align="center">
                                  {t("reception.quantity")}
                                </TableCell>
                                <TableCell align="center">
                                  {t("reception.notation")}
                                </TableCell>
                                <TableCell align="center">
                                  {t("reception.isBeforeMeal")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {prescription.drugs.map((drug) => (
                                <TableRow key={drug.id}>
                                  <TableCell align="center">
                                    <Typography fontWeight="bold">
                                      {drug.stock_medicine_display}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    {drug.dosage}
                                  </TableCell>
                                  <TableCell align="center">
                                    {drug.quantity}
                                  </TableCell>
                                  <TableCell align="center">
                                    {drug.notation}
                                  </TableCell>
                                  <TableCell align="center">
                                    {drug.is_before}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      ) : (
                        <Typography>{t("reception.noMedicine")}</Typography>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Paper>
              </Grid>
            );
          })
        )}
      </Grid>
    </Container>
  );
};

export default ApproveDiscount;
