import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Paper,
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  CircularProgress,
  Alert,
  IconButton,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import DischargeDialog from "./DischargeDialog";
import useUpdate from "../../../../api/useUpdate";
import AssignedRoomPrint from "./AssignedRoomPrint";
import { useTranslation } from "react-i18next";
import EditAssignedRoomDialog from "./EditAssignedRoomDialog";
import useDelete from "../../../../api/useDelete";

const AssignedRoomSummary = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const [searchTerm, setSearchTerm] = useState("");
  const [isActiveFilter, setIsActiveFilter] = useState(true);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [isDischargeDialogOpen, setDischargeDialogOpen] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [printData, setPrintData] = useState(null);
  const printRef = useRef();

  const { data, isLoading, isError, error, refetch } = useFetchObjects(
    ["room-assignment"],
    `room-assignment/?search=${searchTerm}&is_active=${isActiveFilter}`,
    token,
  );

  const { handleUpdate } = useUpdate("room-assignment", token);
  const { handleDelete, ConfirmDialog } = useDelete("room-assignment", token);

  useEffect(() => {
    refetch();
  }, [searchTerm, isActiveFilter]);

  const handleDischargeClick = (assignment) => {
    setSelectedRoom(assignment);
    setDischargeDialogOpen(true);
  };

  const handleDischargeDialogClose = () => {
    setDischargeDialogOpen(false);
    setSelectedRoom(null);
  };

  const handleSaveDischargeDate = (dischargeDate, discount) => {
    const payload = {
      discharge_date: dischargeDate,
      discount: discount,
      is_active: false,
      is_paid: true,
    };
    handleUpdate(selectedRoom.id, payload);
    setDischargeDialogOpen(false);
    refetch();
  };

  const handleEditClick = (assignment) => {
    setSelectedRoom(assignment);
    setEditDialogOpen(true);
  };

  const handleEditSave = (updatedData) => {
    handleUpdate(selectedRoom.id, updatedData);
    setEditDialogOpen(false);
    refetch();
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedRoom(null);
  };

  const handlePrintClick = (assignment) => {
    setPrintData(assignment);
    setTimeout(() => {
      if (printRef.current) {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(printRef.current.innerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }
    }, 500);
  };

  return (
    <Grid
      container
      spacing={4}
      maxWidth={"xl"}
      mt={2}
      justifyContent={"center"}
      mb={10}
    >
      <Grid item xs={12} lg={10}>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          {/* Search Bar Section */}
          <Box mb={4}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("room.assignRoom")}
            </Typography>
            <TextField
              label={t("room.searchRoomOrPatient")}
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: "100%", mt: 2 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isActiveFilter}
                  onChange={(e) => setIsActiveFilter(e.target.checked)}
                  color="primary"
                />
              }
              label={t("room.showActiveRoomsOnly")}
              sx={{ mt: 2 }}
            />
          </Box>
          <Divider sx={{ marginY: 3 }} />
          {isLoading && (
            <Box display="flex" justifyContent="center" mt={4}>
              <CircularProgress />
            </Box>
          )}
          {isError && (
            <Box mt={4}>
              <Alert severity="error">Error: {error.message}</Alert>
            </Box>
          )}
          {data && data.length > 0 && (
            <TableContainer
              component={Paper}
              sx={{ marginTop: 3, boxShadow: 3, borderRadius: 2 }}
            >
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                    <TableCell align="center">{t("room.roomNumber")}</TableCell>
                    <TableCell align="center">
                      {t("room.patientName")}
                    </TableCell>
                    <TableCell align="center">
                      {t("room.assignedDate")}
                    </TableCell>
                    <TableCell align="center">
                      {t("room.dischargeDate")}
                    </TableCell>
                    <TableCell align="center">{t("room.status")}</TableCell>
                    <TableCell align="center">
                      {t("room.paymentStatus")}
                    </TableCell>
                    <TableCell align="center">
                      {t("room.totalAmount")}
                    </TableCell>
                    <TableCell align="center">
                      {t("room.discountAmount")}
                    </TableCell>
                    <TableCell align="center">{t("room.actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((assignment) => (
                    <TableRow key={assignment.id}>
                      <TableCell align="center">
                        {assignment.room_display}
                      </TableCell>
                      <TableCell align="center">
                        {assignment.patient_display}
                      </TableCell>
                      <TableCell>
                        {new Date(
                          assignment.assigned_date,
                        ).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="center">
                        {assignment.discharge_date
                          ? new Date(
                              assignment.discharge_date,
                            ).toLocaleDateString()
                          : t("room.notDischarged")}
                      </TableCell>
                      <TableCell align="center">
                        {assignment.is_active ? (
                          <IconButton color="success">
                            <CheckCircleIcon />
                          </IconButton>
                        ) : (
                          <IconButton color="error">
                            <CancelIcon />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {assignment.is_paid ? (
                          <IconButton color="primary">
                            <MonetizationOnIcon />
                          </IconButton>
                        ) : (
                          <IconButton color="warning">
                            <MoneyOffIcon />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {assignment.total_amount
                          ? `؋${assignment.total_amount}`
                          : "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        {assignment.discount
                          ? `؋${assignment.discount}`
                          : t("room.noDiscount")}
                      </TableCell>
                      <TableCell align="center">
                        {assignment.is_active && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleDischargeClick(assignment)}
                          >
                            {t("room.discharge")}
                          </Button>
                        )}
                        <IconButton
                          color="primary"
                          onClick={() => handlePrintClick(assignment)}
                        >
                          <PrintIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleEditClick(assignment)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => handleDelete(assignment.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!isLoading && data?.length === 0 && (
            <Box mt={4}>
              <Alert severity="info">{t("room.noRoomAssignments")}</Alert>
            </Box>
          )}
        </Paper>
      </Grid>

      <ConfirmDialog />

      {/* Discharge Dialog */}
      {selectedRoom && (
        <DischargeDialog
          open={isDischargeDialogOpen}
          onClose={handleDischargeDialogClose}
          onSave={handleSaveDischargeDate}
        />
      )}

      {/* Edit Dialog */}
      {selectedRoom && (
        <EditAssignedRoomDialog
          open={isEditDialogOpen}
          onClose={handleEditDialogClose}
          onSave={handleEditSave}
          assignment={selectedRoom}
        />
      )}

      {/* Print Component */}
      {printData && (
        <div ref={printRef} style={{ display: "none" }}>
          <AssignedRoomPrint data={printData} />
        </div>
      )}
    </Grid>
  );
};

export default AssignedRoomSummary;
