import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import useUpdate from "../../../../api/useUpdate";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";

const UpdateLaboratoryTypeDialog = ({
  open,
  onClose,
  type,
  token,
  refetch,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    cost: "",
    parent_test: null,
    category: null,
    min_value: "",
    max_value: "",
    units: "",
  });

  const { data: parentTests } = useFetchObjects(
    ["laboratory-tests"],
    "laboratory-tests/",
    token,
  );
  const { data: categories } = useFetchObjects(
    ["laboratory-categories"],
    "laboratory-test-categories/",
    token,
  );

  const { handleUpdate, loading } = useUpdate("laboratory-tests", token);

  useEffect(() => {
    if (type) {
      setFormData({
        name: type.name || "",
        description: type.description || "",
        cost: type.cost || "",
        parent_test: type.parent_test_display || null, // Use `parent_test_display`
        category: type.category_display || null, // Use `category_display`
        min_value: type.min_value || "",
        max_value: type.max_value || "",
        units: type.units || "",
      });
    }
  }, [type]);

  const handleSubmit = () => {
    const updatedData = {
      name: formData.name,
      description: formData.description,
      cost: formData.cost ? parseFloat(formData.cost) : null,
      parent_test: formData.parent_test?.id || null,
      category: formData.category?.id || null,
      min_value: formData.min_value ? parseFloat(formData.min_value) : null,
      max_value: formData.max_value ? parseFloat(formData.max_value) : null,
      units: formData.units,
    };

    handleUpdate(type.id, updatedData);
    refetch();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("laboratory.updateLabTestType")}</DialogTitle>
      <DialogContent>
        {/* Laboratory Test Name */}
        <TextField
          label={t("laboratory.laboratoryTestName")}
          fullWidth
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          sx={{ mb: 3, mt: 2 }}
        />

        {/* Category Selection */}
        <Autocomplete
          options={categories || []}
          getOptionLabel={(option) => option.name}
          value={formData.category}
          onChange={(e, value) => setFormData({ ...formData, category: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("laboratory.selectCategory")}
              fullWidth
              sx={{ mb: 3 }}
            />
          )}
        />

        {/* Parent Test Selection */}
        <Autocomplete
          options={parentTests || []}
          getOptionLabel={(option) => option.name}
          value={formData.parent_test}
          onChange={(e, value) =>
            setFormData({ ...formData, parent_test: value })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("laboratory.parentTest")}
              fullWidth
              sx={{ mb: 3 }}
            />
          )}
        />

        {/* Description */}
        <TextField
          label={t("laboratory.description")}
          fullWidth
          multiline
          rows={4}
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          sx={{ mb: 3 }}
        />

        {/* Cost */}
        <TextField
          label={t("laboratory.cost")}
          fullWidth
          type="number"
          value={formData.cost}
          onChange={(e) => setFormData({ ...formData, cost: e.target.value })}
          sx={{ mb: 3 }}
        />

        {/* Min Value */}
        <TextField
          label={t("laboratory.minValue")}
          fullWidth
          type="number"
          value={formData.min_value}
          onChange={(e) =>
            setFormData({ ...formData, min_value: e.target.value })
          }
          sx={{ mb: 3 }}
        />

        {/* Max Value */}
        <TextField
          label={t("laboratory.maxValue")}
          fullWidth
          type="number"
          value={formData.max_value}
          onChange={(e) =>
            setFormData({ ...formData, max_value: e.target.value })
          }
          sx={{ mb: 3 }}
        />

        {/* Units */}
        <TextField
          label={t("laboratory.units")}
          fullWidth
          value={formData.units}
          onChange={(e) => setFormData({ ...formData, units: e.target.value })}
          sx={{ mb: 3 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          {t("laboratory.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={loading}
          sx={{
            textTransform: "none",
            fontWeight: "bold",
            margin: "0px 10px",
          }}
        >
          {loading ? t("laboratory.updating") : t("laboratory.update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLaboratoryTypeDialog;
