import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import useUpdate from "../../../../api/useUpdate";
import { useTranslation } from "react-i18next";

const UpdateLaboratoryCategoryDialog = ({
  open,
  onClose,
  category,
  token,
  refetch,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: category.name,
  });

  const { handleUpdate, loading } = useUpdate(
    "laboratory-test-categories",
    token,
  );

  useEffect(() => {
    setFormData({
      name: category.name,
    });
  }, [category]);

  const handleSubmit = () => {
    handleUpdate(category.id, formData);
    refetch();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("laboratory.updateLabCategory")}</DialogTitle>
      <DialogContent>
        <TextField
          label={t("laboratory.categoryName")}
          fullWidth
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          sx={{ mb: 3, mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          {t("laboratory.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={loading}
          sx={{
            textTransform: "none",
            fontWeight: "bold",
            margin: "0px 10px",
          }}
        >
          {loading ? t("laboratory.updating") : t("laboratory.update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLaboratoryCategoryDialog;
