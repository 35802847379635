import React, { useEffect, useState, useRef } from "react";
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
  Tooltip,
  Card,
  CardContent,
  InputAdornment,
} from "@mui/material";
import { RemoveCircleOutline, Search } from "@mui/icons-material";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context";
import { useTranslation } from "react-i18next";
import SurgeryPrescriptionList from "./SurgeryPrescriptionList";

const getCurrentDate = () => new Date().toISOString().split("T")[0];
const getCurrentTime = () =>
  new Date().toTimeString().split(":").slice(0, 2).join(":");

const SurgeryPrescription = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const pharmacyType = auth?.user?.user.pharmacy_type || "";

  const { data: surgeries } = useFetchObjects(
    ["surgeries"],
    "surgeries",
    token
  );
  const { data: medicines } = useFetchObjects(
    ["stock-medicines"],
    `stock-medicines/?pharmacy=${pharmacyType}`,
    token
  );

  const [input, setInput] = useState({
    date: getCurrentDate(),
    time: getCurrentTime(),
    surgery: "",
    total_price: 0,
    details: [],
  });

  const [pharmacyPercentage, setPharmacyPercentage] = useState(0);
  const [totalMedicineCost, setTotalMedicineCost] = useState(0);
  const [warning, setWarning] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const autocompleteRef = useRef(null);

  const [existingPrescriptions, setExistingPrescriptions] = useState([]);

  const {
    handleAdd: handleAddPrescription,
    resetForm,
    loading,
  } = useAdd(
    "surgery-prescriptions",
    token,
    t("pharmacy.surgeryPrescriptionCreatedSuccess"),
    t("pharmacy.surgeryPrescriptionCreateFail")
  );

  useEffect(() => {
    if (resetForm) {
      setInput({
        date: getCurrentDate(),
        time: getCurrentTime(),
        surgery: "",
        total_price: 0,
        details: [],
      });
      setFormErrors({});
      setWarning("");
      setPharmacyPercentage(0);
      setTotalMedicineCost(0);
      setExistingPrescriptions([]);
    }
  }, [resetForm]);

  // Fetch existing prescriptions when surgery changes
  useEffect(() => {
    const fetchExistingPrescriptions = async () => {
      if (input.surgery) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/surgery-prescriptions/?surgery=${input.surgery}`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
          if (response.ok) {
            const data = await response.json();
            setExistingPrescriptions(data);

            // Calculate total cost of existing medicines
            const totalCost = data.reduce((sum, prescription) => {
              return sum + (prescription.total_price || 0);
            }, 0);
            setTotalMedicineCost(totalCost);

            // Recalculate warning with new totalMedicineCost
            const updatedTotalPrice = input.total_price + totalCost;
            if (pharmacyPercentage && updatedTotalPrice > pharmacyPercentage) {
              setWarning(
                `The total medicine cost (${updatedTotalPrice}) exceeds the pharmacy percentage (${pharmacyPercentage}) for this surgery.`
              );
            } else {
              setWarning("");
            }
          } else {
            console.error("Failed to fetch existing prescriptions");
          }
        } catch (error) {
          console.error("Error fetching existing prescriptions:", error);
        }
      } else {
        setExistingPrescriptions([]);
        setTotalMedicineCost(0);
        setWarning("");
      }
    };

    fetchExistingPrescriptions();
  }, [input.surgery, pharmacyPercentage, token]);

  const handleSurgerySelection = (event, value) => {
    const selectedSurgery = surgeries?.find(
      (surgery) => surgery.id === value?.id
    );

    if (selectedSurgery) {
      setInput({ ...input, surgery: selectedSurgery.id });
      setPharmacyPercentage(selectedSurgery.pharmacy_percentage || 0);
    } else {
      setInput({ ...input, surgery: "" });
      setPharmacyPercentage(0);
    }
  };

  const handleAddNewItem = (medicine) => {
    if (medicine) {
      setInput((prevInput) => {
        const newDetails = [
          ...prevInput.details,
          {
            stock_medicine: medicine.id,
            name: medicine.medicine_display,
            quantity: 1,
            sell_price: medicine.sell_price,
            total_price: medicine.sell_price,
            available_stock: medicine.quantity,
          },
        ];

        const updatedTotalPrice =
          parseFloat(calculateTotalPrice(newDetails)) + totalMedicineCost;

        // Check if the updated total exceeds the pharmacy percentage
        if (pharmacyPercentage && updatedTotalPrice > pharmacyPercentage) {
          setWarning(
            `The total medicine cost (${updatedTotalPrice}) exceeds the pharmacy percentage (${pharmacyPercentage}) for this surgery.`
          );
        } else {
          setWarning("");
        }

        return {
          ...prevInput,
          details: newDetails,
          total_price: calculateTotalPrice(newDetails),
        };
      });

      setAutocompleteInputValue("");
      if (autocompleteRef.current) {
        autocompleteRef.current.focus();
      }
    }
  };

  const handleChangeItemField = (index, e) => {
    const { name, value } = e.target;
    const details = [...input.details];
    details[index][name] = value ? parseInt(value) || value : "";
    if (name === "quantity" || name === "sell_price") {
      details[index].total_price =
        details[index].sell_price * details[index].quantity;
    }

    const updatedTotalPrice =
      parseFloat(calculateTotalPrice(details)) + totalMedicineCost;

    // Check against pharmacy percentage
    if (pharmacyPercentage && updatedTotalPrice > pharmacyPercentage) {
      setWarning(
        `The total medicine cost (${updatedTotalPrice}) exceeds the pharmacy percentage (${pharmacyPercentage}) for this surgery.`
      );
    } else {
      setWarning("");
    }

    setInput({ ...input, details, total_price: calculateTotalPrice(details) });
  };

  const handleRemoveItem = (index) => {
    const details = [...input.details];
    details.splice(index, 1);
    const updatedTotalPrice =
      parseFloat(calculateTotalPrice(details)) + totalMedicineCost;

    // Check against pharmacy percentage
    if (pharmacyPercentage && updatedTotalPrice > pharmacyPercentage) {
      setWarning(
        `The total medicine cost (${updatedTotalPrice}) exceeds the pharmacy percentage (${pharmacyPercentage}) for this surgery.`
      );
    } else {
      setWarning("");
    }

    setInput({ ...input, details, total_price: calculateTotalPrice(details) });
  };

  const calculateTotalPrice = (details) =>
    details
      .reduce((sum, item) => sum + parseFloat(item.total_price || 0), 0)
      .toFixed(2);

  const handleSave = (e) => {
    e.preventDefault();
    let isValid = true;
    let errors = {};

    if (!input.date) {
      errors.date = t("pharmacy.dateRequired");
      isValid = false;
    }
    if (!input.surgery) {
      errors.surgery = t("pharmacy.surgeryRequired");
      isValid = false;
    }
    input.details.forEach((item, index) => {
      if (!item.stock_medicine) {
        errors[`stock_medicine_${index}`] = t("pharmacy.medicineRequired");
        isValid = false;
      }
      if (!item.quantity) {
        errors[`quantity_${index}`] = t("pharmacy.quantityRequired");
        isValid = false;
      }
    });

    setFormErrors(errors);
    if (isValid) {
      handleAddPrescription(input);
    }
  };

  return (
    <Card
      sx={{
        boxShadow: "0px 20px 50px rgba(0, 0, 0, 0.1)",
        borderRadius: 4,
        backgroundColor: "#f4f6f8",
        width: "100%",
      }}
    >
      <CardContent sx={{ p: 4 }}>
        <Typography
          variant="h5"
          fontWeight={600}
          color="primary"
          textAlign="center"
          gutterBottom
        >
          {t("pharmacy.addSurgeryPrescriptionTitle")}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Box mb={4}>
          <Typography variant="subtitle1" fontWeight={500} mb={2}>
            {t("pharmacy.surgeryInfo")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={surgeries || []}
                getOptionLabel={(option) =>
                  option ? `${option.patient_display} (ID: ${option.id})` : ""
                }
                value={
                  surgeries?.find((surgery) => surgery.id === input.surgery) ||
                  null
                }
                onChange={handleSurgerySelection}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("pharmacy.surgery")}
                    variant="outlined"
                    error={!!formErrors.surgery}
                    helperText={formErrors.surgery}
                    sx={{ backgroundColor: "#fff", borderRadius: 2 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box mb={4}>
          <Typography variant="subtitle1" fontWeight={500} mb={2}>
            {t("pharmacy.addMedicine")}
          </Typography>
          <Autocomplete
            ref={autocompleteRef}
            value={null}
            inputValue={autocompleteInputValue}
            onInputChange={(event, newInputValue) => {
              setAutocompleteInputValue(newInputValue);
            }}
            onChange={(event, value) => {
              handleAddNewItem(value);
              setAutocompleteInputValue("");
            }}
            options={
              autocompleteInputValue.length > 0 && medicines
                ? medicines.filter((medicine) =>
                    medicine.medicine_display
                      .toLowerCase()
                      .startsWith(autocompleteInputValue.toLowerCase())
                  )
                : []
            }
            getOptionLabel={(option) =>
              `${option.medicine_display} (${option.category_display})`
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                sx={{
                  padding: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  backgroundColor: "#f9f9f9",
                  borderRadius: 1,
                  "&:hover": {
                    backgroundColor: "#eef2f5",
                  },
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight={600}
                  sx={{ whiteSpace: "nowrap", color: "text.primary" }}
                >
                  {option.medicine_display}
                </Typography>
                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: "nowrap",
                    color: "text.secondary",
                  }}
                >
                  <strong>{t("pharmacy.category")}:</strong>{" "}
                  {option.category_display}
                </Typography>
                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                <Typography
                  variant="body2"
                  sx={{ whiteSpace: "nowrap", color: "text.secondary" }}
                >
                  <strong>{t("pharmacy.price")}:</strong> {option.sell_price}
                </Typography>
                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                <Typography
                  variant="body2"
                  sx={{ whiteSpace: "nowrap", color: "text.secondary" }}
                >
                  <strong>{t("pharmacy.expiryDate")}:</strong>{" "}
                  {option.expiry_date}
                </Typography>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("pharmacy.searchMedicine")}
                variant="outlined"
                placeholder={t("pharmacy.searchMedicinePlaceholder")}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: 2,
                }}
              />
            )}
          />
        </Box>

        <TableContainer component={Paper} elevation={1} sx={{ mb: 4 }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#eef2f5" }}>
              <TableRow>
                <TableCell>{t("pharmacy.medicineName")}</TableCell>
                <TableCell align="center">
                  {t("pharmacy.availableStock")}
                </TableCell>
                <TableCell align="center">{t("pharmacy.quantity")}</TableCell>
                <TableCell align="center">{t("pharmacy.price")}</TableCell>
                <TableCell align="center">{t("pharmacy.totalPrice")}</TableCell>
                <TableCell align="center">{t("pharmacy.action")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {input.details.map((item, index) => (
                <TableRow key={index} hover>
                  <TableCell>{item.name}</TableCell>
                  <TableCell align="center">{item.available_stock}</TableCell>
                  <TableCell align="center">
                    <TextField
                      name="quantity"
                      type="number"
                      size="small"
                      variant="outlined"
                      value={item.quantity}
                      onChange={(e) => handleChangeItemField(index, e)}
                      error={!!formErrors[`quantity_${index}`]}
                      helperText={formErrors[`quantity_${index}`]}
                      sx={{ backgroundColor: "#fff", borderRadius: 2 }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{item.sell_price}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={600}>{item.total_price}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={t("pharmacy.removeItem")} arrow>
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveItem(index)}
                      >
                        <RemoveCircleOutline />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {warning && (
          <Typography
            variant="body2"
            color="error"
            sx={{ mt: 2, fontWeight: 600 }}
          >
            {warning}
          </Typography>
        )}

        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <TextField
            size="small"
            label={t("pharmacy.totalPrice")}
            type="number"
            value={input.total_price}
            sx={{ width: 200, backgroundColor: "#fff", borderRadius: 2 }}
            disabled
          />
          <Button
            size="small"
            onClick={handleSave}
            variant="contained"
            disabled={loading}
            sx={{
              fontWeight: 700,
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}
          >
            {loading
              ? t("pharmacy.savingSurgeryPrescription")
              : t("pharmacy.saveSurgeryPrescription")}
          </Button>
        </Stack>
      </CardContent>

      <Box mt={4}>
        <SurgeryPrescriptionList />
      </Box>
    </Card>
  );
};

export default SurgeryPrescription;
