// src/routes/humanResourceRoutes.js
import HumanResourceNav from "../../pages/Dashboard/HumanResource/HumanResourceNav";
import HumanResourceIndex from "../../pages/Dashboard/HumanResource/HumanResourceIndex";
import EmployeeLeave from "../../pages/Dashboard/HumanResource/EmployeeLeave/EmployeeLeave";
import EmployeesList from "../../pages/Dashboard/HumanResource/Employees/EmployeesList/EmployeesList";
import Evaluation from "../../pages/Dashboard/HumanResource/Evaluation/Evaluation";
import PositionsIndex from "../../pages/Dashboard/HumanResource/Postisons/PositionsIndex";
import DepartmentsIndex from "../../pages/Dashboard/HumanResource/Departments/DepartmentsIndex";
import AttendanceReport2 from "../../pages/Dashboard/HumanResource/Attendance/AttendanceReport2";
import EmployeeAdd from "../../pages/Dashboard/HumanResource/Employees/EmployeeAdd/EmployeeAdd";
import EmployeeEdit from "../../pages/Dashboard/HumanResource/Employees/EmployeeEdit/EmployeeEdit";
import Backup from "../../pages/Dashboard/HumanResource/backup/Backup";

const humanResourceRoutes = {
  path: "/human-resource",
  element: <HumanResourceNav />,
  children: [
    { path: "/human-resource", element: <HumanResourceIndex /> },
    { path: "/human-resource/attendance", element: <AttendanceReport2 /> },
    { path: "/human-resource/departments", element: <DepartmentsIndex /> },
    { path: "/human-resource/employee-leave", element: <EmployeeLeave /> },
    { path: "/human-resource/employees/", element: <EmployeesList /> },
    { path: "/human-resource/employees/add", element: <EmployeeAdd /> },
    { path: "/human-resource/employees/edit", element: <EmployeeEdit /> },
    { path: "/human-resource/evaluation", element: <Evaluation /> },
    { path: "/human-resource/positions", element: <PositionsIndex /> },
    { path: "/human-resource/backup", element: <Backup /> },
  ],
};

export default humanResourceRoutes;
