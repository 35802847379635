import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";
import useUpdate from "../../../../api/useUpdate";

const OperationsList = () => {
  const auth = useAuth();
  const token = auth?.user?.token;

  const { t } = useTranslation();

  const { data, isLoading, isError } = useFetchObjects(
    ["surgeries"],
    `surgeries/`,
    token
  );

  const { handleUpdate } = useUpdate("surgeries", token);

  const handleSwitchChange = (id, currentValue) => {
    const updatedValue = !currentValue;
    handleUpdate(id, { visible: updatedValue });
  };

  return (
    <Box sx={{ px: 3, py: 2, bgcolor: "#f5f7fa", minHeight: "100vh" }}>
      <Typography variant="h5" gutterBottom>
        {t("finance.operationsList")}
      </Typography>

      {/* Table Section */}
      {isLoading ? (
        <Box sx={{ textAlign: "center", mt: 3 }}>{t("finance.loading")}</Box>
      ) : isError ? (
        <Box sx={{ textAlign: "center", color: "red", mt: 3 }}>
          {t("finance.errorFetchingData")}
        </Box>
      ) : (
        <>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: 2,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              mt: 3,
            }}
          >
            <Table>
              <TableHead sx={{ background: "#007174" }}>
                <TableRow>
                  <TableCell align="center" sx={{ color: "white" }}>
                    {t("finance.patient")}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "white" }}>
                    {t("finance.surgeryType")}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "white" }}>
                    {t("finance.surgeryDate")}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "white" }}>
                    {t("finance.doctor")}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "white" }}>
                    {t("finance.co_doctor")}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "white" }}>
                    {t("finance.anesthetist")}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "white" }}>
                    {t("finance.status")}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "white" }}>
                    {t("finance.total_amount")}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "white" }}>
                    {t("finance.action")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((surgery, i) => (
                  <TableRow key={i}>
                    <TableCell align="center">
                      {surgery.patient_display}
                    </TableCell>
                    <TableCell align="center">
                      {surgery.surgery_type_display}
                    </TableCell>
                    <TableCell align="center">
                      {new Date(surgery.surgery_date).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="center">
                      {surgery.doctor}
                    </TableCell>
                    <TableCell align="center">
                      {surgery.co_doctor}
                    </TableCell>
                    <TableCell align="center">
                      {surgery.anesthetist || "N/A"}
                    </TableCell>
                    <TableCell align="center">
                      {surgery.surgery_state_display}
                    </TableCell>
                    <TableCell align="center">
                      {surgery.total_surgery_amount
                        ? `؋${surgery.total_surgery_amount}`
                        : "N/A"}
                    </TableCell>
                    <TableCell align="center">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={surgery.visible}
                              onChange={() =>
                                handleSwitchChange(surgery.id, surgery.visible)
                              }
                            />
                          }
                        />
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default OperationsList;
