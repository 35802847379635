import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Paper,
  Divider,
  CircularProgress,
} from "@mui/material";
import useAdd from "../../../../../api/useAdd";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../../context";

const buttonStyle = {
  backgroundColor: "#071952",
  color: "white",
  textTransform: "capitalize",
  margin: "0 10px",
};

const AddExpensesType = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
  });

  const { handleAdd, resetForm, loading } = useAdd(
    "expense-types",
    token,
    t("finance.addedSuccess"),
    t("finance.addFailed"),
  );

  useEffect(() => {
    if (resetForm) {
      setFormData({ name: "" });
      setFormErrors({});
    }
  }, [resetForm]);

  const handleSave = (e) => {
    e.preventDefault();
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = t("finance.nameRequired");
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      handleAdd(formData);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Grid container mt={3}>
      <Grid item xs={12} md={12}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 3 }}>
          <Typography
            variant="h5"
            fontWeight="bold"
            mb={2}
            color="primary"
            textAlign="center"
          >
            {t("finance.addExpenseType")}
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <Box component="form" onSubmit={handleSave}>
            <TextField
              fullWidth
              size="small"
              label={t("finance.expenseTypeName")}
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              error={!!formErrors.name}
              helperText={formErrors.name}
              sx={{ mb: 3 }}
            />

            <Grid container justifyContent="flex-end" mt={3}>
              <Button
                variant="contained"
                style={buttonStyle}
                disabled={loading}
                type="submit"
              >
                {loading ? <CircularProgress size={20} /> : t("finance.save")}
              </Button>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AddExpensesType;
