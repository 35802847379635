import React, { useState, useEffect } from "react";
import {
  Grid,
  Tabs,
  Tab,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import AddSampleDialog from "./AddSampleDialog";
import EditSampleDialog from "./EditSampleDialog";
import useFetchObjects from "../../../../api/useFetchObjects";
import useDelete from "../../../../api/useDelete";
import { useAuth } from "../../../../context";

const TestSample = () => {
  const auth = useAuth();
  const token = auth?.user?.token;

  const [searchQuery, setSearchQuery] = useState("");
  const [sampleType, setSampleType] = useState("");
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedSample, setSelectedSample] = useState(null);

  const { data, refetch, isLoading, isError } = useFetchObjects(
    ["laboratoryTestSamples", sampleType, searchQuery],
    `laboratory-test-samples/?sample_type=${sampleType}&search=${searchQuery}`,
    token,
  );

  const { handleDelete, ConfirmDialog } = useDelete(
    "laboratory-test-samples",
    token,
  );

  const handleTabChange = (event, newValue) => {
    setSampleType(newValue || "");
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleEditClick = (sample) => {
    setSelectedSample(sample);
    setEditDialogOpen(true);
  };

  useEffect(() => {
    refetch();
  }, [searchQuery, sampleType]);

  return (
    <Grid container justifyContent="center" mt={4} px={2}>
      <Grid item xs={12} md={10}>
        <Box
          sx={{ padding: "20px", backgroundColor: "#fff", borderRadius: "8px" }}
        >
          <Box display="flex" justifyContent="space-between" mb={3}>
            <Typography variant="h5">Laboratory Test Samples</Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setAddDialogOpen(true)}
            >
              Add Sample
            </Button>
          </Box>

          <TextField
            placeholder="Search by name or test type"
            value={searchQuery}
            onChange={handleSearchChange}
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 3, width: "100%", maxWidth: "400px" }}
          />

          <Tabs value={sampleType} onChange={handleTabChange}>
            <Tab value="" label="All" />
            <Tab value={1} label="Blood" />
            <Tab value={2} label="Urine" />
            <Tab value={3} label="Tissue" />
            <Tab value={4} label="Stool" />
            <Tab value={5} label="Other" />
          </Tabs>

          {isLoading ? (
            <Box display="flex" justifyContent="center" mt={3}>
              <CircularProgress />
            </Box>
          ) : isError ? (
            <Typography color="error">Failed to load samples.</Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sample Number</TableCell>
                    <TableCell>Patient</TableCell>
                    <TableCell>Sample Type</TableCell>
                    <TableCell>Collection Date</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((sample) => (
                    <TableRow key={sample.id}>
                      <TableCell>{sample.sample_number}</TableCell>
                      <TableCell>{sample.patient_display}</TableCell>
                      <TableCell>{sample.sample_type}</TableCell>
                      <TableCell>{sample.collection_date}</TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleEditClick(sample)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(sample.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        <ConfirmDialog />
      </Grid>
      <AddSampleDialog
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        handleAdd={() => {
          refetch();
          setAddDialogOpen(false);
        }}
      />
      {selectedSample && (
        <EditSampleDialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          handleEdit={(id, updatedData) => {
            refetch();
            setEditDialogOpen(false);
          }}
          sampleData={selectedSample}
        />
      )}
    </Grid>
  );
};

export default TestSample;
