import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import useUpdate from "../../../../api/useUpdate"; // Custom hook to handle updating data
import { useTranslation } from "react-i18next";

const UpdateUltrasoundTypeDialog = ({
  open,
  onClose,
  type,
  token,
  refetch,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: type.name,
    description: type.description,
    cost: type.cost,
  });

  const { handleUpdate, loading } = useUpdate("ultrasound-types", token);

  const handleSubmit = () => {
    // Update the selected ultrasound type
    handleUpdate(type.id, formData);
    refetch(); // Refetch the list after update
    onClose(); // Close the dialog after submission
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("ultrasound.updateUltType")}</DialogTitle>
      <DialogContent>
        <TextField
          label={t("ultrasound.ultTypeName")}
          fullWidth
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          sx={{ mb: 3, mt: 2 }}
        />
        <TextField
          label={t("ultrasound.description")}
          fullWidth
          multiline
          rows={4}
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          sx={{ mb: 3 }}
        />
        <TextField
          label={t("ultrasound.cost")}
          fullWidth
          type="number"
          value={formData.cost}
          onChange={(e) => setFormData({ ...formData, cost: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          {t("ultrasound.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={loading}
          sx={{ textTransform: "none", fontWeight: "bold", margin: "0px 10px" }}
        >
          {loading ? t("ultrasound.updating") : t("ultrasound.update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateUltrasoundTypeDialog;
