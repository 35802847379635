import {
  Button,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import useAdd from "../../../../../api/useAdd.js";
import { useAuth } from "../../../../../context/index.js";
import Loading from "./skeletonLoading/Loading.js";
import ErrorPage from "../../../../../Components/GeneralComponents/errorPage/ErrorPage";
import { useTranslation } from "react-i18next";

const buttonStyle = {
  textTransform: "capitalize",
  backgroundColor: "#071952",
  fontWeight: "bold",
};

const AddDepartment = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const [formData, setFormData] = useState({
    name: "",
    code: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const { handleAdd, resetForm, loading, isLoading, isError } = useAdd(
    "departments",
    token,
    t("hr.newCreated"),
    t("hr.conNotCreated"),
  );

  useEffect(() => {
    if (resetForm) {
      setFormData({
        name: "",
        code: "",
      });
      setFormErrors({});
    }
  }, [resetForm]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = {};

    // Validation
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) errors[key] = t("hr.requiredField");
    });

    if (Object.keys(errors).length === 0) {
      const departmentData = new FormData();
      Object.keys(formData).forEach((key) =>
        departmentData.append(key, formData[key]),
      );

      const success = handleAdd(departmentData);
      if (success) {
        setFormData({
          name: "",
          code: "",
        });
        setFormErrors({});
      }
    } else {
      setFormErrors(errors);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <Grid container>
        <ErrorPage />
      </Grid>
    );
  }

  return (
    <Paper sx={{ boxShadow: "0px 0px 0px", width: "100%" }}>
      <form onSubmit={handleSubmit}>
        <Grid container padding={"20px"}>
          <Typography color="primary">{t("hr.newDepartment")}</Typography>
          <Grid container gap={2} justifyContent={"space-between"} mt={2}>
            <Grid item xs={12} md={12}>
              <FormLabel>{t("hr.departmentName")}</FormLabel>
              <TextField
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                size="small"
                error={!!formErrors.name}
                helperText={formErrors.name}
                sx={{ marginBottom: "1em" }}
              />
              <FormLabel>{t("hr.departmentCode")}</FormLabel>
              <TextField
                name="code"
                value={formData.code}
                onChange={handleChange}
                fullWidth
                size="small"
                error={!!formErrors.code}
                helperText={formErrors.code}
              />
            </Grid>
          </Grid>
          <Grid
            container
            gap={2}
            justifyContent={"space-between"}
            alignItems={"center"}
            mt={4}
          >
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
              mt={2.8}
            >
              <LoadingButton
                variant="contained"
                loading={loading}
                type="submit"
                style={{ ...buttonStyle, marginLeft: "20px" }}
              >
                {t("hr.addDepartment")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default AddDepartment;
