import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context";
import ListLaboratoryTypes from "./ListLaboratoryTypes";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";

const LaboratoryTypes = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    cost: "",
    parent_test: null,
    category: null,
    result_type: "Numeric",
    min_value: "",
    max_value: "",
    units: "",
  });
  const [errors, setErrors] = useState({});
  const [searchText, setSearchText] = useState("");
  const [filteredParentTests, setFilteredParentTests] = useState([]);

  const auth = useAuth();
  const token = auth?.user?.token;

  const { handleAdd, loading, resetForm } = useAdd("laboratory-tests", token);
  const { data: parentTests } = useFetchObjects(
    ["laboratory-tests"],
    "laboratory-tests/",
    token
  );
  const { data: categories } = useFetchObjects(
    ["laboratory-categories"],
    "laboratory-test-categories/",
    token
  );

  useEffect(() => {
    if (resetForm) {
      setFormData({
        name: "",
        description: "",
        cost: "",
        parent_test: null,
        category: null,
        result_type: "Numeric",
        min_value: "",
        max_value: "",
        units: "",
      });
      setErrors({});
    }
  }, [resetForm]);

  useEffect(() => {
    if (parentTests && searchText) {
      const filtered = parentTests.filter((test) =>
        test.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredParentTests(filtered);
    } else {
      setFilteredParentTests([]);
    }
  }, [searchText, parentTests]);

  const validate = () => {
    let newErrors = {};

    // Name is required
    if (!formData.name) newErrors.name = t("laboratory.labTestRequired");

    // Category is required
    if (!formData.category)
      newErrors.category = t("laboratory.categoryRequired");

    // Cost validation
    if (formData.cost && Number(formData.cost) <= 0) {
      newErrors.cost = t("laboratory.costNumber");
    }

    // Validate Min and Max Value only if Numeric result type is selected
    if (formData.result_type === "Numeric") {
      if (formData.min_value && formData.max_value) {
        if (Number(formData.min_value) > Number(formData.max_value)) {
          newErrors.min_value = t("laboratory.minValueError");
          newErrors.max_value = t("laboratory.maxValueError");
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const testData = {
        name: formData.name,
        description: formData.description,
        parent_test: formData.parent_test?.id || null,
        category: formData.category?.id || null,
        result_type: formData.result_type,
        ...(formData.result_type === "Numeric" && {
          min_value: formData.min_value || null,
          max_value: formData.max_value || null,
          units: formData.units || null,
        }),
        ...(formData.cost && { cost: formData.cost }),
      };
      handleAdd(testData);
    }
  };

  return (
    <Grid container mt={2} mb={10}>
      <Grid item xs={12} lg={12}>
        <Paper
          component="form"
          onSubmit={handleSubmit}
          sx={{
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            padding: "30px 40px",
            borderRadius: "8px",
            mb: 5,
            width: "100%",
          }}
        >
          <Typography variant="h5" gutterBottom>
            {t("laboratory.addNewTestType")}
          </Typography>

          {/* Laboratory Test Name Input */}
          <TextField
            label={t("laboratory.laboratoryTestName")}
            fullWidth
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            sx={{ mb: 3 }}
            error={!!errors.name}
            helperText={errors.name}
          />

          {/* Category Selection */}
          <Autocomplete
            options={categories || []}
            getOptionLabel={(option) => option.name}
            value={formData.category}
            onChange={(e, value) =>
              setFormData({ ...formData, category: value })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("laboratory.selectCategory")}
                fullWidth
                sx={{ mb: 3 }}
                error={!!errors.category}
                helperText={errors.category}
              />
            )}
          />

          {/* Parent Test Selection */}
          <Autocomplete
            options={filteredParentTests}
            getOptionLabel={(option) => option.name}
            value={formData.parent_test}
            onChange={(e, value) =>
              setFormData({ ...formData, parent_test: value })
            }
            inputValue={searchText}
            onInputChange={(e, value) => setSearchText(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("laboratory.selectParentTest")}
                fullWidth
                sx={{ mb: 3 }}
              />
            )}
          />

          {/* Description Input */}
          <TextField
            label={t("laboratory.description")}
            fullWidth
            multiline
            rows={4}
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
            sx={{ mb: 3 }}
          />

          {/* Cost Input */}
          <TextField
            label={t("laboratory.cost")}
            fullWidth
            type="number"
            value={formData.cost}
            onChange={(e) => setFormData({ ...formData, cost: e.target.value })}
            sx={{ mb: 3 }}
            error={!!errors.cost}
            helperText={errors.cost}
          />

          {/* Result Type Selection */}
          <TextField
            select
            fullWidth
            label="Result Type"
            variant="outlined"
            value={formData.result_type}
            onChange={(e) =>
              setFormData({ ...formData, result_type: e.target.value })
            }
            sx={{ mb: 3 }}
          >
            <MenuItem value="Numeric">Numeric</MenuItem>
            <MenuItem value="Binary">Binary</MenuItem>
          </TextField>

          {/* Min and Max Value Inputs */}
          {formData.result_type === "Numeric" && (
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={t("laboratory.minValue")}
                  fullWidth
                  type="number"
                  value={formData.min_value}
                  onChange={(e) =>
                    setFormData({ ...formData, min_value: e.target.value })
                  }
                  error={!!errors.min_value}
                  helperText={errors.min_value}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={t("laboratory.maxValue")}
                  fullWidth
                  type="number"
                  value={formData.max_value}
                  onChange={(e) =>
                    setFormData({ ...formData, max_value: e.target.value })
                  }
                  error={!!errors.max_value}
                  helperText={errors.max_value}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={t("laboratory.units")}
                  fullWidth
                  value={formData.units}
                  onChange={(e) =>
                    setFormData({ ...formData, units: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          )}

          <Button
            variant="contained"
            disabled={loading}
            type="submit"
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              padding: "10px 20px",
              backgroundColor: "#071952",
              ":hover": {
                backgroundColor: "#071952",
              },
            }}
          >
            {loading ? t("laboratory.saving") : t("laboratory.addLabTest")}
          </Button>
        </Paper>

        <Divider sx={{ my: 4 }} />
        <ListLaboratoryTypes />
      </Grid>
    </Grid>
  );
};

export default LaboratoryTypes;
