import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Container,
  Autocomplete,
} from "@mui/material";
import SurgeryIcon from "@mui/icons-material/HealthAndSafety";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import { useTranslation } from "react-i18next";

const AddSurgery = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const { data: patientsData = {} } = useFetchObjects(
    ["patients"],
    "patients",
    token
  );
  const { handleAdd, resetForm } = useAdd("surgeries", token);

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    surgery_type: "",
    patient: "",
    surgery_date: "",
    doctor: "",
    co_doctor: "",
    anesthetist: "",
    surgery_state: 1,
    total_surgery_amount: "",
    co_doctor_percentage: "",
    anesthetist_percentage: "",
    referred_by: "",
    referred_contact_info: "",
    additional_details: "",
    pharmacy_percentage: "",
    xray_percentage: "",
    laboratory_percentage: "",
    electrocardiography_percentage: "",
    ultrasound_percentage: "",
    doctor_percentage: "",
  });

  useEffect(() => {
    if (resetForm) {
      setFormData({
        surgery_type: "",
        patient: "",
        surgery_date: "",
        doctor: "",
        co_doctor: "",
        anesthetist: "",
        surgery_state: 1,
        total_surgery_amount: "",
        co_doctor_percentage: "",
        anesthetist_percentage: "",
        referred_by: "",
        referred_contact_info: "",
        additional_details: "",
        pharmacy_percentage: "",
        xray_percentage: "",
        laboratory_percentage: "",
        electrocardiography_percentage: "",
        ultrasound_percentage: "",
        doctor_percentage: "",
      });
      setStep(0);
    }
  }, [resetForm]);

  const handleTypeChange = (event, newValue) => {
    if (newValue !== null) setFormData({ ...formData, surgery_type: newValue });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const formData1 = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      if (value !== "" && value !== null && value !== undefined) {
        formData1.append(key, value);
      }
    });

    handleAdd(formData1);
  };

  const nextStep = () => setStep((prev) => prev + 1);
  const previousStep = () => setStep((prev) => prev - 1);

  const validateStep = () => {
    if (step === 0 && !formData.surgery_type) return false;
    if (
      step === 1 &&
      (!formData.patient ||
        !formData.surgery_date ||
        !formData.doctor ||
        !formData.co_doctor ||
        !formData.anesthetist)
    )
      return false;

    if (step === 2) {
      if (
        formData.surgery_type === "1" &&
        (!formData.co_doctor_percentage || !formData.anesthetist_percentage)
      ) {
        return false;
      }
      if (
        formData.surgery_type === "2" &&
        (!formData.doctor_percentage ||
          !formData.co_doctor_percentage ||
          !formData.anesthetist_percentage)
      ) {
        return false;
      }
      if (
        formData.surgery_type === "3" &&
        (!formData.pharmacy_percentage ||
          !formData.xray_percentage ||
          !formData.laboratory_percentage ||
          !formData.electrocardiography_percentage ||
          !formData.ultrasound_percentage)
      ) {
        return false;
      }
    }

    return true;
  };

  const patients = patientsData.results || [];

  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      <Paper elevation={4} sx={{ p: 4, borderRadius: 4 }}>
        {/* Step 0: Select Surgery Type */}
        {step === 0 && (
          <>
            <Box textAlign="center" sx={{ mb: 3 }}>
              <SurgeryIcon color="primary" sx={{ fontSize: 48 }} />
              <Typography variant="h4" fontWeight="bold">
                {t("operations.selectSurgeryType")}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {t("operations.choseAppropriate")}
              </Typography>
            </Box>
            <ToggleButtonGroup
              value={formData.surgery_type}
              exclusive
              onChange={handleTypeChange}
              sx={{ display: "flex", justifyContent: "center", mt: 4 }}
            >
              <ToggleButton value="1">
                {t("operations.internalSurgery")}
              </ToggleButton>
              <ToggleButton value="2">
                {t("operations.referredSurgery")}
              </ToggleButton>
              <ToggleButton value="3">
                {t("operations.cotrehSurgery")}
              </ToggleButton>
            </ToggleButtonGroup>
            {formData.surgery_type === "2" && (
              <Box mt={4}>
                <TextField
                  label={t("operations.referredBy")}
                  name="referred_by"
                  value={formData.referred_by}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  label={t("operations.contactInfo")}
                  name="referred_contact_info"
                  value={formData.referred_contact_info}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  label={t("operations.additionalDetails")}
                  name="additional_details"
                  value={formData.additional_details}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  rows={3}
                  sx={{ mb: 2 }}
                />
              </Box>
            )}
            <Button
              variant="contained"
              onClick={nextStep}
              sx={{ mt: 4 }}
              disabled={!validateStep()}
            >
              {t("operations.next")}
            </Button>
          </>
        )}

        {/* Step 1: Patient & Surgery Details */}
        {step === 1 && (
          <>
            <Typography
              variant="h5"
              fontWeight="bold"
              textAlign="center"
              mb={3}
            >
              {t("operations.patientSurgeryDetails")}
            </Typography>
            <Autocomplete
              options={patients}
              getOptionLabel={(option) =>
                `${option.firstname} ${option.lastname}`
              }
              onChange={(event, newValue) =>
                setFormData({ ...formData, patient: newValue?.id })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("operations.selectPatient")}
                  fullWidth
                />
              )}
              sx={{ mb: 3 }}
            />
            <TextField
              label={t("operations.surgeryDate")}
              type="date"
              name="surgery_date"
              value={formData.surgery_date}
              onChange={handleInputChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={{ mb: 3 }}
            />
            <TextField
              label={t("operations.doctor")}
              name="doctor"
              value={formData.doctor}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 3 }}
            />
            <TextField
              label={t("operations.coDoctor")}
              name="co_doctor"
              value={formData.co_doctor}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 3 }}
            />
            <TextField
              label={t("operations.anesthetist")}
              name="anesthetist"
              value={formData.anesthetist}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 3 }}
            />
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button variant="outlined" onClick={previousStep}>
                {t("operations.previous")}
              </Button>
              <Button
                variant="contained"
                onClick={nextStep}
                disabled={!validateStep()}
              >
                {t("operations.next")}
              </Button>
            </Box>
          </>
        )}

        {/* Step 2: Financial & Percentage Distribution */}
        {step === 2 && (
          <>
            <Typography
              variant="h5"
              fontWeight="bold"
              textAlign="center"
              mb={3}
            >
              {t("operations.financialPercentage")}
            </Typography>
            <TextField
              label={t("operations.totalSurgeryAmount")}
              type="number"
              name="total_surgery_amount"
              value={formData.total_surgery_amount}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 3 }}
            />
            {formData.surgery_type === "1" && (
              <>
                <TextField
                  label={t("operations.coDoctorAmount")}
                  type="number"
                  name="co_doctor_percentage"
                  value={formData.co_doctor_percentage}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 3 }}
                />
                <TextField
                  label={t("operations.anesthetistAmount")}
                  type="number"
                  name="anesthetist_percentage"
                  value={formData.anesthetist_percentage}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 3 }}
                />
              </>
            )}
            {formData.surgery_type === "2" && (
              <>
                <TextField
                  label={t("operations.doctorAmount")}
                  type="number"
                  name="doctor_percentage"
                  value={formData.doctor_percentage}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 3 }}
                />
                <TextField
                  label={t("operations.coDoctorAmount")}
                  type="number"
                  name="co_doctor_percentage"
                  value={formData.co_doctor_percentage}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 3 }}
                />
                <TextField
                  label={t("operations.anesthetistAmount")}
                  type="number"
                  name="anesthetist_percentage"
                  value={formData.anesthetist_percentage}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 3 }}
                />
              </>
            )}
            {formData.surgery_type === "3" && (
              <>
                <TextField
                  label={t("operations.pharmacyAmount")}
                  type="number"
                  name="pharmacy_percentage"
                  value={formData.pharmacy_percentage}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 3 }}
                />
                <TextField
                  label={t("operations.xrayAmount")}
                  type="number"
                  name="xray_percentage"
                  value={formData.xray_percentage}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 3 }}
                />
                <TextField
                  label={t("operations.laboratoryAmount")}
                  type="number"
                  name="laboratory_percentage"
                  value={formData.laboratory_percentage}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 3 }}
                />
                <TextField
                  label={t("operations.electrocardiographyAmount")}
                  type="number"
                  name="electrocardiography_percentage"
                  value={formData.electrocardiography_percentage}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 3 }}
                />
                <TextField
                  label={t("operations.ultrasoundAmount")}
                  type="number"
                  name="ultrasound_percentage"
                  value={formData.ultrasound_percentage}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 3 }}
                />
              </>
            )}
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button variant="outlined" onClick={previousStep}>
                {t("operations.previous")}
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={!validateStep()}
              >
                {t("operations.submitSurgery")}
              </Button>
            </Box>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default AddSurgery;
