import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Paper,
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  CircularProgress,
  Alert,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import useDelete from "../../../../api/useDelete";
import { useTranslation } from "react-i18next";
import EditXrayResult from "./EditXrayResult";

const ListXrayResult = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const imageRef = useRef();
  const [openDialog, setOpenDialog] = useState(null);
  const [editData, setEditData] = useState("");

  const { data, isLoading, isError, error, refetch } = useFetchObjects(
    ["patient-xray-results"],
    `patient-xray-results/?search=${searchTerm}`,
    token,
  );
  const { handleDelete, ConfirmDialog } = useDelete(
    "patient-xray-results",
    token,
  );

  useEffect(() => {
    refetch();
  }, [searchTerm]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    const imgElement = imageRef.current;
    if (imgElement && imgElement.requestFullscreen) {
      imgElement.requestFullscreen();
    } else if (imgElement && imgElement.webkitRequestFullscreen) {
      imgElement.webkitRequestFullscreen();
    } else if (imgElement && imgElement.msRequestFullscreen) {
      imgElement.msRequestFullscreen();
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleEdit = (result) => {
    handleOpenDialog();
    setEditData(result);
  };

  return (
    <Box>
      {/* Search Bar */}
      <Box mb={4}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {t("x_ray.xrayResults")}
        </Typography>
        <TextField
          label={t("x_ray.searchByPatientOrType")}
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: "100%", mt: 2 }}
        />
      </Box>

      <Divider sx={{ marginY: 3 }} />

      {/* Loading State */}
      {isLoading && (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      )}

      {/* Error State */}
      {isError && (
        <Box mt={4}>
          <Alert severity="error">
            {t("x_ray.error")} {error.message}
          </Alert>
        </Box>
      )}

      {/* X-ray Result List */}
      {data && data.length > 0 && (
        <TableContainer
          component={Paper}
          sx={{ marginTop: 3, boxShadow: 3, borderRadius: 2 }}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("x_ray.patientName")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("x_ray.xrayType")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("x_ray.finding")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("x_ray.resultDate")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("x_ray.image")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("x_ray.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((result) => (
                <TableRow
                  key={result.id}
                  hover
                  sx={{
                    transition: "0.3s",
                    "&:hover": { backgroundColor: "#f0f0f0" },
                  }}
                >
                  <TableCell align="center">{result.patient_display}</TableCell>
                  <TableCell align="center">
                    {result.xray_type_display}
                  </TableCell>
                  <TableCell align="center">
                    {result.findings || t("x_ray.noFindings")}
                  </TableCell>
                  <TableCell align="center">
                    {new Date(result.result_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">
                    {result.image ? (
                      <img
                        src={result.image}
                        alt="X-ray"
                        width="50"
                        height="50"
                        style={{ cursor: "pointer", borderRadius: "5px" }}
                        onClick={() => handleImageClick(result.image)}
                        ref={imageRef}
                      />
                    ) : (
                      t("x_ray.noImage")
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(result)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(result.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!isLoading && data?.length === 0 && (
        <Box mt={4}>
          <Alert severity="info">{t("x_ray.noResultsAvailable")}</Alert>
        </Box>
      )}
      <ConfirmDialog />

      <EditXrayResult
        editData={editData}
        openDialog={openDialog}
        handleOpenDialog={handleOpenDialog}
      />
    </Box>
  );
};

export default ListXrayResult;
