import {
  Box,
  Button,
  Container,
  Divider,
  FormLabel,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Months } from "../../../../utils";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context";
import Loading from "./skeletonLoading/Loading";
import ErrorPage from "../../../../Components/GeneralComponents/errorPage/ErrorPage";
import { useTranslation } from "react-i18next";
import EvaluationList from "./EvaluationList";

const currentYear = new Date().getFullYear();
const years = Array.from(
  { length: currentYear - 2020 + 1 },
  (v, i) => 2020 + i,
);

const Evaluation = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const [formData, setFormData] = useState({
    employee: "",
    month: "",
    year: "",
    description: "",
    rate: 0,
  });

  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const { data: employeeData } = useFetchObjects(["users"], "users", token);

  const { handleAdd, resetForm, loading, isError, isLoading } = useAdd(
    "employee-evaluations",
    token,
    "Evaluation submitted successfully!",
    "Failed to submit evaluation",
  );

  useEffect(() => {
    if (resetForm) {
      setFormData({
        employee: "",
        month: "",
        year: "",
        description: "",
        rate: 0,
      });
      setFormErrors({});
      setSuccessMessage("");
      setErrorMessage("");
    }
  }, [resetForm]);

  const handleSave = async (e) => {
    e.preventDefault();
    let errors = {};

    if (!formData.employee) {
      errors.employee = t("hr.employeeRequired");
    }
    if (!formData.month) {
      errors.month = t("hr.monthRequired");
    }
    if (!formData.year) {
      errors.year = t("hr.yearRequired");
    }
    if (!formData.description) {
      errors.description = t("hr.descriptionRequired");
    }
    if (formData.rate < 1 || formData.rate > 10) {
      errors.rate = t("hr.rate");
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      const success = handleAdd(formData);
      if (success) {
        setFormData({
          employee: "",
          month: "",
          year: "",
          description: "",
          rate: 0,
        });
        setFormErrors({});
      } else {
        setFormErrors(errors);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };
  if (isLoading) return <Loading />;
  if (isError)
    return (
      <Grid container>
        <ErrorPage />
      </Grid>
    );

  return (
    <Container>
      <Paper
        sx={{
          boxShadow: "0px 0px 0px",
          marginTop: "2rem",
          marginBottom: "2rem",
          padding: "1rem",
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight={500} variant="h4" color="primary">
            {t("hr.evaluationForm")}
          </Typography>
          <Button variant="contained"> {t("hr.reports")}</Button>
        </Stack>
        <Divider sx={{ mt: 2, mb: 2, width: "100%" }} />

        {successMessage && (
          <Typography color="green" variant="body1">
            {successMessage}
          </Typography>
        )}
        {errorMessage && (
          <Typography color="red" variant="body1">
            {errorMessage}
          </Typography>
        )}

        <form onSubmit={handleSave}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <FormLabel> {t("hr.employee")}</FormLabel>
              <Autocomplete
                options={employeeData}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.last_name}`
                }
                onChange={(event, value) => {
                  setFormData({ ...formData, employee: value.id });
                  setFormErrors({ ...formErrors, employee: "" });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    fullWidth
                    label={t("hr.selectEmployee")}
                    error={!!formErrors.employee}
                    helperText={formErrors.employee}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormLabel> {t("hr.month")}</FormLabel>
              <TextField
                select
                size="small"
                name="month"
                value={formData.month}
                label={t("hr.selectMonth")}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={!!formErrors.month}
                helperText={formErrors.month}
              >
                {Months.map((monthOption) => (
                  <MenuItem key={monthOption.id} value={monthOption.id}>
                    {monthOption.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormLabel> {t("hr.year")}</FormLabel>
              <TextField
                select
                size="small"
                name="year"
                value={formData.year}
                label={t("hr.selectYear")}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={!!formErrors.year}
                helperText={formErrors.year}
              >
                {years.map((yearOption) => (
                  <MenuItem key={yearOption} value={yearOption}>
                    {yearOption}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                size="small"
                label={t("hr.description1")}
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                name="description"
                value={formData.description}
                onChange={handleChange}
                error={!!formErrors.description}
                helperText={formErrors.description}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormLabel> {t("hr.evaluation")}</FormLabel>
              <TextField
                type="number"
                size="small"
                variant="outlined"
                fullWidth
                name="rate"
                value={formData.rate}
                onChange={handleChange}
                inputProps={{ min: 1, max: 10 }}
                error={!!formErrors.rate}
                helperText={formErrors.rate || t("hr.numberBetween")}
              />
            </Grid>
          </Grid>

          <br />

          <Stack direction="row" justifyContent="space-between">
            <Box></Box>
            <Stack direction="row" spacing={2}>
              <Button variant="contained" type="submit" disabled={loading}>
                {t("hr.save")}
              </Button>
            </Stack>
          </Stack>
        </form>
        <Box mt={3}>
          <Divider />
          <EvaluationList />
        </Box>
      </Paper>
    </Container>
  );
};

export default Evaluation;
