import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TextField,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import { useTranslation } from "react-i18next";

// Styled Components
const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(5),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  marginTop: theme.spacing(3),
}));

const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "12px",
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
  },
}));

const ListSurgeryXrayReferral = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [selectedResult, setSelectedResult] = useState(null);
  const theme = useTheme();
  const auth = useAuth();
  const token = auth?.user?.token;

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["surgery-xray-patient"],
    `surgery-xray-patient/?search=${search}`,
    token
  );

  const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

  useEffect(() => {
    refetch();
  }, [refetch, search]);

  const handleResultView = (result) => {
    setSelectedResult(result);
  };

  const handleCloseDialog = () => {
    setSelectedResult(null);
  };

  return (
    <StyledBox>
      <Typography
        variant="h4"
        sx={{
          mb: 4,
          fontWeight: "bold",
          textAlign: "center",
          color: theme.palette.primary.main,
        }}
      >
        {t("doctor.xrayReferrals")}
      </Typography>

      {/* Search Bar */}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <TextField
          variant="outlined"
          placeholder={t("doctor.searchByPatientXrayType")}
          fullWidth
          sx={{
            maxWidth: "600px",
            "& .MuiOutlinedInput-root": {
              borderRadius: theme.shape.borderRadius,
            },
            "& .MuiOutlinedInput-input": {
              padding: "10px 14px",
            },
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>

      {/* Loading Spinner */}
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Error Message */}
      {isError && (
        <Typography
          variant="body1"
          color="error"
          sx={{ textAlign: "center", mt: 4 }}
        >
          {t("doctor.anError")}
        </Typography>
      )}

      {/* Table */}
      {!isLoading && !isError && (
        <StyledTableContainer component={Paper}>
          <Table sx={{ minWidth: 800 }}>
            <TableHead>
              <StyledTableHeadRow>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("doctor.patient")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("doctor.xrayType")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("doctor.assignedDoctor")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("doctor.status")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("doctor.dateRequested")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("doctor.totalAmount")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("doctor.urgent")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("doctor.paid")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("doctor.result")}
                </TableCell>
              </StyledTableHeadRow>
            </TableHead>
            <TableBody>
              {(data || []).map((referral, index) => (
                <TableRow
                  key={referral.id}
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? theme.palette.grey[50] : "white",
                    "&:hover": { backgroundColor: theme.palette.action.hover },
                  }}
                >
                  <TableCell align="center">
                    {referral.patient_display}
                  </TableCell>
                  <TableCell align="center">
                    {referral.xray_type_display}
                  </TableCell>
                  <TableCell align="center">
                    {referral.assigned_doctor_display}
                  </TableCell>
                  <TableCell align="center">
                    <StyledChip
                      label={referral.status_display}
                      color={
                        referral.status_display === "Pending"
                          ? "warning"
                          : "success"
                      }
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell align="center">{referral.date}</TableCell>
                  <TableCell align="center">{referral.total_amount}</TableCell>
                  <TableCell align="center">
                    <StyledChip
                      label={
                        referral.is_urgent ? t("doctor.yes") : t("doctor.no")
                      }
                      color={referral.is_urgent ? "error" : "default"}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <StyledChip
                      label={
                        referral.is_paid ? t("doctor.yes") : t("doctor.no")
                      }
                      color={referral.is_paid ? "success" : "error"}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell align="center">
                    {referral.result_data ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleResultView(referral.result_data)}
                      >
                        {t("doctor.viewResult")}
                      </Button>
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        {t("doctor.noResult")}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}

      {/* Result Dialog */}
      {selectedResult && (
        <StyledDialog open onClose={handleCloseDialog} maxWidth="sm" fullWidth>
          <DialogTitle>
            {t("doctor.xrayResult")}
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography variant="subtitle1" gutterBottom>
              <strong>{t("doctor.patient")}:</strong>{" "}
              {selectedResult.patient_display}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>{t("doctor.xrayType")}:</strong>{" "}
              {selectedResult.xray_type_display}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
            >
              {t("doctor.findings")}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {selectedResult.findings || t("doctor.noFindingsAvailable")}
            </Typography>
            {selectedResult.image && (
              <Typography variant="body2" sx={{ mt: 2 }}>
                <a
                  href={`${BACKEND_BASE_URL}${selectedResult.image}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                  }}
                >
                  {t("doctor.downloadImage")}
                </a>
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDialog}
              color="primary"
              variant="contained"
            >
              {t("doctor.close")}
            </Button>
          </DialogActions>
        </StyledDialog>
      )}
    </StyledBox>
  );
};

export default ListSurgeryXrayReferral;
