import React from "react";
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Grid,
  Avatar,
  Button,
} from "@mui/material";
import { WarningAmber, PictureAsPdf } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import { StockMedicineSkeleton } from "./loading/StockMedicineSkeleton";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment"; // For date calculations

const MedicineExpirySixMonthLater = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const pharmacy = auth?.user?.user?.pharmacy_type;

  const {
    data: expiringMedicinesData,
    isError: isErrorExpiring,
    error: errorExpiring,
  } = useFetchObjects(
    ["expiring-medicines"],
    `medicine-left-sex-month/?pharmacy=${pharmacy}`,
    token
  );

  if (!expiringMedicinesData) {
    return <StockMedicineSkeleton />;
  }

  if (isErrorExpiring) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">{errorExpiring.message}</Typography>
      </Box>
    );
  }

  // Calculate months left for expiry
  const calculateMonthsLeft = (expiryDate) => {
    const currentDate = moment();
    const expiry = moment(expiryDate);
    const monthsLeft = expiry.diff(currentDate, "months", true); // Include partial months
    return monthsLeft > 0 ? Math.ceil(monthsLeft) : 0; // Round up and ensure no negative values
  };

  // Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = [
      "#",
      t("pharmacy.category"),
      t("pharmacy.medicineName"),
      t("pharmacy.expiryDate"),
      t("pharmacy.monthsLeft"),
      t("pharmacy.quantity"),
      `${t("pharmacy.sellPrice")} (؋)`,
    ];
    const tableRows = expiringMedicinesData.map((item, index) => [
      index + 1,
      item.category_display,
      item.medicine_display,
      item.expiry_date,
      calculateMonthsLeft(item.expiry_date),
      item.quantity,
      item.sell_price,
    ]);

    doc.setFontSize(18);
    doc.text(t("overview.expiringMedicines"), 14, 20);
    doc.setFontSize(12);
    doc.setTextColor(100);

    // AutoTable to generate the table with smaller font sizes
    doc.autoTable({
      startY: 30,
      head: [tableColumn],
      body: tableRows,
      theme: "grid",
      headStyles: { fillColor: [211, 47, 47], fontSize: 10 }, // Header font size
      styles: { fontSize: 8 }, // Body font size
    });

    doc.save("ExpiringMedicines.pdf");
  };

  return (
    <Paper
      sx={{
        boxShadow: "0px 8px 24px rgba(0,0,0,0.1)",
        borderRadius: "16px",
        marginTop: "2rem",
        padding: { xs: "1.5rem", md: "2.5rem" },
        width: "100%",
      }}
    >
      {/* Header Section */}
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Avatar sx={{ bgcolor: "#d32f2f", width: 56, height: 56 }}>
                <WarningAmber fontSize="large" />
              </Avatar>
            </Grid>
            <Grid item>
              <Typography fontWeight={700} variant="h4" color="error">
                {t("overview.expiringMedicines")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="error"
            startIcon={<PictureAsPdf />}
            onClick={exportToPDF}
          >
            {t("pharmacy.exportPdf")}
          </Button>
        </Grid>
      </Grid>

      {/* Table Section */}
      <TableContainer component={Box} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
              <TableCell align="center">
                <Typography fontWeight={700} color="primary">
                  {t("pharmacy.category")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={700} color="primary">
                  {t("pharmacy.medicineName")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={700} color="primary">
                  {t("pharmacy.expiryDate")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={700} color="primary">
                  {t("pharmacy.monthsLeft")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={700} color="primary">
                  {t("pharmacy.quantity")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={700} color="primary">
                  {t("pharmacy.sellPrice")} (؋)
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expiringMedicinesData.map((stock, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:hover": { backgroundColor: "#f9f9f9" },
                  "&:nth-of-type(even)": { backgroundColor: "#fdfdfd" },
                }}
              >
                <TableCell align="center">
                  <Typography>{stock.category_display}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{stock.medicine_display}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{stock.expiry_date}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>
                    {calculateMonthsLeft(stock.expiry_date)}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{stock.quantity}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{stock.sell_price} ؋</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default MedicineExpirySixMonthLater;
