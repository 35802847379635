import React, { useEffect, useState } from "react";
import useUpdate from "../../../../../api/useUpdate";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const EditMedicine = ({ open, onClose, medicine, categoryData, token }) => {
  const { t } = useTranslation();
  const [name, setName] = useState(medicine?.name || "");
  const [nameError, setNameError] = useState("");

  const [category, setCategory] = useState(
    categoryData.find((cat) => cat.id === medicine.category) || null,
  );
  const [categoryError, setCategoryError] = useState("");

  const [description, setDescription] = useState(medicine?.description || "");
  const [descriptionError, setDescriptionError] = useState("");

  const { handleUpdate } = useUpdate("medicines", token, null);

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;

    if (!name) {
      setNameError(t("pharmacy.nameRequired"));
      isValid = false;
    } else {
      setNameError("");
      isValid = true;
    }

    if (!category) {
      setCategoryError(t("pharmacy.categoryRequired"));
      isValid = false;
    } else {
      setCategoryError("");
      isValid = true;
    }

    if (!description) {
      setDescriptionError(t("pharmacy.descriptionRequired"));
      isValid = false;
    } else {
      setDescriptionError("");
      isValid = true;
    }

    if (isValid) {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("category", category.id);
      formData.append("description", description);
      handleUpdate(medicine.id, formData);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      component="form"
      onSubmit={handleSubmit}
    >
      <DialogTitle>{t("pharmacy.updateMedicine")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t("pharmacy.medicineName")}
          fullWidth
          variant="outlined"
          value={name}
          error={!!nameError}
          helperText={nameError}
          onChange={(e) => setName(e.target.value)}
        />
        <Autocomplete
          options={categoryData || []}
          getOptionLabel={(option) => option.name}
          value={category || null}
          onChange={(event, newValue) => {
            if (newValue) {
              setCategory(newValue);
            } else {
              setCategory(null);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label={t("pharmacy.category")}
              variant="outlined"
              error={!!categoryError}
              helperText={categoryError}
            />
          )}
          fullWidth
        />

        <TextField
          margin="dense"
          label={t("pharmacy.description")}
          fullWidth
          multiline
          rows={4}
          value={description}
          error={!!descriptionError}
          helperText={descriptionError}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="warning">
          {t("pharmacy.cancel")}
        </Button>
        <Button
          variant="contained"
          color="success"
          type="submit"
          sx={{ margin: "0px 10px" }}
        >
          {t("pharmacy.update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMedicine;
