import React from "react";
import { Grid, Paper, Skeleton, Typography } from "@mui/material";

const Loading = () => {
  return (
    <Paper sx={{ boxShadow: "0px 0px 0px", width: "100%" }}>
      <Grid container padding={"20px"}>
        <Typography color="primary">
          <Skeleton variant="text" width={150} />
        </Typography>
        <Grid container gap={2} justifyContent={"space-between"} mt={2}>
          <Grid item xs={12} md={12}>
            <Skeleton variant="text" width={150} height={30} />
            <Skeleton variant="rectangular" height={40} width="100%" />
          </Grid>
        </Grid>
        <Grid
          container
          gap={2}
          justifyContent={"space-between"}
          alignItems={"center"}
          mt={4}
        >
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
            mt={2.8}
          >
            <Skeleton variant="rectangular" width={120} height={40} />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Loading;
