import {
  Box,
  Button,
  Container,
  Divider,
  FormLabel,
  Grid,
  Autocomplete,
  Paper,
  Stack,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import { Months } from "../../../../utils";
import useAdd from "../../../../api/useAdd";
import { useTranslation } from "react-i18next";
import EmployeeLeaveList from "./EmployeeLeaveList";
const currentYear = new Date().getFullYear();
const years = Array.from(
  { length: currentYear - 2020 + 1 },
  (v, i) => 2020 + i,
);

const EmployeeLeave = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const [formData, setFormData] = useState({
    employee: "",
    month: "",
    year: "",
    description: "",
    days: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { data: employeeData } = useFetchObjects(["users"], "users", token);

  const { handleAdd, resetForm, loading } = useAdd(
    "employee-leave",
    token,
    t("hr.employeeLeaveSuccess"),
    t("hr.failedEmployeeLeave"),
  );

  useEffect(() => {
    if (resetForm) {
      setFormData({
        employee: "",
        month: "",
        year: "",
        description: "",
        days: "",
      });
      setFormErrors({});
      setSuccessMessage("");
      setErrorMessage("");
    }
  }, [resetForm]);
  const handleSave = async (e) => {
    e.preventDefault();
    let errors = {};

    // Validate required fields
    if (!formData.employee) {
      errors.employee = t("hr.employeeRequired");
    }
    if (!formData.month) {
      errors.month = t("hr.monthRequired");
    }
    if (!formData.year) {
      errors.year = t("hr.yearRequired");
    }
    if (!formData.description) {
      errors.description = t("hr.descriptionRequired");
    }
    if (!formData.days) {
      errors.days = t("hr.daysRequired");
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      const success = handleAdd(formData);
      if (success) {
        setFormData({
          employee: "",
          month: "",
          year: "",
          description: "",
          days: "",
        });
        setFormErrors({});
      } else {
        setFormErrors(errors);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  return (
    <Container>
      <Paper
        sx={{
          boxShadow: "0px 0px 0px",
          marginTop: "2rem",
          marginBottom: "2rem",
          padding: "1rem",
        }}
      >
        <Typography fontWeight={500} variant="h4" color="primary">
          {t("hr.leaveForm")}
        </Typography>
        <Divider sx={{ mt: 2, mb: 2, width: "100%" }} />

        <Stack direction="row" justifyContent="space-between"></Stack>
        {successMessage && (
          <Typography color="green" variant="body1">
            {successMessage}
          </Typography>
        )}
        {errorMessage && (
          <Typography color="red" variant="body1">
            {errorMessage}
          </Typography>
        )}

        <form onSubmit={handleSave}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <FormLabel>{t("hr.employee")}</FormLabel>
              <Autocomplete
                options={employeeData}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.last_name}`
                }
                onChange={(event, value) => {
                  setFormData({ ...formData, employee: value ? value.id : "" });
                  setFormErrors({ ...formErrors, employee: "" });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    fullWidth
                    label={t("hr.selectEmployee")}
                    error={!!formErrors.employee}
                    helperText={formErrors.employee}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormLabel>{t("hr.month")}</FormLabel>
              <TextField
                select
                size="small"
                name="month"
                value={formData.month}
                label={t("hr.selectMonth")}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={!!formErrors.month}
                helperText={formErrors.month}
              >
                {Months.map((monthOption) => (
                  <MenuItem key={monthOption.id} value={monthOption.id}>
                    {monthOption.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormLabel>{t("hr.year")}</FormLabel>
              <TextField
                select
                size="small"
                name="year"
                value={formData.year}
                label={t("hr.selectYear")}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={!!formErrors.year}
                helperText={formErrors.year}
              >
                {years.map((yearOption) => (
                  <MenuItem key={yearOption} value={yearOption}>
                    {yearOption}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormLabel>{t("hr.days")}</FormLabel>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                type="number"
                name="days"
                value={formData.days}
                label={t("hr.insertDays")}
                onChange={handleChange}
                error={!!formErrors.days}
                helperText={formErrors.days}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                size="small"
                label={t("hr.description1")}
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                name="description"
                value={formData.description}
                onChange={handleChange}
                error={!!formErrors.description}
                helperText={formErrors.description}
              />
            </Grid>
          </Grid>
          <br />

          <Stack direction="row" justifyContent="space-between">
            <Box></Box>
            <Stack direction="row" spacing={2}>
              <Button variant="contained" type="submit" disabled={loading}>
                {t("hr.save")}
              </Button>
            </Stack>
          </Stack>
        </form>
        <Box mt={3}>
          <Divider />
          <EmployeeLeaveList />
        </Box>
      </Paper>
    </Container>
  );
};

export default EmployeeLeave;
