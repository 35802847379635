import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../context";
import useUpdate from "../../../../../api/useUpdate";
import { useTranslation } from "react-i18next";

const EditTask = ({ open, onClose, nurses, patients, task }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const [formData, setFormData] = useState({});

  const [formErrors, setFormErrors] = useState({});

  const { handleUpdate, resetForm, Buttons, FormMessageComponent } = useUpdate(
    "assign-task-to-nurse",
    token,
  );

  useEffect(() => {
    if (task) {
      setFormData({
        nurse: task.nurse || "",
        assigned_date: task.assigned_date || "",
        description: task.description || "",
        patient: task.patient || "",
        time: task.time || "",
        room_number: task.room_number || "",
        priority: task.priority || "",
      });
      setFormErrors({});
    }
  }, [task]);

  useEffect(() => {
    if (resetForm) {
      setFormData({
        nurse: "",
        assigned_date: "",
        description: "",
        patient: "",
        time: "",
        room_number: "",
        priority: "",
      });
      setFormErrors({});
    }
  }, [resetForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    console.log(formData);
    // Validation
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) errors[key] = t("doctor.fieldRequired");
    });

    if (Object.keys(errors).length === 0) {
      const taskData = new FormData();
      Object.keys(formData).forEach((key) =>
        taskData.append(key, formData[key]),
      );
      handleUpdate(task?.id, taskData);
      onClose();
    } else {
      setFormErrors(errors);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  const handleNurseChange = (e, value) => {
    setFormData({ ...formData, nurse: value?.id });
    setFormErrors({ ...formErrors, nurse: "" });
  };

  const handlePatientChange = (e, value) => {
    setFormData({ ...formData, patient: value?.id });
    setFormErrors({ ...formErrors, patient: "" });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "#3f51b5",
          color: "white",
          textAlign: "center",
        }}
      >
        <Typography variant="h6">{t("doctor.createAppointment")}</Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ padding: "2rem" }}>
          <Grid spacing={4} container>
            <Grid xs={12} md={6} item>
              <Autocomplete
                options={nurses}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.last_name}`
                }
                size="small"
                fullWidth
                required
                value={
                  (nurses &&
                    nurses.find((nurse) => nurse.id === formData?.nurse)) ||
                  null
                }
                onChange={handleNurseChange}
                filterOptions={(options, { inputValue }) => {
                  if (!inputValue) return [];
                  return options.filter((option) =>
                    option.first_name
                      .toLowerCase()
                      .includes(inputValue.toLowerCase()),
                  );
                }}
                noOptionsText=""
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("doctor.nurse")}
                    name="nurse"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <TextField
                size="small"
                name="assigned_date"
                type="date"
                fullWidth
                value={formData?.assigned_date}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                name="description"
                onChange={handleChange}
                fullWidth
                size="small"
                label={t("doctor.description")}
                multiline
                value={formData?.description}
                rows={5}
                required
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <Autocomplete
                options={patients && patients.results}
                getOptionLabel={(option) =>
                  `${option.firstname} ${option.lastname}`
                }
                size="small"
                fullWidth
                required
                value={
                  (patients &&
                    patients.results.find(
                      (patient) => patient.id === formData?.patient,
                    )) ||
                  null
                }
                onChange={handlePatientChange}
                filterOptions={(options, { inputValue }) => {
                  if (!inputValue) return [];
                  return options.filter((option) =>
                    option.firstname
                      .toLowerCase()
                      .includes(inputValue.toLowerCase()),
                  );
                }}
                noOptionsText=""
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("doctor.patientName")}
                    name="nurse"
                    variant="outlined"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="time"
                type="time"
                value={formData?.time}
                onChange={handleChange}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="room_number"
                type="text"
                label={t("doctor.roomNo")}
                value={formData?.room_number}
                onChange={handleChange}
                fullWidth
                required
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                name="priority"
                type="text"
                label={t("doctor.priority")}
                value={formData?.priority}
                onChange={handleChange}
                fullWidth
                size="small"
              >
                <MenuItem value="1">{t("doctor.low")}</MenuItem>
                <MenuItem value="2">{t("doctor.medium")}</MenuItem>
                <MenuItem value="3">{t("doctor.hight")}</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", paddingBottom: "20px" }}
        >
          <Button
            onClick={onClose}
            color="primary"
            variant="outlined"
            style={{ margin: "0px 10px" }}
          >
            {t("doctor.cancel")}
          </Button>
          <LoadingButton type="submit" color="primary" variant="contained">
            {t("doctor.save")}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditTask;
