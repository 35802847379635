import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Tooltip,
  CircularProgress,
  Alert,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PrintIcon from "@mui/icons-material/Print";
import { useNavigate } from "react-router-dom";
import useFetchObjects from "../../../../api/useFetchObjects";
import useDelete from "../../../../api/useDelete";
import { useAuth } from "../../../../context";
import { useTranslation } from "react-i18next";
import Print from "../TestsTobeDone/Print";

const ListSurgeryPatientTest = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const printRef = useRef();
  const [printData, setPrintData] = useState(null);

  const {
    data: orders,
    isLoading,
    isError,
    refetch,
  } = useFetchObjects(
    ["surgery-laboratory-patient-order"],
    `surgery-laboratory-patient-order/?search=${searchTerm}`,
    token,
  );

  const { handleDelete, ConfirmDialog } = useDelete(
    "surgery-laboratory-patient-order",
    token,
  );

  useEffect(() => {
    refetch();
  }, [searchTerm, refetch]);

  // Print function
  const handlePrint = (order) => {
    setPrintData(order);
    setTimeout(() => {
      if (printRef.current) {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(printRef.current.innerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }
    }, 500);
  };

  return (
    <Box>
      {/* Search Bar */}
      <Box mb={4}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {t("laboratory.labOrders")}
        </Typography>
        <TextField
          label={t("laboratory.searchPlaceholder")}
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <IconButton position="start">
                <SearchIcon />
              </IconButton>
            ),
          }}
          sx={{ width: "100%", mt: 2 }}
        />
      </Box>

      <Divider sx={{ my: 3 }} />

      {/* Loading State */}
      {isLoading && (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      )}

      {/* Error State */}
      {isError && (
        <Box mt={4}>
          <Alert severity="error">{t("laboratory.errorFetchingOrders")}</Alert>
        </Box>
      )}

      {/* Laboratory Orders List */}
      {orders && orders.length > 0 && (
        <TableContainer
          component={Paper}
          sx={{ mt: 3, boxShadow: 3, borderRadius: 2 }}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  #
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.patient")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.doctor")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.orderDate")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.totalAmount")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.status")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.action")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order, index) => (
                <TableRow
                  key={order.id}
                  hover
                  sx={{
                    transition: "0.3s",
                    "&:hover": { backgroundColor: "#f0f0f0" },
                  }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {order.patient_display?.name}
                  </TableCell>
                  <TableCell align="center">
                    {order.doctor_display?.username}
                  </TableCell>
                  <TableCell align="center">
                    {new Date(order.order_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">{order.total_amount}</TableCell>
                  <TableCell align="center">
                    {order.status === 1
                      ? t("laboratory.pending")
                      : order.status === 3
                        ? t("laboratory.completed")
                        : t("laboratory.cancelled")}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={t("laboratory.addResult")}>
                      <IconButton
                        color="success"
                        onClick={() =>
                          navigate(`/laboratory/surgery-test-results/${order.id}`)
                        }
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("laboratory.deleteOrder")}>
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(order.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("laboratory.print")}>
                      <IconButton
                        color="secondary"
                        onClick={() => handlePrint(order)}
                      >
                        <PrintIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* No Results */}
      {!isLoading && orders?.length === 0 && (
        <Box mt={4}>
          <Alert severity="info">{t("laboratory.noOrders")}</Alert>
        </Box>
      )}

      <ConfirmDialog />

      {/* Hidden Print Component */}
      {printData && (
        <div ref={printRef} style={{ display: "none" }}>
          <Print labTest={printData} tests={printData.tests_display} />
        </div>
      )}
    </Box>
  );
};

export default ListSurgeryPatientTest;
