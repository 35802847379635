import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
  Tooltip,
  Card,
  CardContent,
  InputAdornment,
  CircularProgress,
  Alert,
} from "@mui/material";
import { RemoveCircleOutline, Search } from "@mui/icons-material";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context";
import { useTranslation } from "react-i18next";
import ListSurgeryPatientTest from "./ListSurgeryPatientTest";

const SurgeryPatientTest = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  // Fetch surgeries instead of patients
  const { data: surgeries, isLoading: loadingSurgeries } = useFetchObjects(
    ["surgeries"],
    "surgeries",
    token
  );

  const { data: tests } = useFetchObjects(
    ["laboratory-tests"],
    "laboratory-tests",
    token
  );

  const { data: doctors, isLoading: loadingDoctors } = useFetchObjects(
    ["users"],
    "users",
    token
  );

  const [orderData, setOrderData] = useState({
    // We'll keep a date just for reference
    date: new Date().toISOString().split("T")[0],
    doctor: auth?.user?.user?.id || "",
    patient: "", // will store the surgery ID
    urgency: false,
    discount: 0,
    tests: [],
  });

  const [formErrors, setFormErrors] = useState({});
  const [selectedTests, setSelectedTests] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredTests, setFilteredTests] = useState([]);

  // State to store laboratory_percentage and existing test amount
  const [laboratoryPercentage, setLaboratoryPercentage] = useState(0);
  const [existingLabTestAmount, setExistingLabTestAmount] = useState(0);

  // State to control warning message visibility
  const [isOverLimit, setIsOverLimit] = useState(false);
  const [overLimitAmount, setOverLimitAmount] = useState(0);

  // Use the surgery-laboratory-patient-order endpoint
  const { handleAdd, loading, resetForm } = useAdd(
    "surgery-laboratory-patient-order",
    token,
    t("laboratory.surgeryTestOrderCreatedSuccess"),
    t("laboratory.surgeryTestOrderCreateFail")
  );

  useEffect(() => {
    if (resetForm) {
      setOrderData({
        date: new Date().toISOString().split("T")[0],
        doctor: auth?.user?.user?.id || "",
        patient: "",
        urgency: false,
        discount: 0,
        tests: [],
      });
      setSelectedTests([]);
      setFormErrors({});
      // Reset laboratory percentage and existing amount
      setLaboratoryPercentage(0);
      setExistingLabTestAmount(0);
      setIsOverLimit(false);
      setOverLimitAmount(0);
    }
  }, [resetForm, auth?.user?.user?.id]);

  useEffect(() => {
    if (tests && searchText) {
      const filtered = tests.filter((test) =>
        test.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredTests(filtered);
    } else {
      setFilteredTests([]);
    }
  }, [searchText, tests]);

  // Update laboratoryPercentage and existingLabTestAmount when a surgery is selected
  useEffect(() => {
    if (orderData.patient && surgeries) {
      const selectedSurgery = surgeries.find(
        (surgery) => surgery.id === orderData.patient
      );
      if (selectedSurgery) {
        setLaboratoryPercentage(selectedSurgery.laboratory_percentage || 0);
        setExistingLabTestAmount(
          selectedSurgery.total_laboratory_test_order_amount || 0
        );
      }
    } else {
      setLaboratoryPercentage(0);
      setExistingLabTestAmount(0);
    }
  }, [orderData.patient, surgeries]);

  // Check if the new total exceeds the laboratory_percentage
  useEffect(() => {
    const totalCost = calculateTotal();
    const newTotal = existingLabTestAmount + totalCost;
    if (newTotal > laboratoryPercentage) {
      setIsOverLimit(true);
      setOverLimitAmount(newTotal - laboratoryPercentage);
    } else {
      setIsOverLimit(false);
      setOverLimitAmount(0);
    }
  }, [selectedTests, existingLabTestAmount, laboratoryPercentage]);

  const handleAddNewTest = (test) => {
    if (test) {
      const allTests = test.sub_tests ? [test, ...test.sub_tests] : [test];
      setSelectedTests((prevTests) => [...prevTests, ...allTests]);
      setSearchText("");
    }
  };

  const handleRemoveTest = (index) => {
    const updatedTests = [...selectedTests];
    updatedTests.splice(index, 1);
    setSelectedTests(updatedTests);
  };

  const handleChangeField = (e) => {
    const { name, value } = e.target;
    setOrderData({ ...orderData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    let isValid = true;

    if (!orderData.patient) {
      errors.patient = t("laboratory.surgeryRequired");
      isValid = false;
    }
    if (!orderData.doctor) {
      errors.doctor = t("laboratory.doctorRequired");
      isValid = false;
    }

    setFormErrors(errors);

    if (!isValid) return;

    const payload = {
      patient: orderData.patient, // Surgery ID
      doctor: orderData.doctor,
      urgency: orderData.urgency,
      discount: parseFloat(orderData.discount) || 0,
      tests: selectedTests.map((test) => ({ test_type: test.id })),
    };

    handleAdd(payload);
  };

  const calculateTotal = () => {
    let totalCost = 0;

    selectedTests.forEach((test) => {
      if (test.is_parent_test) {
        if (test.cost) {
          totalCost += parseFloat(test.cost);
        } else if (test.child_tests?.length) {
          test.child_tests.forEach((child) => {
            if (child.cost) {
              totalCost += parseFloat(child.cost);
            }
          });
        }
      } else {
        if (test.cost) {
          totalCost += parseFloat(test.cost);
        }
      }
    });

    const discount = parseFloat(orderData.discount) || 0;
    return Math.max(0, totalCost - discount);
  };

  return (
    <Card
      sx={{
        boxShadow: "0px 20px 50px rgba(0, 0, 0, 0.1)",
        borderRadius: 4,
        backgroundColor: "#f4f6f8",
        width: "100%",
      }}
    >
      <CardContent sx={{ p: 4 }}>
        <Typography
          variant="h5"
          fontWeight={600}
          color="primary"
          textAlign="center"
          gutterBottom
        >
          {t("laboratory.addSurgeryTestsTitle")}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Box mb={4}>
          <Typography variant="subtitle1" fontWeight={500} mb={2}>
            {t("laboratory.surgeryInfo")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={t("laboratory.orderDate")}
                name="date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={orderData.date}
                onChange={handleChangeField}
                sx={{ backgroundColor: "#fff", borderRadius: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {loadingSurgeries ? (
                <CircularProgress size={24} />
              ) : (
                <Autocomplete
                  options={surgeries || []}
                  getOptionLabel={(option) =>
                    option ? `${option.patient_display} (ID: ${option.id})` : ""
                  }
                  value={
                    surgeries?.find(
                      (surgery) => surgery.id === orderData.patient
                    ) || null
                  }
                  onChange={(event, value) =>
                    setOrderData({
                      ...orderData,
                      patient: value ? value.id : "",
                    })
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      sx={{
                        padding: 1,
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        backgroundColor: "#f9f9f9",
                        borderRadius: 1,
                        "&:hover": {
                          backgroundColor: "#eef2f5",
                        },
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight={600}
                        sx={{ color: "text.primary" }}
                      >
                        {option.patient_display} (ID: {option.id})
                      </Typography>
                      <Divider flexItem />
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("pharmacy.surgery")}
                      placeholder={t("laboratory.searchBySurgery")}
                      error={!!formErrors.patient}
                      helperText={formErrors.patient}
                      sx={{ backgroundColor: "#fff", borderRadius: 2 }}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {loadingDoctors ? (
                <CircularProgress size={24} />
              ) : (
                <Autocomplete
                  options={doctors || []}
                  getOptionLabel={(option) => `${option.username}`}
                  value={
                    doctors?.find((doctor) => doctor.id === orderData.doctor) ||
                    null
                  }
                  onChange={(event, value) =>
                    setOrderData({
                      ...orderData,
                      doctor: value ? value.id : "",
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("laboratory.doctor")}
                      error={!!formErrors.doctor}
                      helperText={formErrors.doctor}
                      sx={{ backgroundColor: "#fff", borderRadius: 2 }}
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box mb={4}>
          <Typography variant="subtitle1" fontWeight={500} mb={2}>
            {t("laboratory.addTest")}
          </Typography>
          <Autocomplete
            value={null}
            inputValue={searchText}
            onInputChange={(event, newInputValue) =>
              setSearchText(newInputValue)
            }
            onChange={(event, value) => handleAddNewTest(value)}
            options={filteredTests}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("laboratory.searchTest")}
                placeholder={t("laboratory.searchTestPlaceholder")}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                sx={{ backgroundColor: "#fff", borderRadius: 2 }}
              />
            )}
          />
        </Box>

        <TableContainer component={Paper} elevation={1} sx={{ mb: 4 }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#eef2f5" }}>
              <TableRow>
                <TableCell>{t("laboratory.testName")}</TableCell>
                <TableCell align="center">{t("laboratory.testCost")}</TableCell>
                <TableCell align="center">{t("laboratory.action")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedTests.map((test, index) => (
                <TableRow key={index} hover>
                  <TableCell>{test.name}</TableCell>
                  <TableCell align="center">{test.cost || "N/A"}</TableCell>
                  <TableCell align="center">
                    <Tooltip title={t("laboratory.removeTest")} arrow>
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveTest(index)}
                      >
                        <RemoveCircleOutline />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Display Warning Message if Over Limit */}
        {isOverLimit && (
          <Alert severity="warning" sx={{ mb: 4 }}>
            {t("laboratory.totalCostExceedsLimit", {
              high_amount: existingLabTestAmount + calculateTotal(),
              laboratory_percentage: laboratoryPercentage,
            })}
            {/* Alternatively, if you prefer the exact message:
            The total cost ({existingLabTestAmount + calculateTotal()}) exceeds the laboratory percentage ({laboratoryPercentage}) for this surgery.
            */}
          </Alert>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={4}
        >
          <TextField
            label={t("laboratory.discount")}
            name="discount"
            type="number"
            value={orderData.discount}
            onChange={handleChangeField}
            sx={{
              width: "30%",
              backgroundColor: "#fff",
              borderRadius: 2,
            }}
          />
          <Typography variant="h6" fontWeight={600}>
            {t("laboratory.totalAmount")}: {calculateTotal()}؋
          </Typography>
        </Box>

        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            size="small"
            onClick={handleSubmit}
            variant="contained"
            disabled={loading}
            sx={{
              fontWeight: 700,
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}
          >
            {loading ? t("laboratory.saving") : t("laboratory.saveOrder")}
          </Button>
        </Stack>
      </CardContent>
      <Divider />
      <Box m={3}>
        <ListSurgeryPatientTest />
      </Box>
    </Card>
  );
};

export default SurgeryPatientTest;
