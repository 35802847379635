import React, { useRef, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Stack,
  IconButton,
  Tooltip,
  Box,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import useDelete from "../../../../api/useDelete";
import { useTranslation } from "react-i18next";
import SurgeryPrescriptionDetailsEdit from "./SurgeryPrescriptionDetailsEdit";

const SurgeryPrescriptionList = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { handleDelete: handleDeletePrescription, ConfirmDialog } = useDelete(
    "surgery-prescriptions",
    token,
  );
  const {
    handleDelete: handleDeletePrescriptionItem,
    ConfirmDialog: ConfirmDialogItem,
  } = useDelete("surgery-prescription-details", token);

  const { data, isLoading, isError, error } = useFetchObjects(
    ["surgery-prescriptions"],
    "surgery-prescriptions",
    token,
  );

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  if (isLoading) {
    return <Typography>{t("pharmacy.loading")}</Typography>;
  }

  if (isError) {
    return <Typography>{error.message}</Typography>;
  }

  if (data?.length === 0) {
    return <Typography>{t("pharmacy.no_prescriptions")}</Typography>;
  }

  return (
    <Grid container spacing={3} padding="20px" mt={3}>
      {data.map((prescription, index) => (
        <Grid item xs={12} md={6} key={index}>
          <Paper
            elevation={4}
            sx={{
              mb: 3,
              padding: "24px",
              borderRadius: "12px",
              width: "100%",
              backgroundColor: "#f4f6f8",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Prescription Header */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <Typography
                variant="h6"
                fontWeight="bold"
                color="primary"
                sx={{ fontSize: isSmallScreen ? "1rem" : "1.25rem" }}
              >
                {t("pharmacy.prescription")} #{prescription.id} -{" "}
                {prescription.date}
              </Typography>
              <Stack direction="row" justifyContent="flex-end">
                <Tooltip title={t("pharmacy.delete_prescription")}>
                  <IconButton
                    color="error"
                    onClick={() => handleDeletePrescription(prescription.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Box>

            {/* Surgery Info */}
            <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
              <Typography>
                <strong>{t("pharmacy.surgeryType")}:</strong>{" "}
                {prescription.surgery_details?.surgery_type_display}
              </Typography>
              <Typography>
                <strong>{t("pharmacy.total_price")}:</strong>{" "}
                {prescription.calculate_total_price} ؋
              </Typography>
            </Stack>

            <Divider sx={{ mt: 2, mb: 2 }} />

            {/* Accordion for Prescription Details (Medicines) */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography fontWeight="bold">
                  {t("pharmacy.medicines")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {prescription.details?.length > 0 ? (
                  <Box sx={{ overflowX: "auto" }}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("pharmacy.category")}</TableCell>
                          <TableCell>{t("pharmacy.medicine")}</TableCell>
                          <TableCell>{t("pharmacy.quantity")}</TableCell>
                          <TableCell>{t("pharmacy.price")}</TableCell>
                          <TableCell>{t("pharmacy.total_price")}</TableCell>
                          <TableCell align="center">
                            {t("pharmacy.actions")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {prescription.details.map((item) => (
                          <TableRow
                            key={item.id}
                            sx={{
                              "&:hover": {
                                backgroundColor: "#f0f4f8",
                              },
                            }}
                          >
                            <TableCell>
                              <Typography>
                                {item.stock_medicine_category}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>
                                {item.stock_medicine_name}
                              </Typography>
                            </TableCell>
                            <TableCell>{item.quantity}</TableCell>
                            <TableCell>{item.stock_medicine_price}</TableCell>
                            <TableCell>{item.calculate_price}</TableCell>
                            <TableCell align="center">
                              <Tooltip title={t("pharmacy.edit_item")}>
                                <IconButton
                                  color="primary"
                                  onClick={() => handleOpen(item)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t("pharmacy.delete_item")}>
                                <IconButton
                                  color="error"
                                  onClick={() =>
                                    handleDeletePrescriptionItem(item.id)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                ) : (
                  <Typography>{t("pharmacy.no_medicines")}</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
      ))}

      <ConfirmDialog />
      <ConfirmDialogItem />

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <Typography fontWeight="bold" color="primary">
            {t("pharmacy.edit_prescription_item")}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ padding: "20px" }}>
          {selectedItem && (
            <SurgeryPrescriptionDetailsEdit
              prescriptionDetail={selectedItem}
              onClose={handleClose}
              token={token}
            />
          )}
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default SurgeryPrescriptionList;
