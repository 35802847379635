import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useTranslation } from "react-i18next";

const usePurchaseDelete = (queryKey, token) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [deleteMessage, setDeleteMessage] = useState("");
  const [deleteMessageOpen, setDeleteMessageOpen] = useState(false);
  const [deleteMessageSeverity, setDeleteMessageSeverity] = useState("success");

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const deleteMutation = useMutation({
    mutationFn: async (id) => {
      try {
        const headers = token ? { Authorization: `Token ${token}` } : {};
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/${queryKey}/${id}/`,
          {
            headers,
          },
        );
      } catch (error) {
        throw new Error(
          error.response?.data?.detail || t("use_delete.deletfeild"),
        );
      }
    },
    onSuccess: () => {
      setDeleteMessage(t("use_delete.deletesuccec"));
      setDeleteMessageSeverity("success");
      setDeleteMessageOpen(true);
      queryClient.invalidateQueries(queryKey);
    },
    onError: (error) => {
      setDeleteMessage(error.message);
      setDeleteMessageSeverity("error");
      setDeleteMessageOpen(true);
    },
  });

  const handleDelete = (id) => {
    setItemToDelete(id);
    setConfirmDialogOpen(true);
  };

  const confirmDelete = () => {
    deleteMutation.mutate(itemToDelete);
    setConfirmDialogOpen(false);
  };

  useEffect(() => {
    if (deleteMessageOpen) {
      const timer = setTimeout(() => {
        setDeleteMessageOpen(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [deleteMessageOpen]);

  return {
    handleDelete,
    confirmDialogOpen,
    setConfirmDialogOpen,
    confirmDelete,
    deleteMessageOpen,
    setDeleteMessageOpen,
    deleteMessage,
    deleteMessageSeverity,
  };
};

export default usePurchaseDelete;
