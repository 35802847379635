import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import useUpdate from "../../../../api/useUpdate";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";

const PrescriptionDetailsEdit = ({ prescriptionDetail, onClose, token }) => {
  const { t } = useTranslation();
  const { data: medicines, isLoading: loadingMedicines } = useFetchObjects(
    ["stock-medicines"],
    "stock-medicines",
    token,
  );

  const [input, setInput] = useState({
    stock_medicine: prescriptionDetail?.stock_medicine || "",
    dosage: prescriptionDetail?.dosage || "",
    quantity: prescriptionDetail?.quantity || "",
    notation: prescriptionDetail?.notation || "",
    is_before: prescriptionDetail?.is_before || "after",
  });

  const [formErrors, setFormErrors] = useState({});
  const { handleUpdate, isLoading: updating } = useUpdate(
    "prescription-details",
    token,
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    let isValid = true;

    if (!input.stock_medicine) {
      errors.stock_medicine = t("pharmacy.medicineRequired");
      isValid = false;
    }

    if (!input.dosage) {
      errors.dosage = t("pharmacy.dosageRequired");
      isValid = false;
    }

    if (!input.quantity) {
      errors.quantity = t("pharmacy.quantityRequired");
      isValid = false;
    }

    setFormErrors(errors);

    if (isValid) {
      const formData = new FormData();
      formData.append("stock_medicine", input.stock_medicine);
      formData.append("dosage", input.dosage);
      formData.append("quantity", input.quantity);
      formData.append("notation", input.notation);
      formData.append("is_before", input.is_before);

      handleUpdate(prescriptionDetail.id, formData);
      onClose();
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
      {loadingMedicines ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ py: 3 }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TextField
            select
            fullWidth
            label={t("pharmacy.medicine")}
            name="stock_medicine"
            size="small"
            margin="normal"
            value={input.stock_medicine}
            onChange={handleChange}
            error={!!formErrors.stock_medicine}
            helperText={formErrors.stock_medicine}
            disabled={loadingMedicines}
          >
            {medicines?.map((medicine) => (
              <MenuItem key={medicine.id} value={medicine.id}>
                {medicine.medicine_display}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            label={t("pharmacy.dosage")}
            name="dosage"
            type="text"
            margin="normal"
            size="small"
            error={!!formErrors.dosage}
            helperText={formErrors.dosage}
            value={input.dosage}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            label={t("pharmacy.quantity")}
            name="quantity"
            type="number"
            margin="normal"
            size="small"
            error={!!formErrors.quantity}
            helperText={formErrors.quantity}
            value={input.quantity}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            label={t("pharmacy.notation")}
            name="notation"
            type="text"
            margin="normal"
            size="small"
            value={input.notation}
            onChange={handleChange}
          />

          <FormControl fullWidth margin="normal" size="small">
            <InputLabel id="is-before-label">
              {t("pharmacy.isBeforeMeal")}
            </InputLabel>
            <Select
              labelId="is-before-label"
              label={t("pharmacy.isBeforeMeal")}
              name="is_before"
              value={input.is_before}
              onChange={handleChange}
            >
              <MenuItem value="before">{t("pharmacy.before")}</MenuItem>
              <MenuItem value="after">{t("pharmacy.after")}</MenuItem>
            </Select>
          </FormControl>

          <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
            <Button onClick={onClose} variant="outlined" size="small">
              {t("pharmacy.cancel")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="small"
              sx={{ backgroundColor: "green", color: "white" }}
              disabled={updating}
            >
              {updating ? <CircularProgress size={20} /> : t("pharmacy.save")}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default PrescriptionDetailsEdit;
