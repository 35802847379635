import React, { useEffect, useState, useTransition } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit"; // Import Edit Icon
import SearchIcon from "@mui/icons-material/Search";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import useDelete from "../../../../api/useDelete";
import SkeletonLoading from "./loading/SkeletonLoading";
import UpdateUltrasoundTypeDialog from "./UpdateUltrasoundTypeDialog"; // Import the Update Dialog component
import { useTranslation } from "react-i18next";

const ListUltrasoundTypes = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedType, setSelectedType] = useState(null); // Store selected type for editing
  const [openDialog, setOpenDialog] = useState(false); // Control the dialog open/close state

  const { data, isLoading, isError, error, refetch } = useFetchObjects(
    ["ultrasound-types"],
    `ultrasound-types/?search=${searchTerm}`,
    token,
  );

  useEffect(() => {
    refetch();
  }, [searchTerm]);

  const { handleDelete, ConfirmDialog } = useDelete("ultrasound-types", token);

  // Open dialog for editing
  const handleEdit = (type) => {
    setSelectedType(type);
    setOpenDialog(true);
  };

  // Close the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedType(null);
  };
  const tableStyle = {
    textAlign: "center",
    fontWeight: "bold",
  };
  const tableCellStyle = {
    textAlign: "center",
  };

  return (
    <Paper
      sx={{
        padding: "30px 40px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      <Typography variant="h5" gutterBottom>
        {t("ultrasound.listUltType")}
      </Typography>

      {/* Search bar */}
      <TextField
        label={t("ultrasound.search")}
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 4 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder={t("ultrasound.searchNameOrDescription")}
      />

      {/* Loading state */}
      {isLoading && <SkeletonLoading />}
      {isError && (
        <Typography color="error">
          {t("ultrasound.error")}: {error.message}
        </Typography>
      )}

      {/* Ultrasound types table */}
      {data && (
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead style={{ backgroundColor: "#f5f5f5" }}>
              <TableRow>
                <TableCell style={tableStyle}>
                  {t("ultrasound.typeName")}
                </TableCell>
                <TableCell style={tableStyle}>
                  {t("ultrasound.description")}
                </TableCell>
                <TableCell style={tableStyle}>{t("ultrasound.cost")}</TableCell>
                <TableCell style={tableStyle}>
                  {t("ultrasound.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((type) => (
                <TableRow
                  key={type.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:hover": { backgroundColor: "#f9f9f9" },
                    transition: "0.3s",
                  }}
                >
                  <TableCell style={tableCellStyle}>{type.name}</TableCell>
                  <TableCell style={tableCellStyle}>
                    {type.description || t("ultrasound.noDescription")}
                  </TableCell>
                  <TableCell style={tableCellStyle}>
                    {type.cost || "N/A"}
                  </TableCell>
                  <TableCell style={tableCellStyle}>
                    {/* Edit Button */}
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(type)}
                    >
                      <EditIcon />
                    </IconButton>
                    {/* Delete Button */}
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(type.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <ConfirmDialog />

      {/* No data found state */}
      {!isLoading && data?.length === 0 && (
        <Typography variant="body1" color="textSecondary" mt={2}>
          {t("ultrasound.noTypeFound")}
        </Typography>
      )}

      {/* Update Dialog */}
      {selectedType && (
        <UpdateUltrasoundTypeDialog
          open={openDialog}
          onClose={handleCloseDialog}
          type={selectedType}
          token={token}
          refetch={refetch} // To refetch data after update
        />
      )}
    </Paper>
  );
};

export default ListUltrasoundTypes;
