import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import useUpdate from "../../../../api/useUpdate";
import useFetchObjects from "../../../../api/useFetchObjects";
import { Months, years } from "../../../../utils";
import { useTranslation } from "react-i18next";

const AdvanceEdit = ({ advance, onClose, token }) => {
  const { t } = useTranslation();
  const { data: employeeData, isLoading: loadingEmployees } = useFetchObjects(
    ["users"],
    "users",
    token,
  );
  const [input, setInput] = useState({
    employee: advance?.employee || "",
    amount: advance?.amount || "",
    month: advance?.month || "",
    year: advance?.year || "",
    date: advance?.date || "",
  });
  const [formErrors, setFormErrors] = useState({});
  const { handleUpdate, isLoading: updating } = useUpdate(
    "employee-advance",
    token,
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    let isValid = true;

    if (!input.employee) {
      errors.employee = t("hr.employeeRequired");
      isValid = false;
    }

    if (!input.amount) {
      errors.amount = t("hr.amountRequired");
      isValid = false;
    }

    if (!input.month) {
      errors.month = t("hr.monthRequired");
      isValid = false;
    }

    if (!input.year) {
      errors.year = t("hr.yearRequired");
      isValid = false;
    }

    setFormErrors(errors);

    if (isValid) {
      const formData = new FormData();
      formData.append("employee", input.employee);
      formData.append("amount", input.amount);
      formData.append("month", input.month);
      formData.append("year", input.year);
      formData.append("date", input.date);
      handleUpdate(advance.id, formData);
      onClose();
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
      {loadingEmployees ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ py: 3 }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TextField
            select
            fullWidth
            label={t("hr.employee")}
            name="employee"
            size="small"
            margin="normal"
            value={input.employee}
            onChange={handleChange}
            error={!!formErrors.employee}
            helperText={formErrors.employee}
            disabled={loadingEmployees}
          >
            {employeeData?.map((employee) => (
              <MenuItem key={employee.id} value={employee.id}>
                {employee.first_name} {employee.last_name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            label={t("hr.amount")}
            name="amount"
            margin="normal"
            size="small"
            error={!!formErrors.amount}
            helperText={formErrors.amount}
            value={input.amount}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            select
            label={t("hr.month")}
            name="month"
            margin="normal"
            size="small"
            value={input.month}
            onChange={handleChange}
            error={!!formErrors.month}
            helperText={formErrors.month}
          >
            {Months.map((monthOption) => (
              <MenuItem key={monthOption.id} value={monthOption.id}>
                {monthOption.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            select
            label={t("hr.year")}
            name="year"
            margin="normal"
            size="small"
            value={input.year}
            onChange={handleChange}
            error={!!formErrors.year}
            helperText={formErrors.year}
          >
            {years.map((yearOption) => (
              <MenuItem key={yearOption} value={yearOption}>
                {yearOption}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            name="date"
            type="date"
            label={t("hr.date")}
            margin="normal"
            size="small"
            InputLabelProps={{ shrink: true }}
            error={!!formErrors.date}
            helperText={formErrors.date}
            value={input.date}
            onChange={handleChange}
          />

          <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
            <Button onClick={onClose} variant="outlined" size="small">
              {t("hr.cancel")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="small"
              sx={{ backgroundColor: "green", color: "white" }}
              disabled={updating}
            >
              {updating ? <CircularProgress size={20} /> : t("hr.save")}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AdvanceEdit;
