import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Paper,
  Typography,
  Stack,
  Box,
  Chip,
  Container,
  TextField,
  Button,
  useMediaQuery,
  useTheme,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import useUpdate from "../../../../api/useUpdate";
import { useTranslation } from "react-i18next";
import Print from "./Print";

const CollectPrescriptionFees = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [printData, setPrintData] = useState(null);
  const printRef = useRef();

  const [searchTerm, setSearchTerm] = useState("");
  const [query, setQuery] = useState("");

  const { data = [], refetch } = useFetchObjects(
    ["collect-prescription"],
    `prescriptions/?search=${query}`,
    token,
    {
      refetchInterval: 5000,
      refetchOnWindowFocus: true,
    }
  );

  useEffect(() => {
    setQuery(searchTerm);
    refetch();
  }, [searchTerm, refetch]);

  const { handleUpdate, isLoading: updateLoading } = useUpdate(
    "prescriptions",
    token
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [discountValue, setDiscountValue] = useState("");

  const handleOpenDialog = (prescription) => {
    setSelectedPrescription(prescription);
    setDiscountValue(prescription.discount || "");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedPrescription(null);
    setDiscountValue("");
  };

  const handleSaveDiscount = () => {
    const formData = new FormData();
    formData.append("discount", discountValue);
    handleUpdate(selectedPrescription.id, formData);
    setDialogOpen(false);
  };

  const handleStatusChange = (id) => {
    const formData = new FormData();
    formData.append("payment_status", 2);
    handleUpdate(id, formData);
  };

  const handlePrintClick = (order) => {
    setPrintData(order);
    setTimeout(() => {
      if (printRef.current) {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(printRef.current.innerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }
    }, 500);
  };

  const getPaymentStatusLabel = (status) => {
    switch (status) {
      case 1:
        return t("reception.pending");
      case 2:
        return t("reception.paid");
      case 3:
        return t("reception.canceled");
      default:
        return t("reception.unknown");
    }
  };

  const getPaymentStatusColor = (status) => {
    switch (status) {
      case 1:
        return "warning";
      case 2:
        return "success";
      case 3:
        return "error";
      default:
        return "default";
    }
  };

  const isCollectButtonDisabled = (prescription) => {
    return false;
  };

  return (
    <Container maxWidth="xl">
      <Box my={4}>
        <TextField
          fullWidth
          label={t("reception.searchPrescription")}
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        />
      </Box>
      <Grid container spacing={3} padding="20px" mt={3}>
        {data.length === 0 ? (
          <Typography>{t("reception.noMatching")}</Typography>
        ) : (
          data.map((prescription, index) => {
            const isPaid = prescription.payment_status === 2;

            return (
              <Grid item xs={12} md={6} key={index}>
                <Paper
                  elevation={isPaid ? 2 : 4}
                  sx={{
                    mb: 3,
                    padding: "24px",
                    borderRadius: "12px",
                    width: "100%",
                    boxShadow: isPaid
                      ? "0px 4px 12px rgba(0, 0, 0, 0.1)"
                      : "0px 8px 24px rgba(255, 0, 0, 0.3)",
                    backgroundColor: isPaid ? "#f9f9f9" : "#ffebee",
                    borderLeft: isPaid ? "none" : "4px solid #d32f2f",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color="primary"
                      sx={{ fontSize: isSmallScreen ? "1rem" : "1.25rem" }}
                    >
                      {t("reception.prescription")} #{prescription.id} -{" "}
                      {prescription.date}
                    </Typography>
                    <Box>
                      <Chip
                        label={getPaymentStatusLabel(
                          prescription.payment_status
                        )}
                        color={getPaymentStatusColor(
                          prescription.payment_status
                        )}
                        sx={{
                          fontWeight: "bold",
                          fontSize: isSmallScreen ? "0.8rem" : "0.9rem",
                        }}
                      />
                      <IconButton
                        color="primary"
                        onClick={() => handlePrintClick(prescription)}
                      >
                        <PrintIcon />
                      </IconButton>
                    </Box>
                  </Box>

                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ mt: 2 }}
                  >
                    <Typography
                      fontSize={isSmallScreen ? "0.85rem" : "1rem"}
                      color={!isPaid ? "error.main" : "text.primary"}
                    >
                      <strong>{t("reception.doctor")}:</strong>{" "}
                      {prescription.doctor_display}
                    </Typography>
                    <Typography fontSize={isSmallScreen ? "0.85rem" : "1rem"}>
                      <strong>{t("reception.patient")}:</strong>{" "}
                      {prescription.patient_display}
                    </Typography>
                    <Typography fontSize={isSmallScreen ? "0.85rem" : "1rem"}>
                      <strong>{t("reception.totalPrice")}:</strong>{" "}
                      {prescription.total_price} ؋
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                    sx={{ mt: 2 }}
                  >
                    {!isPaid &&
                      !prescription.is_discount_approved && ( // Only show if not paid and discount not approved
                        <Button
                          variant="outlined"
                          onClick={() => handleOpenDialog(prescription)}
                          sx={{ textTransform: "none", fontWeight: "bold" }}
                        >
                          {t("reception.addDiscount")}
                        </Button>
                      )}
                    {!isPaid && ( // Only show Collect Fees button if not paid
                      <Button
                        variant="contained"
                        disabled={isCollectButtonDisabled(prescription)}
                        onClick={() => handleStatusChange(prescription.id)}
                        startIcon={
                          updateLoading && <CircularProgress size={24} />
                        }
                        sx={{
                          textTransform: "none",
                          fontWeight: "bold",
                          backgroundColor: "#4caf50",
                          color: "#fff",
                        }}
                      >
                        {t("reception.collectFees")}
                      </Button>
                    )}
                  </Stack>
                </Paper>
              </Grid>
            );
          })
        )}

        {/* Print Component */}
        {printData && (
          <div ref={printRef} style={{ display: "none" }}>
            <Print data={printData} />
          </div>
        )}
      </Grid>
      {/* Discount Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{t("reception.addDiscount")}</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            {t("reception.totalPrice")}: {selectedPrescription?.total_price} ؋
          </Typography>
          <TextField
            fullWidth
            label={t("reception.enterDiscount")}
            type="number"
            value={discountValue}
            onChange={(e) => setDiscountValue(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t("reception.cancel")}</Button>
          <Button
            onClick={handleSaveDiscount}
            variant="contained"
            color="primary"
            disabled={updateLoading}
          >
            {t("reception.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CollectPrescriptionFees;
