import React, { useState } from "react";
import {
  Container,
  Box,
  TextField,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import SurgeryDetailsDialog from "../TestsTobeDone/SurgeryDetailsDialog";
import { useTranslation } from "react-i18next";

const SurgeryList = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [selectedCoDoctor, setSelectedCoDoctor] = useState("");
  const [selectedAnesthetist, setSelectedAnesthetist] = useState("");
  const [selectedSurgeryType, setSelectedSurgeryType] = useState("");
  const [selectedSurgery, setSelectedSurgery] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const filters = [
    searchTerm ? `search=${searchTerm}` : "",
    selectedDoctor ? `doctor=${selectedDoctor}` : "",
    selectedCoDoctor ? `co_doctor=${selectedCoDoctor}` : "",
    selectedAnesthetist ? `anesthetist=${selectedAnesthetist}` : "",
    selectedSurgeryType ? `surgery_type=${selectedSurgeryType}` : "",
  ]
    .filter(Boolean)
    .join("&");

  const { data: surgeries = [] } = useFetchObjects(
    ["surgeries", filters],
    `surgeries/?${filters}`,
    token
  );

  const handleOpenDialog = (surgery) => {
    setSelectedSurgery(surgery);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedSurgery(null);
  };

  const handleResetFilters = () => {
    setSearchTerm("");
    setSelectedDoctor("");
    setSelectedCoDoctor("");
    setSelectedAnesthetist("");
    setSelectedSurgeryType("");
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 5 }}>
      <Paper elevation={4} sx={{ p: 4, borderRadius: 2, width: "100%" }}>
        <Typography variant="h4" align="center" fontWeight="bold" gutterBottom>
          {t("operations.surgeryList")}
        </Typography>

        {/* Search and Filter Section */}
        <Box sx={{ mb: 4 }}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              borderRadius: 2,
              backgroundColor: "#f7f7f7",
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label={t("operations.searchByPatientName")}
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label={t("operations.filterByDoctor")}
                  variant="outlined"
                  value={selectedDoctor}
                  onChange={(e) => setSelectedDoctor(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label={t("operations.filterByCoDoctor")}
                  variant="outlined"
                  value={selectedCoDoctor}
                  onChange={(e) => setSelectedCoDoctor(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label={t("operations.filterByCoAnesthetist")}
                  variant="outlined"
                  value={selectedAnesthetist}
                  onChange={(e) => setSelectedAnesthetist(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>{t("operations.filterBySurgeryType")}</InputLabel>
                  <Select
                    value={selectedSurgeryType}
                    onChange={(e) => setSelectedSurgeryType(e.target.value)}
                    label={t("operations.filterBySurgeryType")}
                  >
                    <MenuItem value="">
                      <em>{t("operations.allType")}</em>
                    </MenuItem>
                    <MenuItem value={1}>{t("operations.internal")}</MenuItem>
                    <MenuItem value={2}>{t("operations.referred")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleResetFilters}
                  sx={{ ml: 2 }}
                >
                  {t("operations.resetFilters")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>

        {/* Surgery Table */}
        <TableContainer component={Paper} elevation={3} sx={{ width: "100%" }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("operations.patientName")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("operations.surgeryType")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("operations.surgeryDate")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("operations.doctor")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("operations.coDoctor")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("operations.anesthetist")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("operations.status")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("operations.totalAmount")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("operations.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {surgeries.map((surgery) => (
                <TableRow key={surgery.id} hover>
                  <TableCell align="center">
                    {surgery.patient_display}
                  </TableCell>
                  <TableCell align="center">
                    {surgery.surgery_type_display}
                  </TableCell>
                  <TableCell align="center">
                    {new Date(surgery.surgery_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">
                    {surgery.doctor || "N/A"}
                  </TableCell>
                  <TableCell align="center">
                    {surgery.co_doctor || "N/A"}
                  </TableCell>
                  <TableCell align="center">
                    {surgery.anesthetist || "N/A"}
                  </TableCell>
                  <TableCell align="center">
                    {surgery.surgery_state_display}
                  </TableCell>
                  <TableCell align="center">
                    {surgery.total_surgery_amount
                      ? `؋${surgery.total_surgery_amount}`
                      : "N/A"}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<VisibilityIcon sx={{ margin: "0px 4px" }} />}
                      onClick={() => handleOpenDialog(surgery)}
                    >
                      {t("operations.details")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Surgery Details Dialog */}
      <SurgeryDetailsDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        surgery={selectedSurgery}
      />
    </Container>
  );
};

export default SurgeryList;
