import { Grid } from "@mui/material";
import React from "react";
import DepartmentsList from "./DepartmentsList/DepartmentsList";
import AddDepartment from "./AddDepartment/AddDepartment";

const DepartmentsIndex = () => {
  return (
    <Grid container maxWidth="xl" mb={3}>
      <Grid container gap={1} mt={2} justifyContent={"center"}>
        <Grid item xs={12} lg={8}>
          <Grid container>
            <DepartmentsList />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={3.7} bgcolor={"white"}>
          <AddDepartment />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DepartmentsIndex;
