import PharmacyNav from "../../pages/Dashboard/Pharmacy/PharmacyNav";
import Pos from "../../pages/Dashboard/Pharmacy/POS/Pos";
import AddPrescription from "../../pages/Dashboard/Pharmacy/prescription/Prescription";
import AddMedicine from "../../pages/Dashboard/Pharmacy/medicine/addMedicine/AddMedicine";
import ListMedicine from "../../pages/Dashboard/Pharmacy/medicine/ListMedicine/ListMedicine";
import PharmacyIndex from "../../pages/Dashboard/Pharmacy/PharmacyIndex";
import PurchaseDrug from "../../pages/Dashboard/Pharmacy/PurchaseDrug/PurchaseDrug";
import MedicineStock from "../../pages/Dashboard/Pharmacy/stock/MedicineStock";
import MedicineExpirySexMonthLater from "../../pages/Dashboard/Pharmacy/MedicineExpirySexMonthLater/MedicineExpirySexMonthLater";
import ManageCompanyMedicine from "../../pages/Dashboard/Pharmacy/companyMedicine/ManageCompanyMedicine";
import SurgeryPrescription from "../../pages/Dashboard/Pharmacy/surgeryPrescription/SurgeryPrescription";
import LowQuantityStockMedicine from "../../pages/Dashboard/Pharmacy/LowQuantityStockMedicine/LowQuantityStockMedicine";
import AddReturnMedicine from "../../pages/Dashboard/Pharmacy/ReturnMedicine/addReturnMedicine/AddReturnMedicine";

const pharmacyRoutes = {
  path: "/pharmacy",
  element: <PharmacyNav />,
  children: [
    { path: "/pharmacy", element: <PharmacyIndex /> },
    { path: "/pharmacy/pos", element: <Pos /> },
    { path: "/pharmacy/prescription", element: <AddPrescription /> },
    {
      path: "/pharmacy/surgery-prescription",
      element: <SurgeryPrescription />,
    },
    { path: "/pharmacy/new-medicine", element: <AddMedicine /> },
    { path: "/pharmacy/medicine-list", element: <ListMedicine /> },
    { path: "/pharmacy/medicine-company", element: <ManageCompanyMedicine /> },
    { path: "/pharmacy/purchase-drug", element: <PurchaseDrug /> },
    {
      path: "/pharmacy/return-medicine",
      element: <AddReturnMedicine />,
    },
    { path: "/pharmacy/medicine-stock", element: <MedicineStock /> },
    {
      path: "/pharmacy/medicine-expiry-six-months",
      element: <MedicineExpirySexMonthLater />,
    },
    {
      path: "/pharmacy/low-quantity-stock",
      element: <LowQuantityStockMedicine />,
    },
  ],
};

export default pharmacyRoutes;
