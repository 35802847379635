// src/components/Expenses/AddExpenseTypeDialog.js
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import useAdd from "../../../../api/useAdd";
import { useTranslation } from "react-i18next";

const AddExpenseTypeDialog = ({ open, onClose, token }) => {
  const { t } = useTranslation();
  const [expenseTypeName, setExpenseTypeName] = useState("");
  const { handleAdd, loading, resetForm } = useAdd("expense-types", token);

  useEffect(() => {
    if (resetForm) {
      setExpenseTypeName("");
      onClose();
    }
  }, [resetForm]);

  const handleAddExpenseType = () => {
    handleAdd({ name: expenseTypeName });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Expense Type</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Expense Type Name"
          type="text"
          fullWidth
          variant="outlined"
          value={expenseTypeName}
          onChange={(e) => setExpenseTypeName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleAddExpenseType}
          color="primary"
          disabled={loading || !expenseTypeName}
          startIcon={loading && <CircularProgress size={20} />}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddExpenseTypeDialog;
