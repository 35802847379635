import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material"; // Icons for urgency
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns"; // For human-readable dates

const UltrasoundList = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [status, setStatus] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);

  const auth = useAuth();
  const token = auth?.user?.token;

  // Fetch Ultrasound data
  const {
    data: ultrasoundData,
    refetch,
    isLoading: isUltrasoundLoading,
    isError: isUltrasoundError,
  } = useFetchObjects(
    ["ultrasounds"],
    `ultrasounds/?start_datetime=${startDateTime}&end_datetime=${endDateTime}&status=${status}&doctor=${
      selectedUser?.id || ""
    }`,
    token,
  );

  const {
    data: usersData,
    isLoading: isUsersLoading,
    isError: isUsersError,
  } = useFetchObjects(["users"], `users/`, token);

  useEffect(() => {
    refetch();
  }, [status, selectedUser, startDateTime, endDateTime, refetch]);

  useEffect(() => {
    // Calculate total amount
    if (ultrasoundData) {
      const total = ultrasoundData.reduce(
        (sum, ultrasound) => sum + parseFloat(ultrasound.total_amount || 0),
        0,
      );
      setTotalAmount(total);
    }
  }, [ultrasoundData]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setStatus(
      newValue === 1 ? 1 : newValue === 2 ? 2 : newValue === 3 ? 3 : "",
    );
  };

  const getChipColor = (status) => {
    switch (status) {
      case 1:
        return "orange"; // Pending
      case 2:
        return "green"; // Completed
      case 3:
        return "red"; // Cancelled
      default:
        return "grey"; // Unknown
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return t("finance.pending");
      case 2:
        return t("finance.completed");
      case 3:
        return t("finance.canceled");
      default:
        return t("finance.status.unknown");
    }
  };

  const renderUrgencyIcon = (isUrgent) => {
    return isUrgent ? (
      <CheckCircle sx={{ color: "green", fontSize: "1.5rem" }} />
    ) : (
      <Cancel sx={{ color: "red", fontSize: "1.5rem" }} />
    );
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    return format(parseISO(dateString), "PPpp"); // Format to human-readable
  };

  return (
    <Box sx={{ px: 3, py: 2, bgcolor: "#f5f7fa", minHeight: "100vh" }}>
      <Typography variant="h5" gutterBottom>
        {t("finance.listUltrasounds")}
      </Typography>

      {/* Filters Section */}
      <Card
        sx={{
          mb: 3,
          borderRadius: 2,
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          bgcolor: "white",
        }}
      >
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label={t("finance.all")} />
                <Tab label={t("finance.pending")} />
                <Tab label={t("finance.completed")} />
                <Tab label={t("finance.canceled")} />
              </Tabs>
            </Grid>

            {/* Filters */}
            <Grid item xs={12} md={4}>
              <Autocomplete
                size="small"
                options={usersData || []}
                getOptionLabel={(option) => `${option.username}`}
                value={selectedUser}
                onChange={(e, newValue) => setSelectedUser(newValue)}
                loading={isUsersLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("finance.selectDoctor")}
                    error={isUsersError}
                    helperText={
                      isUsersError ? t("finance.errorFetchingUsers") : ""
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                label={t("finance.startDate")}
                type="datetime-local"
                value={startDateTime}
                onChange={(e) => setStartDateTime(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                label={t("finance.endDate")}
                type="datetime-local"
                value={endDateTime}
                onChange={(e) => setEndDateTime(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={refetch}
                sx={{ height: "100%" }}
              >
                {t("finance.applyFilter")}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Table Section */}
      {isUltrasoundLoading ? (
        <Box sx={{ textAlign: "center", mt: 3 }}>{t("finance.loading")}</Box>
      ) : isUltrasoundError ? (
        <Box sx={{ textAlign: "center", color: "red", mt: 3 }}>
          {t("finance.errorFetchingData")}
        </Box>
      ) : (
        <>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: 2,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              mt: 3,
            }}
          >
            <Table>
              <TableHead sx={{ bgcolor: "#f8f9fa" }}>
                <TableRow>
                  <TableCell align="center">{t("finance.date")}</TableCell>
                  <TableCell align="center">{t("finance.patient")}</TableCell>
                  <TableCell align="center">{t("finance.doctor")}</TableCell>
                  <TableCell align="center">{t("finance.type")}</TableCell>
                  <TableCell align="center">
                    {t("finance.totalAmount")}
                  </TableCell>
                  <TableCell align="center">{t("finance.urgency")}</TableCell>
                  <TableCell align="center">{t("finance.status")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ultrasoundData?.map((ultrasound, i) => (
                  <TableRow key={i}>
                    <TableCell align="center">
                      {formatDate(ultrasound.date)}
                    </TableCell>
                    <TableCell align="center">
                      {ultrasound.patient_display}
                    </TableCell>
                    <TableCell align="center">
                      {ultrasound.doctor_display}
                    </TableCell>
                    <TableCell align="center">
                      {ultrasound.ultrasound_type_display}
                    </TableCell>
                    <TableCell align="center">
                      {ultrasound.total_amount} ؋
                    </TableCell>
                    <TableCell align="center">
                      {renderUrgencyIcon(ultrasound.is_urgent)}
                    </TableCell>
                    <TableCell align="center">
                      <Chip
                        label={getStatusLabel(ultrasound.status)}
                        sx={{
                          bgcolor: getChipColor(ultrasound.status),
                          color: "white",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Total Amount Section */}
          <Card
            sx={{
              mt: 3,
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              bgcolor: "#ffffff",
              borderRadius: 2,
            }}
          >
            <Typography variant="h6">{t("finance.totalAmount")}</Typography>
            <Typography variant="h6" color="primary">
              {totalAmount} ؋
            </Typography>
          </Card>
        </>
      )}
    </Box>
  );
};

export default UltrasoundList;
