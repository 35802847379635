import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState, useEffect } from "react";
import { Grid, Button, CircularProgress, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const useUpdate = (queryKey, token, redirectPath) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const [formMessageOpen, setFormMessageOpen] = useState(false);
  const [formMessageSeverity, setFormMessageSeverity] = useState("success");
  const navigate = useNavigate();

  const updateMutation = useMutation({
    mutationFn: async ({ id, data }) => {
      setLoading(true);
      const headers = token ? { Authorization: `Token ${token}` } : {};
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_API_URL}/${queryKey}/${id}/`,
          data,
          { headers },
        );
        return response.data;
      } catch (error) {
        // If the error response has data, throw it for handling in onError
        if (error.response) {
          throw error.response;
        } else {
          throw new Error(t("use_update.updateFailed"));
        }
      }
    },
    onSuccess: () => {
      setLoading(false);
      setFormMessage(t("use_update.dataUpdatedSuccess"));
      setFormMessageSeverity("success");
      setFormMessageOpen(true);
      queryClient.invalidateQueries(queryKey);
      if (redirectPath) {
        navigate(redirectPath);
      }
    },
    onError: (error) => {
      setLoading(false);

      // Handle specific backend errors
      if (error.data && typeof error.data === "object") {
        Object.keys(error.data).forEach((key) => {
          const errorMsg = Array.isArray(error.data[key])
            ? error.data[key].join(", ")
            : error.data[key];
          toast.error(`${key}: ${errorMsg}`, {
            position: "top-right",
            autoClose: 3000,
          });
        });
      } else {
        // Generic error message if backend errors are not detailed
        const errorMessage = error.data?.detail || t("use_update.updateFailed");
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
        });
        setFormMessage(errorMessage);
        setFormMessageSeverity("error");
        setFormMessageOpen(true);
      }
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (formMessageOpen) {
      const timer = setTimeout(() => {
        setFormMessageOpen(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [formMessageOpen]);

  const handleUpdate = (id, data) => {
    updateMutation.mutate({ id, data });
  };

  const FormMessageComponent = () => (
    <Box
      sx={{
        display: formMessageOpen ? "flex" : "none",
        alignItems: "center",
        justifyContent: "space-between",
        bgcolor: formMessageSeverity === "success" ? "green" : "red",
        color: "#fff",
        padding: "10px 15px",
        borderRadius: "4px",
        mt: 2,
        position: "relative",
      }}
    >
      <Box>{formMessage}</Box>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setFormMessageOpen(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );

  const Buttons = ({ onSubmit }) => (
    <Grid container justifyContent={"end"} mt={2}>
      <Button
        variant="contained"
        color="primary"
        disabled={loading}
        sx={{ mr: 2 }}
      >
        {t("use_update.cancel")}
      </Button>
      <Button
        variant="contained"
        onClick={onSubmit}
        color="primary"
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} />}
      >
        {loading ? t("use_update.updating") : t("use_update.update")}
      </Button>
    </Grid>
  );

  return {
    handleUpdate,
    Buttons,
    FormMessageComponent,
    loading,
  };
};

export default useUpdate;
