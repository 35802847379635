import React, { useEffect, useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Grid,
  Avatar,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import {
  Inventory2,
  Search,
  ArrowDropUp,
  ArrowDropDown,
} from "@mui/icons-material";
import { StockMedicineSkeleton } from "./loading/StockMedicineSkeleton";
import { useTranslation } from "react-i18next";

const tableStyle = {
  textAlign: "center",
  cursor: "pointer", // Indicate clickable headers
};

const StockMedicine = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const pharmacy = auth?.user?.user?.pharmacy_type;

  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [sortField, setSortField] = useState(""); // Current sorting field
  const [sortOrder, setSortOrder] = useState("asc"); // Current sorting order

  const {
    data: stockMedicines,
    isLoading,
    isError,
    error,
    refetch,
  } = useFetchObjects(
    ["stock-medicines"],
    `stock-medicines/?pharmacy=${pharmacy}&search=${searchQuery}&medicine__category=${categoryFilter}&ordering=${
      sortOrder === "asc" ? sortField : `-${sortField}`
    }`,
    token
  );

  const { data: medicineCategory } = useFetchObjects(
    ["medicine-categories"],
    `medicine-categories/`,
    token
  );

  useEffect(() => {
    refetch();
  }, [searchQuery, categoryFilter, sortField, sortOrder]);

  // Toggle sorting
  const handleSort = (field) => {
    if (sortField === field) {
      // Toggle between ascending and descending
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      // Set new sorting field and default to ascending
      setSortField(field);
      setSortOrder("asc");
    }
  };

  // Calculate Total Amount
  const totalAmount = stockMedicines?.reduce((sum, stock) => {
    const quantity = parseFloat(stock.quantity) || 0;
    const sellPrice = parseFloat(stock.sell_price) || 0;
    return sum + quantity * sellPrice;
  }, 0);

  if (isLoading) {
    return <StockMedicineSkeleton />;
  }

  if (isError) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">{error.message}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "80vh",
        width: "100%",
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          padding: { xs: "1.5rem", md: "2.5rem" },
          boxShadow: "0px 0px 16px rgba(0,0,0,0.1)",
          backgroundColor: "#fff",
          zIndex: 1,
        }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Avatar sx={{ bgcolor: "#1976d2", width: 56, height: 56 }}>
              <Inventory2 fontSize="large" />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography fontWeight={700} variant="h4" color="primary">
              {t("pharmacy.stockMedicine")}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {t("pharmacy.overview")}
            </Typography>
          </Grid>
        </Grid>

        {/* Search and Filter Section */}
        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder={t("pharmacy.searchPlaceholder")}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#fff",
                borderRadius: 2,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel>{t("pharmacy.filterByCategory")}</InputLabel>
              <Select
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
                label={t("pharmacy.filterByCategory")}
              >
                <MenuItem value="">----------------</MenuItem>
                {medicineCategory?.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {/* Content Section */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto", // Scroll only this section
          padding: { xs: "1rem", md: "2rem" },
          backgroundColor: "#f8f9fa",
        }}
      >
        <TableContainer component={Box}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f0f0f0" }}>
                <TableCell style={tableStyle}>
                  <Typography fontWeight={700} color="primary">
                    #
                  </Typography>
                </TableCell>
                <TableCell
                  style={tableStyle}
                  onClick={() => handleSort("medicine__category")}
                >
                  <Typography fontWeight={700} color="primary">
                    {t("pharmacy.category")}
                  </Typography>
                </TableCell>
                <TableCell
                  style={tableStyle}
                  onClick={() => handleSort("medicine__name")}
                >
                  <Typography fontWeight={700} color="primary">
                    {t("pharmacy.medicineName")}{" "}
                    {sortField === "medicine__name" &&
                      (sortOrder === "asc" ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      ))}
                  </Typography>
                </TableCell>
                <TableCell
                  style={tableStyle}
                  onClick={() => handleSort("expiry_date")}
                >
                  <Typography fontWeight={700} color="primary">
                    {t("pharmacy.expiryDate")}{" "}
                    {sortField === "expiry_date" &&
                      (sortOrder === "asc" ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      ))}
                  </Typography>
                </TableCell>
                <TableCell style={tableStyle}>
                  <Typography fontWeight={700} color="primary">
                    {t("pharmacy.quantity")}
                  </Typography>
                </TableCell>
                <TableCell style={tableStyle}>
                  <Typography fontWeight={700} color="primary">
                    {t("pharmacy.sellPrice")} (؋)
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stockMedicines.map((stock, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:hover": { backgroundColor: "#f9f9f9" } }}
                >
                  <TableCell style={tableStyle}>
                    <Typography>{index + 1}</Typography>
                  </TableCell>
                  <TableCell style={tableStyle}>
                    <Typography>{stock.category_display}</Typography>
                  </TableCell>
                  <TableCell style={tableStyle}>
                    <Typography>{stock.medicine_display}</Typography>
                  </TableCell>
                  <TableCell style={tableStyle}>
                    <Typography>{stock.expiry_date}</Typography>
                  </TableCell>
                  <TableCell style={tableStyle}>
                    <Typography>{stock.quantity}</Typography>
                  </TableCell>
                  <TableCell style={tableStyle}>
                    <Typography>{stock.sell_price} ؋</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Footer Section */}
      <Box
        sx={{
          padding: { xs: "1rem", md: "2rem" },
          boxShadow: "0px -4px 16px rgba(0,0,0,0.1)",
          backgroundColor: "#fff",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            fontWeight={700}
            sx={{ marginRight: 2 }}
            color="primary"
          >
            {t("pharmacy.totalValue")}
          </Typography>
          <Typography variant="h6" fontWeight={700} sx={{ color: "#1976d2" }}>
            {parseFloat(totalAmount.toFixed(2))} ؋
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default StockMedicine;
