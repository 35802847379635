// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RouterProvider } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import "./lang/i18n";
import { AuthProvider, useAuth } from "./context";
import { getAccess } from "./permissions/Permissions";
import { createAppRouter } from "./Routes/AppRoutes";
import { NewRecordsProvider } from "./context/NewRecordsProvider";

const queryClient = new QueryClient({
  defaultOptions: {},
});

const AppWithRouter = () => {
  const auth = useAuth();
  const user = auth?.user;
  const permissions = getAccess(user?.user?.permissions, user?.user?.isAdmin);
  const router = createAppRouter(permissions);

  return <RouterProvider router={router} />;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <NewRecordsProvider>
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <React.StrictMode>
            <AppWithRouter />
          </React.StrictMode>
        </SnackbarProvider>
      </QueryClientProvider>
    </NewRecordsProvider>
  </AuthProvider>,
);
