import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Typography,
  IconButton,
  Box,
  Chip,
  Button,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import useFetchObjects from "../../../../api/useFetchObjects";
import useDelete from "../../../../api/useDelete";
import ErrorPage from "../../../../Components/GeneralComponents/errorPage/ErrorPage";
import { useAuth } from "../../../../context";
import AssignPatientDialog from "../assignmentRoom/AssignPatientDialog";
import useAdd from "../../../../api/useAdd";
import { useTranslation } from "react-i18next";
import EditRoom from "./EditRoom";

const RoomList = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const [open, setOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [patientId, setPatientId] = useState("");
  const [openDialog, setOpenDialog] = useState(null);
  const { handleAdd } = useAdd("room-assignment", token);
  const { handleDelete, ConfirmDialog } = useDelete();
  const [editRoomData, setEditRoomData] = useState("");
  const { data, isLoading, isError, isSuccess, error } = useFetchObjects(
    ["rooms"],
    "rooms/",
    token,
  );

  const handleOpenDialog = (room) => {
    if (
      room.total_beds_available > 0 ||
      window.confirm(
        "The room has no available beds. Are you sure you want to assign the patient to this room?",
      )
    ) {
      setSelectedRoom(room);
      setOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedRoom(null);
    setPatientId("");
  };

  const handleEditRoomDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleAssign = () => {
    const formData = new FormData();
    formData.append("patient", patientId);
    formData.append("room", selectedRoom.id);
    formData.append("is_active", true);
    handleAdd(formData);
    handleCloseDialog();
  };

  if (isError) {
    return <ErrorPage errorMessage={error.message} />;
  }

  if (isLoading) {
    return <Typography>{t("room.loading")}</Typography>;
  }

  if (isSuccess) {
    return (
      <>
        <TableContainer
          component={Paper}
          sx={{ marginTop: 3, boxShadow: 3, borderRadius: 2 }}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("room.roomNumber")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("room.roomType")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("room.capacity")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("room.bedsAvailable")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("room.roomPrice")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("room.floor")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("room.availability")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("room.actions")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("room.assignPatient")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((room) => (
                <TableRow
                  key={room.id}
                  hover
                  sx={{
                    transition: "0.3s",
                    "&:hover": { backgroundColor: "#f0f0f0" },
                  }}
                >
                  <TableCell align="center">
                    <Typography
                      variant="subtitle2"
                      sx={{ fontWeight: "medium" }}
                    >
                      {room.room_number}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      label={room.room_type_display.toUpperCase()}
                      color={
                        room.room_type_display === "VIP"
                          ? "secondary"
                          : "primary"
                      }
                      sx={{ fontWeight: "bold" }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {room.number_of_bed}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body2"
                      color={
                        room.total_beds_available > 0
                          ? "success.main"
                          : "error.main"
                      }
                    >
                      {room.total_beds_available}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      ؋{room.price_per_night}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{room.floor}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      label={
                        room.is_available
                          ? t("room.available")
                          : t("room.occupied")
                      }
                      color={room.is_available ? "success" : "error"}
                      sx={{ fontWeight: "bold" }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Box display="flex" gap={1}>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setEditRoomData(room);
                          handleEditRoomDialog();
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => {
                          handleDelete(room.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box display="flex" justifyContent="center">
                      {room.total_beds_available > 0 ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpenDialog(room)}
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          {t("room.assignPatient")}
                        </Button>
                      ) : (
                        <Tooltip title={t("room.noBed")} arrow>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleOpenDialog(room)}
                            startIcon={<InfoIcon sx={{ margin: "0px 10px" }} />}
                            sx={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                          >
                            {t("room.fullCapacity")}
                          </Button>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={openDialog} onClose={handleEditRoomDialog}>
          <DialogTitle>Edit Room</DialogTitle>
          <DialogContent>
            <EditRoom roomData={editRoomData} onClose={handleEditRoomDialog} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditRoomDialog}>Close</Button>
          </DialogActions>
        </Dialog>
        <ConfirmDialog />
        <AssignPatientDialog
          open={open}
          selectedRoom={selectedRoom}
          patientId={patientId}
          setPatientId={setPatientId}
          handleCloseDialog={handleCloseDialog}
          handleAssign={handleAssign}
          token={token}
        />
      </>
    );
  }
};

export default RoomList;
