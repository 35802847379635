import React, { useEffect } from "react";

const LaboratoryResultPaper = ({ labTest, tests }) => {
  useEffect(() => {
    const printStyles = `
      @media print {
        @page {
          size: A4;
          margin: 100mm; /* Adjust as needed for header/footer */
        }
        html, body {
          font-family: 'Roboto', sans-serif;
          margin: 0;
          padding: 0;
          color: #000;
        }
        .print-container {
          padding-top: 120px; /* space for logo/header */
          padding-bottom: 50px; /* space for footer */
          background-color: white;
        }
        table {
          width: 100%;
        }
      }
    `;
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = printStyles;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  if (!labTest || !tests || tests.length === 0) {
    return null;
  }

  // Separate CBC tests from others
  const cbcTests = tests.filter(
    (test) =>
      (test.test_type_display && test.test_type_display.name === "CBC") ||
      (test.parent_test && test.parent_test.name === "CBC")
  );
  const otherTests = tests.filter((test) => !cbcTests.includes(test));

  // Group CBC tests by category
  const cbcGroupedTests = cbcTests.reduce((acc, test) => {
    const category = test.test_type_display.category.name;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(test);
    return acc;
  }, {});

  const renderCBCTests = () => {
    if (cbcTests.length === 0) {
      return null;
    }

    return (
      <div
        className="print-container"
        style={{
          fontFamily: "'Roboto', sans-serif",
          backgroundColor: "#fff",
          color: "#333",
          lineHeight: "1.5",
          fontSize: "10px",
          marginTop: "150px",
          marginBottom: "150px",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        {/* Patient Information */}
        <div style={{ marginBottom: "15px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p>
                <strong>Patient ID:</strong> {labTest.patient_display.id}
              </p>
              <p>
                <strong>Patient Name:</strong> {labTest.patient_display.name}
              </p>
              <p>
                <strong>Age/Sex:</strong> {labTest.patient_display.age}{" "}
                {labTest.patient_display.gender}
              </p>
            </div>
            <div>
              <p>
                <strong>Referred By:</strong> {labTest.doctor_display.username}
              </p>
              <p>
                <strong>Date:</strong>{" "}
                {new Date(labTest.order_date).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>

        {/* Laboratory Results */}
        <h2
          style={{
            textAlign: "center",
            fontSize: "12px",
            fontWeight: "bold",
            marginBottom: "15px",
          }}
        >
          Laboratory Test Results
        </h2>

        <table
          style={{
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <thead>
            <tr
              style={{
                backgroundColor: "#f5f5f5",
                textAlign: "left",
                fontSize: "10px",
                fontWeight: "bold",
              }}
            >
              <th style={{ padding: "6px", border: "1px solid #ddd" }}>
                Test Name
              </th>
              <th style={{ padding: "6px", border: "1px solid #ddd" }}>
                Result
              </th>
              <th style={{ padding: "6px", border: "1px solid #ddd" }}>Unit</th>
              <th style={{ padding: "6px", border: "1px solid #ddd" }}>
                Normal Range
              </th>
              <th style={{ padding: "6px", border: "1px solid #ddd" }}>
                Range Status
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(cbcGroupedTests).map((category, index) => (
              <React.Fragment key={index}>
                {/* Category Row */}
                <tr>
                  <td
                    colSpan={12}
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    {category.toUpperCase()}
                  </td>
                </tr>

                {/* Group by parent test (CBC or parent name) */}
                {Object.entries(
                  cbcGroupedTests[category].reduce((parents, test) => {
                    const parentTest = test.parent_test
                      ? test.parent_test.name
                      : test.test_type_display.name;
                    if (!parents[parentTest]) {
                      parents[parentTest] = [];
                    }
                    if (
                      test.parent_test ||
                      test.test_type_display.name === "CBC"
                    ) {
                      parents[parentTest].push(test);
                    }
                    return parents;
                  }, {})
                ).map(([parentTest, childTests], parentIndex) => (
                  <React.Fragment key={parentIndex}>
                    {/* Parent Test */}
                    <tr>
                      <td
                        colSpan={4}
                        style={{
                          fontWeight: "bold",
                          fontSize: "10px",
                          padding: "6px 8px",
                          textAlign: "left",
                        }}
                      >
                        {parentTest}
                      </td>
                    </tr>

                    {/* Child Tests */}
                    {childTests.map((childTest, childIndex) => (
                      <tr key={childIndex}>
                        <td style={{ padding: "6px 24px", fontSize: "10px" }}>
                          {childTest.test_type_display.name}
                        </td>
                        <td style={{ padding: "6px", fontSize: "10px" }}>
                          {childTest.test_type_display.result_value}
                        </td>
                        <td style={{ padding: "6px", fontSize: "10px" }}>
                          {childTest.test_type_display.units}
                        </td>
                        <td style={{ padding: "6px", fontSize: "10px" }}>
                          {childTest.test_type_display.min_value !== null &&
                          childTest.test_type_display.max_value !== null
                            ? `${childTest.test_type_display.min_value} - ${childTest.test_type_display.max_value}`
                            : ""}
                        </td>
                        <td style={{ padding: "6px", fontSize: "10px" }}>
                          {
                            childTest.test_type_display
                              .is_within_normal_range_display
                          }
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}

            {/* Standalone CBC Tests (if any) */}
            {cbcTests
              .filter(
                (test) =>
                  !test.parent_test &&
                  test.test_type_display.name === "CBC" &&
                  test.test_type_display.result_value
              )
              .map((test, index) => (
                <tr key={index}>
                  <td style={{ padding: "6px 24px", fontSize: "10px" }}>
                    {test.test_type_display.name}
                  </td>
                  <td style={{ padding: "6px", fontSize: "10px" }}>
                    {test.test_type_display.result_value}
                  </td>
                  <td style={{ padding: "6px", fontSize: "10px" }}>
                    {test.test_type_display.units}
                  </td>
                  <td style={{ padding: "6px", fontSize: "10px" }}>
                    {test.test_type_display.min_value !== null &&
                    test.test_type_display.max_value !== null
                      ? `${test.test_type_display.min_value} - ${test.test_type_display.max_value}`
                      : ""}
                  </td>
                  <td style={{ padding: "6px", fontSize: "10px" }}>
                    {test.test_type_display.is_within_normal_range_display}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };

  // Group other tests by category first
  const otherTestsByCategory = otherTests.reduce((acc, test) => {
    const categoryName =
      (test.test_type_display.category &&
        test.test_type_display.category.name) ||
      "Uncategorized";
    if (!acc[categoryName]) acc[categoryName] = [];
    acc[categoryName].push(test);
    return acc;
  }, {});

  // Now group within each category by parent/child relationship
  const otherGroupedTests = Object.fromEntries(
    Object.entries(otherTestsByCategory).map(([catName, testsInCat]) => {
      const grouped = testsInCat.reduce((acc, test) => {
        const groupName = test.parent_test
          ? test.parent_test.name
          : test.test_type_display.name;

        if (!acc[groupName]) {
          acc[groupName] = {
            parent: null,
            children: [],
          };
        }

        if (test.child_tests && test.child_tests.length > 0) {
          acc[groupName].parent = test;
        } else if (test.parent_test) {
          acc[groupName].children.push(test);
        } else {
          // standalone test
          acc[groupName].parent = test;
        }

        return acc;
      }, {});
      return [catName, grouped];
    })
  );

  const renderOtherTests = () => {
    const categoryNames = Object.keys(otherGroupedTests);
    if (categoryNames.length === 0) return null;

    return (
      <div
        className="print-container"
        style={{
          fontFamily: "'Roboto', sans-serif",
          backgroundColor: "#fff",
          color: "#333",
          lineHeight: "1.5",
          fontSize: "10px",
          marginTop: "150px",
          marginBottom: "150px",
          marginLeft: "20px",
          marginRight: "20px",
          pageBreakBefore: "always",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            fontSize: "12px",
            fontWeight: "bold",
            marginBottom: "15px",
          }}
        >
          Laboratory Test Results - Other Tests
        </h2>

        <table
          style={{
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <thead>
            <tr
              style={{
                backgroundColor: "#f5f5f5",
                textAlign: "left",
                fontSize: "10px",
                fontWeight: "bold",
              }}
            >
              <th style={{ padding: "6px", border: "1px solid #ddd" }}>
                Test Name
              </th>
              <th style={{ padding: "6px", border: "1px solid #ddd" }}>
                Result
              </th>
              <th style={{ padding: "6px", border: "1px solid #ddd" }}>Unit</th>
              <th style={{ padding: "6px", border: "1px solid #ddd" }}>
                Normal Range
              </th>
              <th style={{ padding: "6px", border: "1px solid #ddd" }}>
                Range Status
              </th>
            </tr>
          </thead>
          <tbody>
            {categoryNames.map((catName, catIndex) => {
              const groups = otherGroupedTests[catName];
              const groupKeys = Object.keys(groups);

              return (
                <React.Fragment key={catIndex}>
                  {/* Category Row */}
                  <tr>
                    <td
                      colSpan={12}
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      {catName.toUpperCase()}
                    </td>
                  </tr>

                  {groupKeys.map((groupName, index) => {
                    const { parent, children } = groups[groupName];
                    const displayedName = parent
                      ? parent.parent_test
                        ? parent.parent_test.name
                        : parent.test_type_display.name
                      : groupName;

                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td
                            colSpan={5}
                            style={{
                              fontWeight: "bold",
                              fontSize: "10px",
                              padding: "6px 8px",
                              textAlign: "left",
                              backgroundColor: "#eee",
                            }}
                          >
                            {displayedName}
                          </td>
                        </tr>

                        {children && children.length > 0
                          ? children.map((childTest, cIndex) => (
                              <tr key={cIndex}>
                                <td
                                  style={{
                                    padding: "6px 24px",
                                    fontSize: "10px",
                                  }}
                                >
                                  {childTest.test_type_display.name}
                                </td>
                                <td
                                  style={{ padding: "6px", fontSize: "10px" }}
                                >
                                  {childTest.test_type_display.result_value}
                                </td>
                                <td
                                  style={{ padding: "6px", fontSize: "10px" }}
                                >
                                  {childTest.test_type_display.units}
                                </td>
                                <td
                                  style={{ padding: "6px", fontSize: "10px" }}
                                >
                                  {childTest.test_type_display.min_value !==
                                    null &&
                                  childTest.test_type_display.max_value !== null
                                    ? `${childTest.test_type_display.min_value} - ${childTest.test_type_display.max_value}`
                                    : ""}
                                </td>
                                <td
                                  style={{ padding: "6px", fontSize: "10px" }}
                                >
                                  {
                                    childTest.test_type_display
                                      .is_within_normal_range_display
                                  }
                                </td>
                              </tr>
                            ))
                          : parent &&
                            parent.child_tests &&
                            parent.child_tests.length > 0
                          ? parent.child_tests.map((childTest, cIndex) => (
                              <tr key={cIndex}>
                                <td
                                  style={{
                                    padding: "6px 24px",
                                    fontSize: "10px",
                                  }}
                                >
                                  {childTest.name}
                                </td>
                                <td
                                  style={{ padding: "6px", fontSize: "10px" }}
                                >
                                  {childTest.result_value}
                                </td>
                                <td
                                  style={{ padding: "6px", fontSize: "10px" }}
                                >
                                  {childTest.units}
                                </td>
                                <td
                                  style={{ padding: "6px", fontSize: "10px" }}
                                >
                                  {childTest.min_value !== null &&
                                  childTest.max_value !== null
                                    ? `${childTest.min_value} - ${childTest.max_value}`
                                    : ""}
                                </td>
                                <td
                                  style={{ padding: "6px", fontSize: "10px" }}
                                >
                                  {childTest.is_within_normal_range_display}
                                </td>
                              </tr>
                            ))
                          : parent && (
                              <tr>
                                <td
                                  style={{
                                    padding: "6px 24px",
                                    fontSize: "10px",
                                  }}
                                >
                                  {parent.test_type_display.name}
                                </td>
                                <td
                                  style={{ padding: "6px", fontSize: "10px" }}
                                >
                                  {parent.test_type_display.result_value}
                                </td>
                                <td
                                  style={{ padding: "6px", fontSize: "10px" }}
                                >
                                  {parent.test_type_display.units}
                                </td>
                                <td
                                  style={{ padding: "6px", fontSize: "10px" }}
                                >
                                  {parent.test_type_display.min_value !==
                                    null &&
                                  parent.test_type_display.max_value !== null
                                    ? `${parent.test_type_display.min_value} - ${parent.test_type_display.max_value}`
                                    : ""}
                                </td>
                                <td
                                  style={{ padding: "6px", fontSize: "10px" }}
                                >
                                  {
                                    parent.test_type_display
                                      .is_within_normal_range_display
                                  }
                                </td>
                              </tr>
                            )}
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      {renderCBCTests()}
      {renderOtherTests()}
    </>
  );
};

export default LaboratoryResultPaper;
