import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@mui/material";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";

const PatientList = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [status, setStatus] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [startDateTime, setStartDateTime] = useState(""); // DateTime for start
  const [endDateTime, setEndDateTime] = useState(""); // DateTime for end
  const [totalFees, setTotalFees] = useState(0);

  const auth = useAuth();
  const token = auth?.user?.token;

  // Fetch data
  const {
    data: appointmentsData,
    refetch,
    isLoading: isAppointmentsLoading,
    isError: isAppointmentsError,
  } = useFetchObjects(
    ["appointments"],
    `appointments/?status=${status}&doctor=${
      selectedUser?.id || ""
    }&start_datetime=${startDateTime}&end_datetime=${endDateTime}`,
    token,
  );

  const {
    data: usersData,
    isLoading: isUsersLoading,
    isError: isUsersError,
  } = useFetchObjects(["users"], `users/`, token);

  useEffect(() => {
    refetch();
  }, [status, selectedUser, startDateTime, endDateTime, refetch]);

  useEffect(() => {
    // Calculate total fees when appointments data changes
    if (appointmentsData?.results) {
      const total = appointmentsData.results.reduce(
        (sum, appointment) => sum + appointment.fees,
        0,
      );
      setTotalFees(total);
    }
  }, [appointmentsData]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setStatus(
      newValue === 1 ? 1 : newValue === 2 ? 2 : newValue === 3 ? 3 : "",
    );
  };

  const getChipColor = (status) => {
    return status === 1
      ? "orange"
      : status === 2
        ? "green"
        : status === 3
          ? "blue"
          : "grey";
  };

  return (
    <Box sx={{ px: 3, py: 2, bgcolor: "#f5f7fa", minHeight: "100vh" }}>
      <Typography variant="h5" gutterBottom>
        {t("finance.filterAppointments")}
      </Typography>

      {/* Filters Section */}
      <Card
        sx={{
          mb: 3,
          borderRadius: 2,
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          bgcolor: "white",
        }}
      >
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            {/* Tabs for Status */}
            <Grid item xs={12}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label={t("finance.all")} />
                <Tab label={t("finance.pending")} />
                <Tab label={t("finance.completed")} />
                <Tab label={t("finance.canceled")} />
              </Tabs>
            </Grid>

            {/* Filters */}
            <Grid item xs={12} md={4}>
              <Autocomplete
                size="small"
                options={usersData || []}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.last_name}`
                }
                value={selectedUser}
                onChange={(e, newValue) => setSelectedUser(newValue)}
                loading={isUsersLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("finance.selectDoctor")}
                    error={isUsersError}
                    helperText={
                      isUsersError ? t("finance.errorFetchingUsers") : ""
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                label={t("finance.startDate")}
                type="datetime-local"
                value={startDateTime}
                onChange={(e) => setStartDateTime(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                label={t("finance.endDate")}
                type="datetime-local"
                value={endDateTime}
                onChange={(e) => setEndDateTime(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={refetch}
                sx={{ height: "100%" }}
              >
                {t("finance.applyFilter")}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Table Section */}
      {isAppointmentsLoading ? (
        <Box sx={{ textAlign: "center", mt: 3 }}>{t("finance.loading")}</Box>
      ) : isAppointmentsError ? (
        <Box sx={{ textAlign: "center", color: "red", mt: 3 }}>
          {t("finance.errorFetchingData")}
        </Box>
      ) : (
        <>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: 2,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              mt: 3,
            }}
          >
            <Table>
              <TableHead sx={{ bgcolor: "#f8f9fa" }}>
                <TableRow>
                  <TableCell align="center">{t("finance.time")}</TableCell>
                  <TableCell align="center">{t("finance.date")}</TableCell>
                  <TableCell align="center">
                    {t("finance.patientName")}
                  </TableCell>
                  <TableCell align="center">{t("finance.doctor")}</TableCell>
                  <TableCell align="center">{t("finance.fees")}</TableCell>
                  <TableCell align="center">{t("finance.due")}</TableCell>
                  <TableCell align="center">{t("finance.status")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appointmentsData?.results?.map((appointment, i) => (
                  <TableRow key={i}>
                    <TableCell align="center">
                      {appointment.timeOfVisit}
                    </TableCell>
                    <TableCell align="center">
                      {appointment.dateOfVisit}
                    </TableCell>
                    <TableCell align="center">
                      {appointment.patient_name_display}{" "}
                      {appointment.patient_lastname_display}
                    </TableCell>
                    <TableCell align="center">
                      {appointment.doctor_name_display}{" "}
                      {appointment.doctor_lastname_display}
                    </TableCell>
                    <TableCell align="center">{appointment.fees}</TableCell>
                    <TableCell align="center">{appointment.due}</TableCell>
                    <TableCell align="center">
                      <Chip
                        label={appointment.status_display}
                        sx={{
                          bgcolor: getChipColor(appointment.status),
                          color: "white",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Total Fees Section */}
          <Card
            sx={{
              mt: 3,
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              bgcolor: "#ffffff",
              borderRadius: 2,
            }}
          >
            <Typography variant="h6">{t("finance.totalFees")}</Typography>
            <Typography variant="h6" color="primary">
              {totalFees} ؋
            </Typography>
          </Card>
        </>
      )}
    </Box>
  );
};

export default PatientList;
