import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import useFetchObjects from "../../../../api/useFetchObjects";

const AddSampleDialog = ({ open, onClose, handleAdd, auth }) => {
  const [formData, setFormData] = useState({
    patient_test: "",
    sample_type: "",
    sample_number: "",
    collection_date: new Date().toISOString().split("T")[0], // Default to today's date
  });
  const [formErrors, setFormErrors] = useState({});
  const technicianId = auth?.user?.user?.id;

  const {
    data: patientTests,
    isLoading,
    isError,
  } = useFetchObjects(
    ["laboratoryPatientTests"],
    "laboratory-patient-tests/",
    auth?.user?.token,
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};
    if (!formData.patient_test)
      errors.patient_test = "Patient test is required.";
    if (!formData.sample_type) errors.sample_type = "Sample type is required.";
    if (!formData.sample_number)
      errors.sample_number = "Sample number is required.";
    if (!formData.collection_date) {
      errors.collection_date = "Collection date is required.";
    } else if (new Date(formData.collection_date) > new Date()) {
      errors.collection_date = "Collection date cannot be in the future.";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const sampleData = {
      ...formData,
      technician: technicianId,
    };

    handleAdd(sampleData);
    onClose();
    setFormData({
      patient_test: "",
      sample_type: "",
      sample_number: "",
      collection_date: new Date().toISOString().split("T")[0],
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add New Sample</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        ) : isError ? (
          <Box textAlign="center" color="red" mt={2}>
            <Typography variant="body1">Error loading patient tests</Typography>
          </Box>
        ) : (
          <>
            <TextField
              select
              label="Patient Test"
              name="patient_test"
              value={formData.patient_test}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.patient_test}
              helperText={formErrors.patient_test}
            >
              {patientTests?.map((test) => (
                <MenuItem key={test.id} value={test.id}>
                  {`${test.patient_display} - ${test.test_type_display.name}`}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              label="Sample Type"
              name="sample_type"
              value={formData.sample_type}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.sample_type}
              helperText={formErrors.sample_type}
            >
              <MenuItem value={1}>Blood</MenuItem>
              <MenuItem value={2}>Urine</MenuItem>
              <MenuItem value={3}>Tissue</MenuItem>
              <MenuItem value={4}>Stool</MenuItem>
              <MenuItem value={5}>Other</MenuItem>
            </TextField>

            <TextField
              label="Sample Number"
              name="sample_number"
              value={formData.sample_number}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.sample_number}
              helperText={formErrors.sample_number}
            />

            <TextField
              label="Collection Date"
              type="date"
              name="collection_date"
              value={formData.collection_date}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              InputLabelProps={{ shrink: true }}
              error={!!formErrors.collection_date}
              helperText={formErrors.collection_date}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Add Sample
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSampleDialog;
