import React, { useRef, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Stack,
  IconButton,
  Tooltip,
  Box,
  Chip,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PrintIcon from "@mui/icons-material/Print";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import useDelete from "../../../../api/useDelete";
import MedicineOrderEdit from "./PosEdit";
import { useTranslation } from "react-i18next";
import MedicineOrderPrint from "./MedicineOrderPrint";

const MedicineOrderList = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const pharmacyType = auth?.user?.user.pharmacy_type || "";

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [printData, setPrintData] = useState(null);
  const printRef = useRef();

  const { handleDelete: handleDeleteOrder, ConfirmDialog } = useDelete(
    "medicine-orders",
    token
  );
  const {
    handleDelete: handleDeleteOrderItem,
    ConfirmDialog: ConfirmDialogItem,
  } = useDelete("medicine-order-items", token);

  const { data, isLoading, isError, isSuccess, error } = useFetchObjects(
    ["medicine-orders"],
    `medicine-orders?items__stock_medicine__pharmacy=${pharmacyType}`,
    token
  );

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  const handlePrintClick = (order) => {
    setPrintData(order);
    setTimeout(() => {
      if (printRef.current) {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(printRef.current.innerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }
    }, 500);
  };

  const getPaymentStatusLabel = (status) => {
    switch (status) {
      case 1:
        return t("pharmacy.pending");
      case 2:
        return t("pharmacy.paid");
      case 3:
        return t("pharmacy.canceled");
      default:
        return t("pharmacy.unknown");
    }
  };

  const getPaymentStatusColor = (status) => {
    switch (status) {
      case 1:
        return "warning";
      case 2:
        return "success";
      case 3:
        return "error";
      default:
        return "default";
    }
  };

  if (isLoading) {
    return <Typography>{t("pharmacy.loading")}</Typography>;
  }

  if (isError) {
    return <Typography>{error.message}</Typography>;
  }

  if (isSuccess) {
    return (
      <Grid container spacing={3} mt={3}>
        {data.map((order, index) => {
          const isPaid = order.payment_status === 2;
          const isLoan = order.is_loan;

          return (
            <Grid item xs={12} md={6} key={index}>
              <Paper
                elevation={isLoan ? 4 : isPaid ? 2 : 4}
                sx={{
                  mb: 3,
                  padding: "24px",
                  borderRadius: "12px",
                  width: "100%",
                  boxShadow: isLoan
                    ? "0px 8px 24px rgba(0, 123, 255, 0.3)"
                    : isPaid
                    ? "0px 4px 12px rgba(0, 0, 0, 0.1)"
                    : "0px 8px 24px rgba(255, 0, 0, 0.3)",
                  backgroundColor: isLoan
                    ? "#e3f2fd"
                    : isPaid
                    ? "#f9f9f9"
                    : "#ffebee",
                  borderLeft: isLoan
                    ? "4px solid #1976d2"
                    : isPaid
                    ? "none"
                    : "4px solid #d32f2f",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="primary"
                    sx={{ fontSize: isSmallScreen ? "1rem" : "1.25rem" }}
                  >
                    {t("pharmacy.order")} #{order.id} - {order.date}
                  </Typography>
                  <Box>
                    <Chip
                      label={getPaymentStatusLabel(order.payment_status)}
                      color={getPaymentStatusColor(order.payment_status)}
                      sx={{
                        fontWeight: "bold",
                        fontSize: isSmallScreen ? "0.8rem" : "0.9rem",
                      }}
                    />
                    <IconButton
                      color="primary"
                      onClick={() => handlePrintClick(order)}
                    >
                      <PrintIcon />
                    </IconButton>
                  </Box>
                </Box>

                {isLoan && (
                  <Box sx={{ mt: 2, p: 2, backgroundColor: "#bbdefb" }}>
                    <Typography variant="body1">
                      <strong>{t("pharmacy.name")}: </strong>
                      {order.name}
                    </Typography>
                    <Typography variant="body1">
                      <strong>{t("pharmacy.fatherName")}: </strong>
                      {order.father_name}
                    </Typography>
                    <Typography variant="body1">
                      <strong>{t("pharmacy.phoneNumber")}: </strong>
                      {order.phone_number}
                    </Typography>
                  </Box>
                )}

                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Typography
                    fontSize={isSmallScreen ? "0.85rem" : "1rem"}
                    color={
                      isLoan
                        ? "info.main"
                        : !isPaid
                        ? "error.main"
                        : "text.primary"
                    }
                  >
                    <strong>{t("pharmacy.total_price")}:</strong>{" "}
                    {order.total_price} ؋
                  </Typography>
                </Stack>

                {!isPaid && (
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                    sx={{ mt: 2 }}
                  >
                    <Tooltip title={t("pharmacy.deleteOrder")}>
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteOrder(order.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}

                <Divider sx={{ mt: 2, mb: 2 }} />

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      fontWeight="bold"
                      color={!isPaid ? "error.main" : "text.primary"}
                    >
                      {t("pharmacy.medicineOrder")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {order.items && order.items.length > 0 ? (
                      <Box sx={{ overflowX: "auto" }}>
                        <Table sx={{ minWidth: 650 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">#</TableCell>
                              <TableCell align="center">
                                {t("pharmacy.category")}
                              </TableCell>
                              <TableCell align="center">
                                {t("pharmacy.medicine")}
                              </TableCell>
                              <TableCell align="center">
                                {t("pharmacy.quantity")}
                              </TableCell>
                              <TableCell align="center">
                                {t("pharmacy.pricePerQuantity")}
                              </TableCell>
                              <TableCell align="center">
                                {t("pharmacy.subtotal")}
                              </TableCell>
                              <TableCell align="center">
                                {t("pharmacy.action")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {order.items.map((item, index) => (
                              <TableRow
                                key={item.id}
                                sx={{
                                  "&:hover": { backgroundColor: "#f0f4f8" },
                                }}
                              >
                                <TableCell align="center">
                                  <Typography fontWeight="bold">
                                    {index + 1}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography fontWeight="bold">
                                    {item.stock_medicine_category_display}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography fontWeight="bold">
                                    {item.stock_medicine_display}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography>{item.quantity}</Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography>
                                    {item.sell_price_display}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography>
                                    {item.sell_price_display * item.quantity}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  {!isPaid && (
                                    <>
                                      <Tooltip title={t("pharmacy.editItem")}>
                                        <IconButton
                                          color="primary"
                                          onClick={() => handleOpen(item)}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title={t("pharmacy.deleteItem")}>
                                        <IconButton
                                          color="error"
                                          onClick={() =>
                                            handleDeleteOrderItem(item.id)
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    ) : (
                      <Typography>{t("pharmacy.noMedicineOrder")}</Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Paper>
            </Grid>
          );
        })}

        <ConfirmDialog />
        <ConfirmDialogItem />

        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogTitle>
            <Typography fontWeight="bold" color="primary">
              {t("pharmacy.editOrderItem")}
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ padding: "20px" }}>
            {selectedItem && (
              <MedicineOrderEdit
                medicineOrderItem={selectedItem}
                onClose={handleClose}
                token={token}
              />
            )}
          </DialogContent>
        </Dialog>

        {printData && (
          <div ref={printRef} style={{ display: "none" }}>
            <MedicineOrderPrint data={printData} />
          </div>
        )}
      </Grid>
    );
  }
};

export default MedicineOrderList;
