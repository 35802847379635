import LaboratoryNav from "../../pages/Dashboard/Laboratory/LaboratoryNav";
import LaboratoryIndex from "../../pages/Dashboard/Laboratory/LaboratoryIndex";
import LaboratoryCategory from "../../pages/Dashboard/Laboratory/LaboratoryCategory/LaboratoryCategory";
import LaboratoryTypes from "../../pages/Dashboard/Laboratory/LaboratoryType/LaboratoryTypes";
import TestSample from "../../pages/Dashboard/Laboratory/TestSample/TestSample";
import PatientTest from "../../pages/Dashboard/Laboratory/PatientTest/PatientTest";
import PatientTestResults from "../../pages/Dashboard/Laboratory/PatientTest/PatientTestResults";
import SurgeryPatientTest from "../../pages/Dashboard/Laboratory/SurgeryPatientTest/SurgeryPatientTest";
import SurgeryPatientTestResults from "../../pages/Dashboard/Laboratory/SurgeryPatientTest/SurgeryPatientTestResults";

const laboratoryRoutes = {
  path: "/laboratory",
  element: <LaboratoryNav />,
  children: [
    { path: "/laboratory", element: <LaboratoryIndex /> },
    { path: "/laboratory/test-category", element: <LaboratoryCategory /> },
    { path: "/laboratory/test-types", element: <LaboratoryTypes /> },
    { path: "/laboratory/patient-test", element: <PatientTest /> },
    {
      path: "/laboratory/surgery-patient-test",
      element: <SurgeryPatientTest />,
    },
    { path: "/laboratory/test-results/:id", element: <PatientTestResults /> },
    {
      path: "/laboratory/surgery-test-results/:id",
      element: <SurgeryPatientTestResults />,
    },
    { path: "/laboratory/test-sample", element: <TestSample /> },
  ],
};

export default laboratoryRoutes;
