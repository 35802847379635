import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
  Alert,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useFetchObjects from "../../../../api/useFetchObjects";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import "../Appointments/DatePickerStyles.css";

const ReportTable = ({ title, rows }) => (
  <Paper
    elevation={3}
    sx={{
      p: 3,
      mb: 3,
      borderRadius: 2,
      borderLeft: "6px solid #007174",
      bgcolor: "#f7f7f7",
      width: "100%",
    }}
  >
    <Typography
      variant="h6"
      sx={{ fontWeight: "bold", mb: 2, color: "#007174" }}
    >
      {title}
    </Typography>
    <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#007174" }}>
            <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
              Description
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
              Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:nth-of-type(odd)": { bgcolor: "#e9e9e9" } }}
            >
              <TableCell>
                {row.label.replace(/_/g, " ").toUpperCase()}
              </TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>
);

const Report = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fetchParams, setFetchParams] = useState(
    "finance-report/overall_finance_report/",
  );

  const reportRef = useRef();

  const handleFilter = () => {
    const params = new URLSearchParams();
    if (startDate)
      params.append("start_date", startDate.toISOString().split("T")[0]);
    if (endDate) params.append("end_date", endDate.toISOString().split("T")[0]);

    setFetchParams(
      `finance-report/overall_finance_report/?${params.toString()}`,
    );
  };

  const { data, isLoading, isError, error } = useFetchObjects(
    ["finance-report", fetchParams],
    fetchParams,
  );

  const sections = [
    { title: "Appointments", data: data?.appointment || {} },
    { title: "Lab", data: data?.lab || {} },
    { title: "Radiology", data: data?.radiology || {} },
    { title: "Surgery", data: data?.surgery || {} },
    { title: "Payroll and Expenses", data: data?.payroll_and_expenses || {} },
    { title: "Room", data: data?.room || {} },
    { title: "Medicine Sales", data: data?.medicine_sales || {} },
    { title: "Prescription Sales", data: data?.prescription || {} },
    { title: "Echo", data: data?.echo || {} },
    { title: "Purchase", data: data?.purchase || {} },
    { title: "Summary", data: data?.summary || {} },
  ];

  const downloadPDF = () => {
    const doc = new jsPDF("p", "pt", "a4");
    doc.setFontSize(12);
    doc.text("Financial Report", 40, 30);

    html2canvas(reportRef.current, {
      scale: 2,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 595.28;
      const pageHeight = 841.89;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("Financial_Report.pdf");
    });
  };

  return (
    <Box sx={{ width: "100%", padding: "20px", bgcolor: "#f4f4f4" }}>
      {/* Header and Filter Controls */}
      <Box mb={3}>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
          Financial Report
        </Typography>

        {/* Date Range Filter and Download PDF Icon */}
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={6} md={3}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy-MM-dd"
              placeholderText="Start Date"
              className="date-picker-input"
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="yyyy-MM-dd"
              placeholderText="End Date"
              className="date-picker-input"
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleFilter}
              sx={{
                height: "100%",
                backgroundColor: "#007174",
                ":hover": { backgroundColor: "#005f5f" },
              }}
            >
              Apply Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Tooltip title="Download PDF">
              <IconButton
                onClick={downloadPDF}
                sx={{
                  backgroundColor: "#007174",
                  color: "#fff",
                  ":hover": { backgroundColor: "#005f5f" },
                }}
              >
                <PictureAsPdfIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      {/* Report Data */}
      {isLoading ? (
        <CircularProgress />
      ) : isError ? (
        <Alert severity="error">{error.message}</Alert>
      ) : (
        <Box ref={reportRef}>
          <Grid container spacing={3} justifyContent="center">
            {sections.map((section, index) => (
              <Grid item xs={12} md={6} key={index}>
                <ReportTable
                  title={section.title}
                  rows={Object.entries(section.data).map(([label, value]) => ({
                    label,
                    value: value ?? "N/A",
                  }))}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default Report;
