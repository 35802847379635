import {
  Box,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider,
  Tabs,
  Tab,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../../context";
import useFetchObjects from "../../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";

const HrSummery = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const [value, setValue] = useState(0);
  const token = auth?.user?.token;
  const [position, setPosition] = useState("");
  const {
    data,
    isLoading,
    refetch: refetchPosition,
  } = useFetchObjects(["users"], `users/?position=${position}`, token);

  useEffect(() => {
    refetchPosition();
  }, [position, refetchPosition]);

  const { data: positionData } = useFetchObjects(
    ["position"],
    "position",
    token,
  );

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid item xs={12}>
      <Paper sx={{ boxShadow: "0px 0px 0px" }}>
        <Grid container padding={"10px"}>
          <Grid item xs={12}>
            <Typography variant="h6">{t("hr.employeeList")}</Typography>

            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="Employee filter tabs"
            >
              <Tab
                sx={{ textTransform: "capitalize" }}
                onClick={() => setPosition("")}
                label={t("hr.allEmployees")}
              />
              {positionData?.map((data, i) => (
                <Tab
                  key={i}
                  sx={{ textTransform: "capitalize" }}
                  label={data.name}
                  onClick={() => {
                    setPosition(data.id);
                  }}
                />
              ))}
            </Tabs>

            <Divider sx={{ mt: 2, width: "100%" }} />
          </Grid>

          {isLoading ? (
            <Box>{t("hr.loading")}</Box>
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableHead style={{ backgroundColor: "#f2f2f2" }}>
                  <TableRow>
                    <TableCell align="center">{t("hr.name")}</TableCell>
                    <TableCell align="center">{t("hr.joiningDate")}</TableCell>
                    <TableCell align="center">{t("hr.department")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.length ? (
                    data?.map((item, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{`${item.first_name} ${item.last_name}`}</TableCell>
                        <TableCell align="center">
                          {item.contract_date}
                        </TableCell>
                        <TableCell align="center">
                          {item.display_department}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        {t("hr.noEmployees")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Grid container justifyContent={"center"} margin={"18px 0px"}>
            <Pagination count={5} page={1} variant="outlined" shape="rounded" />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default HrSummery;
