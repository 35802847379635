import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Switch,
  FormControlLabel,
  Paper,
  Divider,
  InputLabel,
  Alert,
  Autocomplete,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import ListSurgeryXrayReferral from "./ListSurgeryXrayReferral";

const SurgeryXRayReferral = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const { data: xrayTypesData } = useFetchObjects(
    ["xray-types"],
    "xray-types/",
    token
  );
  const { data: surgeries, isLoading: loadingSurgeries } = useFetchObjects(
    ["surgeries"],
    "surgeries",
    token
  );
  const { data: usersData } = useFetchObjects(["users"], "users/", token);

  const { handleAdd, resetForm } = useAdd("surgery-xray-patient", token);

  const xrayTypes = xrayTypesData || [];
  const users = usersData || [];

  const [xrayDetails, setXrayDetails] = useState({
    xrayType: "",
    testDate: new Date().toISOString().split("T")[0],
    notes: "",
    isUrgent: false,
    totalAmount: "",
    patient: null,
    assigned_doctor: null,
  });

  const [errors, setErrors] = useState({});
  const [xrayPercentage, setXrayPercentage] = useState(0);
  const [existingXrayAmount, setExistingXrayAmount] = useState(0);
  const [isOverLimit, setIsOverLimit] = useState(false);
  const [overLimitAmount, setOverLimitAmount] = useState(0);

  useEffect(() => {
    if (resetForm) {
      setXrayDetails({
        xrayType: "",
        testDate: new Date().toISOString().split("T")[0],
        notes: "",
        isUrgent: false,
        totalAmount: "",
        patient: null,
        assigned_doctor: null,
      });
      setErrors({});
      setXrayPercentage(0);
      setExistingXrayAmount(0);
      setIsOverLimit(false);
      setOverLimitAmount(0);
    }
  }, [resetForm]);

  useEffect(() => {
    if (xrayDetails.patient && surgeries) {
      const selectedSurgery = surgeries.find(
        (surgery) => surgery.id === xrayDetails.patient
      );
      if (selectedSurgery) {
        setXrayPercentage(selectedSurgery.xray_percentage || 0);
        setExistingXrayAmount(selectedSurgery.total_xray_amount || 0);
      }
    } else {
      setXrayPercentage(0);
      setExistingXrayAmount(0);
    }
  }, [xrayDetails.patient, surgeries]);

  useEffect(() => {
    const totalCost = parseFloat(xrayDetails.totalAmount || 0);
    const newTotal = existingXrayAmount + totalCost;
    if (newTotal > xrayPercentage) {
      setIsOverLimit(true);
      setOverLimitAmount(newTotal - xrayPercentage);
    } else {
      setIsOverLimit(false);
      setOverLimitAmount(0);
    }
  }, [xrayDetails.totalAmount, existingXrayAmount, xrayPercentage]);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setXrayDetails((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "xrayType") {
      const selectedType = xrayTypes.find(
        (type) => type.id.toString() === value
      );
      setXrayDetails((prev) => ({
        ...prev,
        totalAmount: selectedType ? selectedType.cost.toString() : "",
      }));
    }

    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!xrayDetails.patient) {
      tempErrors.patient = t("doctor.patientRequired");
    }
    if (!xrayDetails.assigned_doctor) {
      tempErrors.assigned_doctor = t("doctor.doctorRequired");
    }
    if (!xrayDetails.xrayType) {
      tempErrors.xrayType = t("doctor.xrayTypeRequired");
    }
    if (!xrayDetails.testDate) {
      tempErrors.testDate = t("doctor.testDateRequired");
    }
    if (!xrayDetails.totalAmount) {
      tempErrors.totalAmount = t("doctor.totalAmountRequired");
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const payload = {
        patient: xrayDetails.patient,
        assigned_doctor: xrayDetails.assigned_doctor.id,
        xray_type: xrayDetails.xrayType,
        date: xrayDetails.testDate,
        is_urgent: xrayDetails.isUrgent,
        total_amount: xrayDetails.totalAmount,
        notes: xrayDetails.notes,
      };
      handleAdd(payload);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        margin: "auto",
        width: "100%",
        borderRadius: "16px",
        backgroundColor: "background.default",
        boxShadow: "0px 6px 18px rgba(0,0,0,0.12)",
      }}
    >
      <Typography
        variant="h4"
        textAlign="center"
        fontWeight="bold"
        sx={{ mb: 3 }}
        color="primary"
      >
        {t("doctor.x-rayReferralForm")}
      </Typography>

      <Divider sx={{ mb: 4 }} />

      <Grid container spacing={3}>
        {/* Patient Selection */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={surgeries || []}
            getOptionLabel={(option) =>
              `${option.patient_display} (ID: ${option.id})`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("doctor.selectSurgery")}
                variant="outlined"
                fullWidth
                error={!!errors.patient}
                helperText={errors.patient}
              />
            )}
            value={
              surgeries?.find(
                (surgery) => surgery.id === xrayDetails.patient
              ) || null
            }
            onChange={(event, newValue) => {
              setXrayDetails((prev) => ({
                ...prev,
                patient: newValue ? newValue.id : null,
              }));
              setErrors((prev) => ({ ...prev, patient: "" }));
            }}
          />
        </Grid>

        {/* Assigned Doctor Selection */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={users}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("doctor.selectDoctor")}
                variant="outlined"
                fullWidth
                error={!!errors.assigned_doctor}
                helperText={errors.assigned_doctor}
              />
            )}
            value={xrayDetails.assigned_doctor}
            onChange={(event, newValue) => {
              setXrayDetails((prev) => ({
                ...prev,
                assigned_doctor: newValue,
              }));
              setErrors((prev) => ({ ...prev, assigned_doctor: "" }));
            }}
          />
        </Grid>

        {/* X-Ray Type Selection */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>{t("doctor.xrayType")}</InputLabel>
            <Select
              name="xrayType"
              value={xrayDetails.xrayType}
              onChange={handleChange}
              displayEmpty
              error={!!errors.xrayType}
            >
              {xrayTypes.map((type) => (
                <MenuItem key={type.id} value={type.id.toString()}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
            {errors.xrayType && (
              <Typography color="error" variant="caption">
                {errors.xrayType}
              </Typography>
            )}
          </FormControl>
        </Grid>

        {/* Test Date */}
        <Grid item xs={12} sm={6}>
          <TextField
            name="testDate"
            label={t("doctor.testDate")}
            type="date"
            fullWidth
            value={xrayDetails.testDate}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            error={!!errors.testDate}
            helperText={errors.testDate}
          />
        </Grid>

        {/* Total Amount */}
        <Grid item xs={12} sm={6}>
          <TextField
            name="totalAmount"
            label={t("doctor.totalAmount")}
            type="number"
            fullWidth
            value={xrayDetails.totalAmount}
            onChange={handleChange}
            error={!!errors.totalAmount}
            helperText={errors.totalAmount}
          />
        </Grid>

        {/* Urgent Switch */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={xrayDetails.isUrgent}
                onChange={handleChange}
                name="isUrgent"
              />
            }
            label={t("doctor.urgent")}
          />
        </Grid>

        {/* Notes */}
        <Grid item xs={12}>
          <TextField
            name="notes"
            label={t("doctor.notes")}
            multiline
            rows={4}
            fullWidth
            value={xrayDetails.notes}
            onChange={handleChange}
          />
        </Grid>

        {/* Display Warning Message if Over Limit */}
        {isOverLimit && (
          <Grid item xs={12}>
            <Alert severity="warning">
              {t("doctor.totalCostExceedsXrayLimit", {
                high_amount:
                  existingXrayAmount + parseFloat(xrayDetails.totalAmount || 0),
                xray_percentage: xrayPercentage,
              })}
            </Alert>
          </Grid>
        )}

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            {t("doctor.submit")}
          </Button>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 4 }} />

      <Box sx={{ mt: 3 }}>
        <ListSurgeryXrayReferral />
      </Box>
    </Paper>
  );
};

export default SurgeryXRayReferral;
