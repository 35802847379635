import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Switch,
  FormControlLabel,
  Paper,
  Divider,
  InputLabel,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import UltrasoundCollectFees from "./UltrasoundCollectFees";

const UltrasoundReferral = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const { data: ultrasoundTypesData } = useFetchObjects(
    ["ultrasound-types"],
    "ultrasound-types/",
    token,
  );
  const { data: patientsData } = useFetchObjects(
    ["patients"],
    "patients/",
    token,
  );
  const { data: usersData } = useFetchObjects(["users"], "users/", token);

  const { handleAdd, resetForm } = useAdd("ultrasounds", token);

  const ultrasoundTypes = ultrasoundTypesData || [];
  const patients = patientsData?.results || [];
  const users = usersData || []; // All users for doctor selection

  // Get current date in YYYY-MM-DD format
  const getCurrentDate = () => new Date().toISOString().split("T")[0];

  const [ultrasoundDetails, setUltrasoundDetails] = useState({
    ultrasoundType: "",
    date: getCurrentDate(), // Automatically set current date
    isUrgent: false,
    totalAmount: "",
    patient: null,
    doctor: null,
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (resetForm) {
      setUltrasoundDetails({
        ultrasoundType: "",
        date: getCurrentDate(), // Reset to current date
        isUrgent: false,
        totalAmount: "",
        patient: null,
        doctor: null,
      });
      setErrors({});
    }
  }, [resetForm]);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setUltrasoundDetails((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "ultrasoundType") {
      const selectedType = ultrasoundTypes.find(
        (type) => type.id.toString() === value,
      );
      setUltrasoundDetails((prev) => ({
        ...prev,
        totalAmount: selectedType ? selectedType.cost.toString() : "",
      }));
    }

    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!ultrasoundDetails.patient) {
      tempErrors.patient = t("doctor.patientRequired");
    }
    if (!ultrasoundDetails.doctor) {
      tempErrors.doctor = t("doctor.doctorRequired");
    }
    if (!ultrasoundDetails.ultrasoundType) {
      tempErrors.ultrasoundType = t("doctor.ultTypeRequired");
    }
    if (!ultrasoundDetails.date) {
      tempErrors.date = t("doctor.testDateRequired");
    }
    if (!ultrasoundDetails.totalAmount) {
      tempErrors.totalAmount = t("doctor.totalAmountRequired");
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const payload = {
        patient: ultrasoundDetails.patient.id,
        doctor: ultrasoundDetails.doctor.id,
        ultrasound_type: ultrasoundDetails.ultrasoundType,
        date: ultrasoundDetails.date,
        is_urgent: ultrasoundDetails.isUrgent,
        total_amount: ultrasoundDetails.totalAmount,
      };
      handleAdd(payload);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        margin: "auto",
        width: "100%",
        borderRadius: "16px",
        backgroundColor: "background.default",
        boxShadow: "0px 6px 18px rgba(0,0,0,0.12)",
      }}
    >
      <Typography
        variant="h4"
        textAlign="center"
        fontWeight="bold"
        sx={{ mb: 3 }}
        color="primary"
      >
        {t("doctor.ultReferralForm")}
      </Typography>

      <Divider sx={{ mb: 4 }} />

      <Grid container spacing={3}>
        {/* Patient Selection */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={patients}
            getOptionLabel={(option) =>
              option
                ? `${option.id} - ${option.firstname} ${option.lastname}`
                : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("doctor.selectPatient")}
                variant="outlined"
                fullWidth
                error={!!errors.patient}
                helperText={errors.patient}
              />
            )}
            value={ultrasoundDetails.patient}
            onChange={(event, newValue) => {
              setUltrasoundDetails((prev) => ({
                ...prev,
                patient: newValue,
              }));
              setErrors((prev) => ({ ...prev, patient: "" }));
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Grid>

        {/* Doctor Selection */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={users}
            getOptionLabel={(option) =>
              option
                ? `${option.id} - ${option.first_name} ${option.last_name}`
                : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("doctor.selectDoctor")}
                variant="outlined"
                fullWidth
                error={!!errors.doctor}
                helperText={errors.doctor}
              />
            )}
            value={ultrasoundDetails.doctor}
            onChange={(event, newValue) => {
              setUltrasoundDetails((prev) => ({
                ...prev,
                doctor: newValue,
              }));
              setErrors((prev) => ({ ...prev, doctor: "" }));
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Grid>

        {/* Ultrasound Type */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>{t("doctor.ultrasoundType")}</InputLabel>
            <Select
              name="ultrasoundType"
              value={ultrasoundDetails.ultrasoundType}
              onChange={handleChange}
              displayEmpty
              error={!!errors.ultrasoundType}
            >
              {ultrasoundTypes.map((type) => (
                <MenuItem key={type.id} value={type.id.toString()}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
            {errors.ultrasoundType && (
              <Typography color="error" variant="caption">
                {errors.ultrasoundType}
              </Typography>
            )}
          </FormControl>
        </Grid>

        {/* Date */}
        <Grid item xs={12} sm={6}>
          <TextField
            name="date"
            label={t("doctor.testDate")}
            type="date"
            fullWidth
            value={ultrasoundDetails.date}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            error={!!errors.date}
            helperText={errors.date}
          />
        </Grid>

        {/* Total Amount */}
        <Grid item xs={12} sm={6}>
          <TextField
            name="totalAmount"
            label={t("doctor.totalAmount")}
            type="number"
            fullWidth
            value={ultrasoundDetails.totalAmount}
            onChange={handleChange}
            error={!!errors.totalAmount}
            helperText={errors.totalAmount}
          />
        </Grid>

        {/* Urgent Switch */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={ultrasoundDetails.isUrgent}
                onChange={handleChange}
                name="isUrgent"
              />
            }
            label={t("doctor.urgent")}
          />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            {t("doctor.submit")}
          </Button>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 4 }} />

      <Box sx={{ mt: 3 }}>
        <UltrasoundCollectFees />
      </Box>
    </Paper>
  );
};

export default UltrasoundReferral;
