import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormLabel,
  Box,
} from "@mui/material";
import { useAuth } from "../../../../context";
import useAdd from "../../../../api/useAdd";
import { useTranslation } from "react-i18next";

const RoomForm = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const [roomNumber, setRoomNumber] = useState("");
  const [roomNumberError, setRoomNumberError] = useState("");

  const [floor, setFloor] = useState("");
  const [floorError, setFloorError] = useState("");

  const [pricePerNight, setPricePerNight] = useState("");
  const [pricePerNightError, setPricePerNightError] = useState("");

  const [numberOfBed, setNumberOfBed] = useState("");
  const [numberOfBedError, setNumberOfBedError] = useState("");

  const [roomType, setRoomType] = useState("");
  const [roomTypeError, setRoomTypeError] = useState("");

  const { handleAdd, loading } = useAdd(
    "rooms",
    token,
    t("room.successfullyCreated"),
    t("room.failedOperation"),
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;

    if (!roomNumber) {
      setRoomNumberError(t("room.fieldRequired"));
      isValid = false;
    } else {
      setRoomNumberError("");
    }

    if (!floor) {
      setFloorError(t("room.fieldRequired"));
      isValid = false;
    } else {
      setFloorError("");
    }

    if (!pricePerNight) {
      setPricePerNightError(t("room.fieldRequired"));
      isValid = false;
    } else {
      setPricePerNightError("");
    }

    if (roomType !== 2 && !numberOfBed) {
      setNumberOfBedError(t("room.fieldRequired"));
      isValid = false;
    } else {
      setNumberOfBedError("");
    }

    if (!roomType) {
      setRoomTypeError(t("room.fieldRequired"));
      isValid = false;
    } else {
      setRoomTypeError("");
    }

    if (isValid) {
      const formData = {
        room_number: roomNumber,
        floor: floor,
        price_per_night: pricePerNight,
        number_of_bed: roomType === 2 ? 1 : numberOfBed,
        room_type: roomType,
      };
      handleAdd(formData);
    }
  };

  return (
    <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
      <Grid item xs={12} md={4}>
        <FormLabel>{t("room.roomNumber")}</FormLabel>
        <TextField
          fullWidth
          size="small"
          placeholder={t("room.enterRoomNumber")}
          value={roomNumber}
          onChange={(e) => setRoomNumber(e.target.value)}
          error={!!roomNumberError}
          helperText={roomNumberError}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <FormLabel>{t("room.floor")}</FormLabel>
        <TextField
          fullWidth
          size="small"
          type="number"
          placeholder={t("room.enterFloor")}
          value={floor}
          onChange={(e) => setFloor(e.target.value)}
          error={!!floorError}
          helperText={floorError}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <FormLabel>{t("room.roomType")}</FormLabel>
        <FormControl fullWidth size="small">
          <Select
            value={roomType}
            onChange={(e) => {
              setRoomType(e.target.value);
              if (e.target.value === 2) {
                setNumberOfBed(1);
              } else {
                setNumberOfBed("");
              }
            }}
            error={!!roomTypeError}
          >
            <MenuItem value={1}>{t("room.normal")}</MenuItem>
            <MenuItem value={2}>{t("room.vip")}</MenuItem>
          </Select>
          {roomTypeError && (
            <Box color="error.main" mt={0.5}>
              {roomTypeError}
            </Box>
          )}
        </FormControl>
      </Grid>

      <Grid item xs={12} md={4}>
        <FormLabel>{t("room.roomPrice")}</FormLabel>
        <TextField
          type="number"
          fullWidth
          size="small"
          placeholder={t("room.enterRoomPrice")}
          value={pricePerNight}
          onChange={(e) => setPricePerNight(e.target.value)}
          error={!!pricePerNightError}
          helperText={pricePerNightError}
        />
      </Grid>

      {roomType !== 2 && (
        <Grid item xs={12} md={4}>
          <FormLabel>{t("room.numberOfBeds")}</FormLabel>
          <TextField
            type="number"
            fullWidth
            size="small"
            placeholder={t("room.enterNumberOfBeds")}
            value={numberOfBed}
            onChange={(e) => setNumberOfBed(e.target.value)}
            error={!!numberOfBedError}
            helperText={numberOfBedError}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" type="submit" disabled={loading}>
            {loading ? t("room.adding") : t("room.addRoom")}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RoomForm;
