import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import useAdd from "../../../../api/useAdd";
import { useTranslation } from "react-i18next";

const AddBloodTypeDialog = ({ open, onClose, token }) => {
  const { t } = useTranslation();
  const [bloodTypeName, setBloodTypeName] = useState("");

  const { handleAdd, loading, resetForm } = useAdd("blood-types", token);

  useEffect(() => {
    if (resetForm) {
      setBloodTypeName("");
      onClose();
    }
  }, [resetForm]);

  const handleAddBloodType = () => {
    handleAdd({ name: bloodTypeName });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("reception.addNewBloodType")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t("reception.bloodTypeName")}
          type="text"
          fullWidth
          variant="outlined"
          value={bloodTypeName}
          onChange={(e) => setBloodTypeName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t("reception.cancel")}
        </Button>
        <Button
          onClick={handleAddBloodType}
          color="primary"
          disabled={loading || !bloodTypeName}
          startIcon={loading && <CircularProgress size={20} />}
        >
          {t("reception.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddBloodTypeDialog;
