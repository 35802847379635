import {
  Box,
  Typography,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Divider,
  Tabs,
  Tab,
  CircularProgress,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import useUpdate from "../../../../api/useUpdate";
import { useTranslation } from "react-i18next";

const ApprovePurchase = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState(""); // "approve" or "reject"
  const [status, setStatus] = useState("");
  const [tabValue, setTabValue] = useState(0);

  const { data, refetch, isLoading, isError } = useFetchObjects(
    ["purchases"],
    `purchases/?is_approved=${status}`,
    token
  );

  const { handleUpdate } = useUpdate("purchases", token);

  useEffect(() => {
    refetch();
  }, [status, refetch]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setStatus(
      newValue === 0 ? "" : newValue === 1 ? 1 : newValue === 2 ? 2 : 3
    );
  };

  const handleOpenDialog = (purchase, type) => {
    setSelectedPurchase(purchase);
    setDialogType(type); // Set dialog type to "approve" or "reject"
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedPurchase(null);
    setDialogType("");
  };

  const handleApproveReject = () => {
    if (selectedPurchase) {
      const formData = new FormData();
      formData.append(
        "is_approved",
        dialogType === "approve" ? 2 : 3 // Approve = 2, Reject = 3
      );
      handleUpdate(selectedPurchase.id, formData);
      handleCloseDialog();
      refetch();
    }
  };

  const renderPurchaseCard = (purchase) => (
    <Paper
      key={purchase.id}
      elevation={3}
      sx={{
        borderRadius: "12px",
        overflow: "hidden",
        mb: 3,
        borderLeft: `6px solid ${
          purchase.is_approved === 1
            ? "orange"
            : purchase.is_approved === 2
            ? "green"
            : "red"
        }`, // Status color
        padding: "16px",
        width: "100%",
        transition: "transform 0.2s ease",
        "&:hover": {
          transform: "scale(1.02)",
        },
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="subtitle1" fontWeight="bold">
            {t("finance.date")}: {purchase.date}
          </Typography>
          <Typography variant="subtitle1">
            {t("finance.medicineCompany")}:{" "}
            <strong>{purchase.medicine_company_display}</strong>
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold" color="primary">
            {t("finance.totalAmount")}:{" "}
            {`${purchase.total_amount.toLocaleString()}؋`}
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="right" mt={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Chip
              label={
                purchase.is_approved === 1
                  ? t("finance.pending")
                  : purchase.is_approved === 2
                  ? t("finance.approved")
                  : t("finance.rejected")
              }
              color={
                purchase.is_approved === 1
                  ? "warning"
                  : purchase.is_approved === 2
                  ? "success"
                  : "error"
              }
              icon={
                purchase.is_approved === 2 ? (
                  <CheckCircleIcon />
                ) : purchase.is_approved === 3 ? (
                  <CloseIcon />
                ) : null
              }
              sx={{
                fontWeight: "bold",
                fontSize: "0.9rem",
              }}
            />
            {purchase.is_approved === 1 && (
              <Box>
                <Tooltip title={t("finance.approve")}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleOpenDialog(purchase, "approve")}
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      mr: 1,
                    }}
                  >
                    {t("finance.approve")}
                  </Button>
                </Tooltip>
                <Tooltip title={t("finance.reject")}>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => handleOpenDialog(purchase, "reject")}
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: "bold",
                    }}
                  >
                    {t("finance.reject")}
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Accordion elevation={0} sx={{ mt: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle2" fontWeight="bold">
                {t("finance.viewMedicines")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">#</TableCell>
                    <TableCell align="center">
                      {t("finance.category")}
                    </TableCell>
                    <TableCell align="center">
                      {t("finance.medicine")}
                    </TableCell>
                    <TableCell align="center">
                      {t("finance.quantity")}
                    </TableCell>
                    <TableCell align="center">
                      {t("finance.purchasePrice")}
                    </TableCell>
                    <TableCell align="center">
                      {t("finance.sellPrice")}
                    </TableCell>
                    <TableCell align="center">
                      {t("finance.expiryDate")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {purchase.items.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {item.category_display}
                      </TableCell>
                      <TableCell align="center">
                        {item.medicine_display}
                      </TableCell>
                      <TableCell align="center">{item.quantity}</TableCell>
                      <TableCell align="center">
                        {`${item.purchase_price.toLocaleString()}؋`}
                      </TableCell>
                      <TableCell align="center">
                        {`${item.sell_price.toLocaleString()}؋`}
                      </TableCell>
                      <TableCell align="center">{item.expiry_date}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Paper>
  );

  // Calculate total amount of approved purchases
  const totalAmount = data
    ?.reduce((sum, purchase) => {
      return purchase.is_approved === 2
        ? sum + parseFloat(purchase.total_amount) || 0
        : sum;
    }, 0)
    .toLocaleString();

  return (
    <Box py={4} sx={{ width: "100%" }}>
      {/* Filtering Section */}
      <Box
        bgcolor="background.paper"
        p={2}
        borderRadius={2}
        boxShadow={3}
        mb={4} // Add spacing between tabs and items
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            label={t("finance.allPurchases")}
            sx={{ textTransform: "capitalize", fontWeight: "bold" }}
          />
          <Tab
            label={t("finance.pending")}
            sx={{ textTransform: "capitalize", fontWeight: "bold" }}
          />
          <Tab
            label={t("finance.approved")}
            sx={{ textTransform: "capitalize", fontWeight: "bold" }}
          />
          <Tab
            label={t("finance.rejected")}
            sx={{ textTransform: "capitalize", fontWeight: "bold" }}
          />
        </Tabs>
      </Box>

      {/* Total Amount Section */}
      {!isLoading && !isError && (
        <Box sx={{ mb: 4, textAlign: "right" }}>
          <Typography variant="h6" fontWeight="bold">
            {t("finance.totalApprovedAmount")}: {totalAmount}؋
          </Typography>
        </Box>
      )}

      {/* Content Section */}
      {isLoading ? (
        <Box textAlign="center" py={4}>
          <CircularProgress />
        </Box>
      ) : isError ? (
        <Box color="error.main" textAlign="center" py={4}>
          {t("finance.errorFetchingData")}
        </Box>
      ) : (
        <Box>{data?.map((purchase) => renderPurchaseCard(purchase))}</Box>
      )}

      {/* Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle
          sx={{
            fontWeight: "bold",
            color: "primary.main",
            textAlign: "center",
          }}
        >
          {dialogType === "approve"
            ? t("finance.approvePurchase")
            : t("finance.rejectPurchase")}
        </DialogTitle>
        <DialogContent>
          <Typography textAlign="center" sx={{ fontWeight: "bold" }}>
            {t(
              dialogType === "approve"
                ? "finance.areYouSureApprove"
                : "finance.areYouSureReject"
            )}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
          <Button
            onClick={handleCloseDialog}
            color="error"
            startIcon={<CloseIcon />}
            sx={{ fontWeight: "bold", textTransform: "capitalize" }}
          >
            {t("finance.cancel")}
          </Button>
          <Button
            onClick={handleApproveReject}
            variant="contained"
            color={dialogType === "approve" ? "primary" : "error"}
            startIcon={
              dialogType === "approve" ? <CheckCircleIcon /> : <CloseIcon />
            }
            sx={{ fontWeight: "bold", textTransform: "capitalize" }}
          >
            {dialogType === "approve"
              ? t("finance.approve")
              : t("finance.reject")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ApprovePurchase;
