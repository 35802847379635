import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Switch,
  FormControlLabel,
  Paper,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/system";
import useFetchObjects from "../../../../../api/useFetchObjects";
import useAdd from "../../../../../api/useAdd";
import { useAuth } from "../../../../../context";
import ListUltrasoundReferral from "./ListUltrasoundReferral";
import { useTranslation } from "react-i18next";

// Styled Components for Full-Width Layout
const FullWidthPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#ffffff",
  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 700,
  color: "#3a3a3a",
  textAlign: "left",
  marginBottom: theme.spacing(4),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  "& .MuiOutlinedInput-root": {
    borderRadius: theme.shape.borderRadius,
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.main,
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: theme.palette.primary.main,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  fontWeight: 700,
  fontSize: "1rem",
  padding: "12px 16px",
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const UltrasoundReferral = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const { data: ultrasoundTypesData } = useFetchObjects(
    ["ultrasound-types"],
    "ultrasound-types/",
    token,
  );
  const { data: patientsData } = useFetchObjects(
    ["patients"],
    "patients/",
    token,
  );

  const { handleAdd, resetForm } = useAdd("ultrasounds", token);

  const ultrasoundTypes = ultrasoundTypesData || [];
  const patients = patientsData?.results || [];

  const getCurrentDate = () => new Date().toISOString().split("T")[0];

  const [ultrasoundDetails, setUltrasoundDetails] = useState({
    ultrasoundType: "",
    date: getCurrentDate(),
    isUrgent: false,
    totalAmount: "",
    patient: null,
    doctor: auth?.user?.user?.id,
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (resetForm) {
      setUltrasoundDetails({
        ultrasoundType: "",
        date: getCurrentDate(),
        isUrgent: false,
        totalAmount: "",
        patient: null,
        doctor: auth?.user?.user?.id,
      });
      setErrors({});
    }
  }, [resetForm]);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setUltrasoundDetails((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "ultrasoundType") {
      const selectedType = ultrasoundTypes.find(
        (type) => type.id.toString() === value,
      );
      setUltrasoundDetails((prev) => ({
        ...prev,
        totalAmount: selectedType ? selectedType.cost.toString() : "",
      }));
    }

    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!ultrasoundDetails.patient) {
      tempErrors.patient = t("doctor.patientRequired");
    }
    if (!ultrasoundDetails.ultrasoundType) {
      tempErrors.ultrasoundType = t("doctor.ultTypeRequired");
    }
    if (!ultrasoundDetails.date) {
      tempErrors.date = t("doctor.testDateRequired");
    }
    if (!ultrasoundDetails.totalAmount) {
      tempErrors.totalAmount = t("doctor.totalAmountRequired");
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const payload = {
        patient: ultrasoundDetails.patient.id,
        doctor: ultrasoundDetails.doctor,
        ultrasound_type: ultrasoundDetails.ultrasoundType,
        date: ultrasoundDetails.date,
        is_urgent: ultrasoundDetails.isUrgent,
        total_amount: ultrasoundDetails.totalAmount,
      };
      handleAdd(payload);
    }
  };

  return (
    <FullWidthPaper>
      <SectionTitle>{t("doctor.ultReferralForm")}</SectionTitle>

      <Grid container spacing={3}>
        {/* Patient Autocomplete */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={patients}
            getOptionLabel={(option) =>
              option
                ? `${option.id} - ${option.firstname} ${option.lastname}`
                : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("doctor.selectPatient")}
                variant="outlined"
                fullWidth
                error={!!errors.patient}
                helperText={errors.patient}
              />
            )}
            value={ultrasoundDetails.patient}
            onChange={(event, newValue) => {
              setUltrasoundDetails((prev) => ({ ...prev, patient: newValue }));
              setErrors((prev) => ({ ...prev, patient: "" }));
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Grid>

        {/* Ultrasound Type Dropdown */}
        <Grid item xs={12} sm={6}>
          <StyledFormControl fullWidth error={!!errors.ultrasoundType}>
            <InputLabel>{t("doctor.ultrasoundType")}</InputLabel>
            <Select
              name="ultrasoundType"
              value={ultrasoundDetails.ultrasoundType}
              onChange={handleChange}
              displayEmpty
            >
              {ultrasoundTypes.map((type) => (
                <MenuItem key={type.id} value={type.id.toString()}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
            {errors.ultrasoundType && (
              <Typography color="error" variant="caption">
                {errors.ultrasoundType}
              </Typography>
            )}
          </StyledFormControl>
        </Grid>

        {/* Test Date */}
        <Grid item xs={12} sm={6}>
          <TextField
            name="date"
            label={t("doctor.testDate")}
            type="date"
            fullWidth
            value={ultrasoundDetails.date}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            error={!!errors.date}
            helperText={errors.date}
          />
        </Grid>

        {/* Total Amount */}
        <Grid item xs={12} sm={6}>
          <TextField
            name="totalAmount"
            label={t("doctor.totalAmount")}
            type="number"
            fullWidth
            value={ultrasoundDetails.totalAmount}
            onChange={handleChange}
            error={!!errors.totalAmount}
            helperText={errors.totalAmount}
          />
        </Grid>

        {/* Urgent Switch */}
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={ultrasoundDetails.isUrgent}
                onChange={handleChange}
                name="isUrgent"
              />
            }
            label={t("doctor.urgent")}
          />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <StyledButton fullWidth onClick={handleSubmit}>
            {t("doctor.submit")}
          </StyledButton>
        </Grid>
      </Grid>

      {/* List of Referrals */}
      <Box mt={5}>
        <ListUltrasoundReferral />
      </Box>
    </FullWidthPaper>
  );
};

export default UltrasoundReferral;
