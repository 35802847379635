import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useTranslation } from "react-i18next";

dayjs.extend(utc);
dayjs.extend(timezone);

const DischargeDialog = ({ open, onClose, onSave }) => {
  const { t } = useTranslation();
  const [dischargeDate, setDischargeDate] = useState(
    dayjs().tz("Asia/Kabul").format("YYYY-MM-DD"),
  );
  const [discount, setDiscount] = useState(0);

  const handleSave = () => {
    onSave(dischargeDate, discount);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("room.setDischargeDate")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("room.pleaseSelectDischargeDate")}
        </DialogContentText>
        <TextField
          type="date"
          label={t("room.dischargeDate")}
          fullWidth
          margin="normal"
          value={dischargeDate}
          onChange={(e) => setDischargeDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          type="number"
          label={t("room.discountAmount")}
          fullWidth
          margin="normal"
          value={discount}
          onChange={(e) => setDiscount(Number(e.target.value))}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          {t("room.cancel")}
        </Button>
        <Button variant="contained" onClick={handleSave} color="primary">
          {t("room.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DischargeDialog;
