import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Paper,
  Typography,
  Stack,
  Box,
  Chip,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Container,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  useTheme,
  CircularProgress,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import SkeletonLoading from "./skeletonLoading/SkeletonLoading";
import ErrorPage from "../../../../Components/GeneralComponents/errorPage/ErrorPage";
import useUpdate from "../../../../api/useUpdate";
import { useTranslation } from "react-i18next";
import PrintIcon from "@mui/icons-material/Print";
import MedicineOrderPrint from "../../Pharmacy/POS/MedicineOrderPrint";

const CollectMedicineOrderFees = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [printData, setPrintData] = useState(null);
  const printRef = useRef();

  const [searchTerm, setSearchTerm] = useState("");
  const [query, setQuery] = useState("");
  const [filterLoan, setFilterLoan] = useState(false);

  const { data, isLoading, isError, error, refetch } = useFetchObjects(
    ["medicine-orders"],
    `medicine-orders/?search=${query}&is_loan=${filterLoan ? "true" : ""}`,
    token
  );

  useEffect(() => {
    refetch();
  }, [query, filterLoan, refetch]);

  const { handleUpdate, isLoading: updateLoading } = useUpdate(
    "medicine-orders",
    token
  );

  const handleStatusChange = (id) => {
    const formData = new FormData();
    formData.append("payment_status", 2);
    handleUpdate(id, formData);
  };

  const handlePrintClick = (order) => {
    setPrintData(order);
    setTimeout(() => {
      if (printRef.current) {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(printRef.current.innerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }
    }, 500);
  };

  if (isLoading) {
    return <SkeletonLoading />;
  }

  if (isError) {
    return <ErrorPage errorMessage={error.message} />;
  }

  const getPaymentStatusLabel = (status) => {
    switch (status) {
      case 1:
        return t("reception.pending");
      case 2:
        return t("reception.paid");
      case 3:
        return t("reception.canceled");
      default:
        return t("reception.unknown");
    }
  };

  const getPaymentStatusColor = (status) => {
    switch (status) {
      case 1:
        return "warning";
      case 2:
        return "success";
      case 3:
        return "error";
      default:
        return "default";
    }
  };

  return (
    <Container maxWidth="xl">
      <Box my={4} display="flex" gap={2} alignItems="center">
        <TextField
          fullWidth
          label={t("reception.searchMedicine")}
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filterLoan}
              onChange={(e) => setFilterLoan(e.target.checked)}
              color="primary"
            />
          }
          label={t("reception.loanOrdersOnly")}
        />
      </Box>

      <Grid container spacing={3} padding="20px" mt={3}>
        {data.length === 0 ? (
          <Typography>{t("reception.noMatchingMedicine")}</Typography>
        ) : (
          data.map((order, index) => {
            const isPaid = order.payment_status === 2;
            const isLoan = order.is_loan;

            return (
              <Grid item xs={12} md={6} key={index}>
                <Paper
                  elevation={isLoan ? 4 : isPaid ? 2 : 4}
                  sx={{
                    mb: 3,
                    padding: "24px",
                    borderRadius: "12px",
                    width: "100%",
                    boxShadow: isLoan
                      ? "0px 8px 24px rgba(0, 123, 255, 0.3)"
                      : isPaid
                      ? "0px 4px 12px rgba(0, 0, 0, 0.1)"
                      : "0px 8px 24px rgba(255, 0, 0, 0.3)",
                    backgroundColor: isLoan
                      ? "#e3f2fd"
                      : isPaid
                      ? "#f9f9f9"
                      : "#ffebee",
                    borderLeft: isLoan
                      ? "4px solid #1976d2"
                      : isPaid
                      ? "none"
                      : "4px solid #d32f2f",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color="primary"
                      sx={{ fontSize: isSmallScreen ? "1rem" : "1.25rem" }}
                    >
                      {t("reception.medicineOrder")} #{order.id} - {order.date}
                    </Typography>
                    <Box>
                      <Chip
                        label={getPaymentStatusLabel(order.payment_status)}
                        color={getPaymentStatusColor(order.payment_status)}
                        sx={{
                          fontWeight: "bold",
                          fontSize: isSmallScreen ? "0.8rem" : "0.9rem",
                        }}
                      />
                      <IconButton
                        color="primary"
                        onClick={() => handlePrintClick(order)}
                      >
                        <PrintIcon />
                      </IconButton>
                    </Box>
                  </Box>

                  {isLoan && (
                    <Box sx={{ mt: 2, p: 2, backgroundColor: "#bbdefb" }}>
                      <Typography variant="body1">
                        <strong>{t("reception.name")}: </strong>
                        {order.name}
                      </Typography>
                      <Typography variant="body1">
                        <strong>{t("reception.fatherName")}: </strong>
                        {order.father_name}
                      </Typography>
                      <Typography variant="body1">
                        <strong>{t("reception.phoneNumber")}: </strong>
                        {order.phone_number}
                      </Typography>
                    </Box>
                  )}

                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ mt: 2 }}
                  >
                    <Typography
                      fontSize={isSmallScreen ? "0.85rem" : "1rem"}
                      color={
                        isLoan
                          ? "info.main"
                          : !isPaid
                          ? "error.main"
                          : "text.primary"
                      }
                    >
                      <strong>{t("reception.totalPrice")}:</strong>{" "}
                      {order.total_price} ؋
                    </Typography>
                  </Stack>

                  {order.payment_status === 1 && (
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      spacing={2}
                      sx={{ mt: 2 }}
                    >
                      <Button
                        variant="contained"
                        fullWidth={isSmallScreen}
                        disabled={updateLoading}
                        startIcon={
                          updateLoading && <CircularProgress size={24} />
                        }
                        onClick={() => handleStatusChange(order.id)}
                        sx={{
                          minWidth: 150,
                          background:
                            "linear-gradient(90deg, #d32f2f, #f44336)",
                          color: "#fff",
                          fontWeight: "bold",
                          borderRadius: "8px",
                          padding: "10px 20px",
                          transition: "0.3s",
                          "&:hover": {
                            background:
                              "linear-gradient(90deg, #f44336, #d32f2f)",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                          },
                        }}
                      >
                        {updateLoading
                          ? t("reception.loading")
                          : t("reception.collectFees")}
                      </Button>
                    </Stack>
                  )}

                  <Divider sx={{ mt: 2, mb: 2 }} />

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        fontWeight="bold"
                        color={!isPaid ? "error.main" : "text.primary"}
                      >
                        {t("reception.medicineInOrder")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {order.items && order.items.length > 0 ? (
                        <Box sx={{ overflowX: "auto" }}>
                          <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">
                                  {t("reception.medicine")}
                                </TableCell>
                                <TableCell align="center">
                                  {t("reception.sellPrice")}
                                </TableCell>
                                <TableCell align="center">
                                  {t("reception.quantity")}
                                </TableCell>
                                <TableCell align="center">
                                  {t("reception.createdAt")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {order.items.map((item, idx) => (
                                <TableRow key={item.id}>
                                  <TableCell align="center">
                                    <Typography fontWeight="bold">
                                      {item.stock_medicine_display}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.sell_price_display} ؋
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.quantity}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.created_at}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      ) : (
                        <Typography>
                          {t("reception.noMedicineOrder")}
                        </Typography>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Paper>
              </Grid>
            );
          })
        )}

        {printData && (
          <div ref={printRef} style={{ display: "none" }}>
            <MedicineOrderPrint data={printData} />
          </div>
        )}
      </Grid>
    </Container>
  );
};

export default CollectMedicineOrderFees;
