import React, { useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
} from "@mui/material";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import useDelete from "../../../../api/useDelete";
import AdvanceEdit from "./AdvanceEdit";
import { useTranslation } from "react-i18next";
import { Months } from "../../../../utils";

const AdvanceList = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const [filters, setFilters] = useState({ month: "", year: "", employee: "" });

  const { handleDelete, ConfirmDialog } = useDelete("employee-advance", token);

  const { data: employees } = useFetchObjects(["employees"], "users/", token);

  const endpoint = `employee-advance/?month=${filters.month}&year=${filters.year}&employee=${filters.employee}`;
  const { data, isLoading, isError, error } = useFetchObjects(
    ["employee-advance", filters],
    endpoint,
    token,
  );

  const [open, setOpen] = useState(false);
  const [selectedAdvance, setSelectedAdvance] = useState(null);

  const handleOpen = (item) => {
    setSelectedAdvance(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAdvance(null);
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  if (isLoading) {
    return <Typography>{t("hr.loading")}</Typography>;
  }

  if (isError) {
    return <Typography>{error.message}</Typography>;
  }

  return (
    <Grid container padding={"10px"}>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={4}>
          <TextField
            select
            label={t("hr.month")}
            name="month"
            value={filters.month}
            onChange={handleFilterChange}
            fullWidth
          >
            {Months.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            label={t("hr.year")}
            name="year"
            value={filters.year}
            onChange={handleFilterChange}
            fullWidth
          >
            {Array.from({ length: 5 }, (_, index) => (
              <MenuItem key={2020 + index} value={2020 + index}>
                {2020 + index}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            label={t("hr.employee")}
            name="employee"
            value={filters.employee}
            onChange={handleFilterChange}
            fullWidth
          >
            {employees.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.first_name + " " + item.last_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <TableContainer sx={{ mt: 2 }}>
        <Table sx={{ minWidth: "100%" }} aria-label="simple table">
          <TableHead style={{ backgroundColor: "#f2f2f2" }}>
            <TableRow>
              <TableCell align="center">{t("hr.employee")}</TableCell>
              <TableCell align="center">{t("hr.amount")}</TableCell>
              <TableCell align="center">{t("hr.month")}</TableCell>
              <TableCell align="center">{t("hr.year")}</TableCell>
              <TableCell align="center">{t("hr.paidDate")}</TableCell>
              <TableCell align="center">{t("hr.update")}</TableCell>
              <TableCell align="center">{t("hr.delete")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{item.employee_display}</TableCell>
                <TableCell align="center">{item.amount}</TableCell>
                <TableCell align="center">{item.month_display}</TableCell>
                <TableCell align="center">{item.year}</TableCell>
                <TableCell align="center">{item.date}</TableCell>
                <TableCell align="center">
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      boxShadow: "0",
                      textTransform: "capitalize",
                      color: "green",
                      backgroundColor: "lightgreen",
                      "&:hover": {
                        backgroundColor: "green",
                        color: "white",
                      },
                    }}
                    onClick={() => handleOpen(item)}
                  >
                    {t("hr.update")}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => handleDelete(item.id)}
                    size="small"
                    variant="contained"
                    sx={{
                      boxShadow: "0",
                      textTransform: "capitalize",
                      color: "red",
                      backgroundColor: "pink",
                      "&:hover": {
                        backgroundColor: "red",
                        color: "white",
                      },
                    }}
                  >
                    {t("hr.delete")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog />

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{t("hr.editAdvance")}</DialogTitle>
        <DialogContent>
          <AdvanceEdit
            advance={selectedAdvance}
            onClose={handleClose}
            token={token}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default AdvanceList;
