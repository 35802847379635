import React from "react";
import {
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import useFetchObjects from "../../../../../api/useFetchObjects";
import useDelete from "../../../../../api/useDelete";
import ErrorPage from "../../../../../Components/GeneralComponents/errorPage/ErrorPage";
import { useAuth } from "../../../../../context/";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

const EmployeesList = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const navigate = useNavigate();

  const { data, isLoading, isError } = useFetchObjects(
    ["users"],
    "users",
    token,
  );
  const { handleDelete, ConfirmDialog } = useDelete("users", token);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <Grid container>
        <ErrorPage />
      </Grid>
    );
  }

  // Filter out superusers
  const filteredData = data?.filter((user) => !user.is_staff);

  return (
    <Container maxWidth="lg" sx={{ marginTop: 4 }}>
      <Paper elevation={3} sx={{ padding: 4, borderRadius: 2 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="h4" color="primary" fontWeight={700}>
            {t("hr.employees")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleIcon />}
            onClick={() => navigate("/human-resource/employees/add")}
          >
            {t("hr.newEmployee")}
          </Button>
        </Grid>

        <Divider sx={{ my: 2 }} />

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("hr.no")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("hr.firstName")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("hr.lastName")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("hr.position")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("hr.department")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("hr.salary")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("hr.contractDate")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("hr.contractDuration")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("hr.action")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.map((item, i) => (
                <TableRow
                  key={i}
                  sx={{
                    backgroundColor:
                      i % 2 === 0 ? "action.hover" : "background.default",
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center">{i + 1}</TableCell>
                  <TableCell align="center">{item?.first_name}</TableCell>
                  <TableCell align="center">{item?.last_name}</TableCell>
                  <TableCell align="center">{item?.display_position}</TableCell>
                  <TableCell align="center">
                    {item?.display_department}
                  </TableCell>
                  <TableCell align="center">{item?.salary_amount}</TableCell>
                  <TableCell align="center">{item?.contract_date}</TableCell>
                  <TableCell align="center">
                    {item?.available_contract_date}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() =>
                        navigate("/human-resource/employees/edit", {
                          state: item,
                        })
                      }
                      aria-label="edit"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(item.id)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ConfirmDialog />
    </Container>
  );
};

export default EmployeesList;
