import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const useFetchObjects = (queryKey, endpoint, token, options = {}) => {
  const fetchFunction = async () => {
    const headers = token ? { Authorization: `Token ${token}` } : {};
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      {
        headers,
      }
    );
    return response.data;
  };

  const { data, isLoading, isError, isSuccess, error, refetch } = useQuery({
    queryKey: queryKey,
    queryFn: fetchFunction,
    refetchOnWindowFocus: options.refetchOnWindowFocus ?? true,
    refetchInterval: options.refetchInterval ?? false,
  });

  return { data, isLoading, isError, isSuccess, error, refetch };
};

export default useFetchObjects;
