import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Box,
  Stack,
  Paper,
  MenuItem,
} from "@mui/material";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import TaskTable from "./TaskTable";
import EditTask from "./editTask/EditTask";
import useDelete from "../../../../api/useDelete";
import { useTranslation } from "react-i18next";

const buttonStyle = {
  textTransform: "capitalize",
  backgroundColor: "#071952",
  fontWeight: "bold",
};

const AddTaskToNurse = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const [open, setOpen] = useState(false);
  const [openDescription, setOpenDescription] = useState(false);
  const [currentTask, setCurrentTask] = useState({});

  const getCurrentDate = () => new Date().toISOString().split("T")[0];
  const getCurrentTime = () =>
    new Date().toLocaleTimeString("en-US", { hour12: false }).slice(0, 5);

  const [formData, setFormData] = useState({
    nurse: "",
    assigned_by: user?.user?.id,
    assigned_date: getCurrentDate(),
    description: "",
    patient: "",
    room_number: "",
    time: getCurrentTime(),
  });

  const [formErrors, setFormErrors] = useState({});

  const { data: rooms } = useFetchObjects(["rooms"], "rooms/", token);

  const { data: users, isLoading: isLoadingUsers } = useFetchObjects(
    ["UsersList"],
    `users/`,
    token,
  );

  const { data: patients, isLoading: isLoadingPatients } = useFetchObjects(
    ["PatientsList"],
    `patients/`,
    token,
  );

  const { data: assignedTasks, isLoading: isLoadingAssignedTasks } =
    useFetchObjects(
      ["NursesAssignedTasks"],
      `assign-task-to-nurse/?assigned_by=${auth?.user?.user?.id}`,
      token,
    );

  const { handleAdd } = useAdd("assign-task-to-nurse", token);
  const { handleDelete, ConfirmDialog } = useDelete(
    `assign-task-to-nurse`,
    token,
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) errors[key] = t("doctor.fieldRequired");
    });

    if (Object.keys(errors).length === 0) {
      const appointmentData = new FormData();
      Object.keys(formData).forEach((key) =>
        appointmentData.append(key, formData[key]),
      );
      handleAdd(appointmentData);
    } else {
      setFormErrors(errors);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  const handleNurseChange = (e, value) => {
    setFormData({ ...formData, nurse: value?.id });
    setFormErrors({ ...formErrors, nurse: "" });
  };

  const handlePatientChange = (e, value) => {
    setFormData({ ...formData, patient: value?.id });
    setFormErrors({ ...formErrors, patient: "" });
  };

  const handleOpenEdit = (task) => {
    setCurrentTask(task);
    setOpen(true);
  };

  const handleOpenDescription = (task) => {
    setCurrentTask(task);
    setOpenDescription(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDescription(false);
  };

  return (
    <Grid container mt={2} mb={10}>
      <Grid item xs={12} lg={12}>
        <Paper sx={{ boxShadow: "0px 0px 0px ", padding: "20px" }}>
          <form onSubmit={handleSubmit}>
            <Grid container gap={2} justifyContent={"space-between"} mt={2}>
              <Grid xs={12} md={6} item>
                <FormLabel> {t("doctor.nurseName")}</FormLabel>
                {isLoadingUsers ? (
                  <Box display="flex" justifyContent="center" py={2}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Autocomplete
                    options={users || []}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name}`
                    }
                    size="small"
                    fullWidth
                    onChange={handleNurseChange}
                    renderInput={(params) => (
                      <TextField {...params} name="nurse" variant="outlined" />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={3.5}>
                <FormLabel>{t("doctor.date")}</FormLabel>
                <TextField
                  type="date"
                  name="assigned_date"
                  value={formData.assigned_date}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              spacing={4}
              mt={4}
            >
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid xs={12} item>
                <FormLabel>{t("doctor.description")}</FormLabel>
                <TextField
                  name="description"
                  onChange={handleChange}
                  fullWidth
                  size="small"
                  multiline
                  rows={5}
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <FormLabel>{t("doctor.patientName")}</FormLabel>
                {isLoadingPatients ? (
                  <Box display="flex" justifyContent="center" py={2}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Autocomplete
                    options={patients && patients.results}
                    getOptionLabel={(option) =>
                      option
                        ? `${option.id} - ${option.firstname} ${option.lastname}`
                        : ""
                    }
                    size="small"
                    fullWidth
                    onChange={handlePatientChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="patient"
                        variant="outlined"
                        label={t("doctor.selectPatient")}
                      />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabel>{t("doctor.time")}</FormLabel>
                <TextField
                  name="time"
                  type="time"
                  value={formData.time}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel>{t("doctor.roomNo")} </FormLabel>
                <TextField
                  select
                  name="room_number"
                  type="text"
                  onChange={handleChange}
                  fullWidth
                  size="small"
                >
                  {(rooms || []).map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}
                    >{`room number: ${item.room_number} floor: ${item.floor}`}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel>{t("doctor.priority")}</FormLabel>
                <TextField
                  select
                  name="priority"
                  type="text"
                  onChange={handleChange}
                  fullWidth
                  size="small"
                >
                  <MenuItem value="1">{t("doctor.low")}</MenuItem>
                  <MenuItem value="2">{t("doctor.medium")}</MenuItem>
                  <MenuItem value="3">{t("doctor.hight")}</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <Stack
              fullWidth
              mt={4}
              direction="row"
              gap={1}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                variant="contained"
                style={{ ...buttonStyle, marginLeft: "20px" }}
              >
                {t("doctor.save")}
              </Button>
            </Stack>
          </form>
          <Divider sx={{ margin: "2rem 0" }} fullWidth />

          <Typography variant="h6" style={{ padding: "16px" }}>
            {t("doctor.taskAssignNurse")}
          </Typography>

          {isLoadingAssignedTasks ? (
            <Box display="flex" justifyContent="center" py={4}>
              <CircularProgress />
            </Box>
          ) : (
            <TaskTable
              assignedTasks={assignedTasks}
              handleOpenEdit={handleOpenEdit}
              handleOpenDescription={handleOpenDescription}
              handleDelete={handleDelete}
            />
          )}
        </Paper>
        <ConfirmDialog />
      </Grid>

      <EditTask
        open={open}
        onClose={handleClose}
        users={users}
        patients={patients}
        task={currentTask}
      />

      <Dialog
        PaperProps={{
          style: {
            minWidth: "260px",
            maxWidth: "600px",
            width: "100%",
          },
        }}
        open={openDescription}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography variant="h6">{t("doctor.taskDescription")} </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{currentTask?.description}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t("doctor.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AddTaskToNurse;
