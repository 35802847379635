export const Gender = [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
];
export const Months = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

const currentYear = new Date().getFullYear();
export const years = Array.from(
  { length: currentYear - 2020 + 1 },
  (v, i) => 2020 + i,
);
