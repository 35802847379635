import { Box, Grid, Paper, Tabs, Tab } from "@mui/material";
import React, { useState, useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const tabStyle = {
  textTransform: "capitalize",
  fontWeight: "bold",
  borderRadius: "10px",
};

const XrayNav = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("/");
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    const path = location.pathname;
    const validPaths = [
      "/xray",
      "/xray/test-result",
      "xray/xray-types",
      "xray/surgery-xray-patient",
    ];

    if (validPaths.includes(path)) {
      setValue(path);
    } else {
      setValue("/xray");
    }
  }, [location.pathname]);

  return (
    <Grid container maxWidth="xl">
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Paper sx={{ padding: "10px 0px", boxShadow: "0px 0px 0px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{ ml: "5px", "& .MuiTabs-indicator": { display: "none" } }}
          >
            <Tab
              style={{
                ...tabStyle,
                backgroundColor: value === "/x-rays" ? "#007174" : "white",
                color: value === "/x-rays" ? "white" : "black",
              }}
              label={t("x_ray.dashboard")}
              value="/x-rays"
              component={NavLink}
              to="/x-rays"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/x-rays/test-result" ? "#007174" : "white",
                color: value === "/x-rays/test-result" ? "white" : "black",
              }}
              label={t("x_ray.testResult")}
              value="/x-rays/test-result"
              component={NavLink}
              to="/x-rays/test-result"
            />

            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/x-rays/xray-types" ? "#007174" : "white",
                color: value === "/x-rays/xray-types" ? "white" : "black",
              }}
              label={t("x_ray.xrayType")}
              value="/x-rays/xray-types"
              component={NavLink}
              to="/x-rays/xray-types"
            />

            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/x-rays/surgery-xray-patient" ? "#007174" : "white",
                color: value === "/x-rays/surgery-xray-patient" ? "white" : "black",
              }}
              label={t("x_ray.surgeryXrayPatient")}
              value="/x-rays/surgery-xray-patient"
              component={NavLink}
              to="/x-rays/surgery-xray-patient"
            />
          </Tabs>
        </Paper>
      </Box>
      <Outlet />
    </Grid>
  );
};

export default XrayNav;
