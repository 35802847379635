import { Box, Grid, Paper, Tabs, Tab } from "@mui/material";
import React, { useState, useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const tabStyle = {
  textTransform: "capitalize",
  fontWeight: "bold",
  borderRadius: "10px",
};

const FinanceNav = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("/finance");
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    const path = location.pathname;
    const validPaths = [
      "/finance",
      "/finance/patients",
      "/finance/laboratory",
      "/finance/echo",
      "/finance/ultrasound",
      "/finance/xray",
      "/finance/operations",
      "/finance/expenses-type",
      "/finance/expenses",
      "/finance/account",
      "/finance/payroll",
      "/finance/advance",
      "/finance/approve",
      "/finance/approve-discount",
      "/finance/report",
    ];
    if (validPaths.includes(path)) {
      setValue(path);
    } else {
      setValue("/finance");
    }
  }, [location.pathname]);

  return (
    <Grid container maxWidth="xl">
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Paper sx={{ padding: "10px 0px", boxShadow: "0px 0px 0px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              ml: "5px",
              "& .MuiTabs-indicator": { display: "none" },
              "& .MuiTab-root": { minWidth: "120px" },
            }}
          >
            <Tab
              style={{
                ...tabStyle,
                backgroundColor: value === "/finance" ? "#007174" : "white",
                color: value === "/finance" ? "white" : "black",
              }}
              label={t("finance.dashboard")}
              value="/finance"
              component={NavLink}
              to="/finance"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/finance/patients" ? "#007174" : "white",
                color: value === "/finance/patients" ? "white" : "black",
              }}
              label={t("finance.patients")}
              value="/finance/patients"
              component={NavLink}
              to="/finance/patients"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/finance/laboratory" ? "#007174" : "white",
                color: value === "/finance/laboratory" ? "white" : "black",
              }}
              label={t("finance.laboratory")}
              value="/finance/laboratory"
              component={NavLink}
              to="/finance/laboratory"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/finance/echo" ? "#007174" : "white",
                color: value === "/finance/echo" ? "white" : "black",
              }}
              label={t("finance.echo")}
              value="/finance/echo"
              component={NavLink}
              to="/finance/echo"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/finance/ultrasound" ? "#007174" : "white",
                color: value === "/finance/ultrasound" ? "white" : "black",
              }}
              label={t("finance.ultrasound")}
              value="/finance/ultrasound"
              component={NavLink}
              to="/finance/ultrasound"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/finance/xray" ? "#007174" : "white",
                color: value === "/finance/xray" ? "white" : "black",
              }}
              label={t("finance.xray")}
              value="/finance/xray"
              component={NavLink}
              to="/finance/xray"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/finance/operations" ? "#007174" : "white",
                color: value === "/finance/operations" ? "white" : "black",
              }}
              label={t("finance.operations")}
              value="/finance/operations"
              component={NavLink}
              to="/finance/operations"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/finance/payroll" ? "#007174" : "white",
                color: value === "/finance/payroll" ? "white" : "black",
              }}
              label={t("finance.payroll")}
              value="/finance/payroll"
              component={NavLink}
              to="/finance/payroll"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/finance/advance" ? "#007174" : "white",
                color: value === "/finance/advance" ? "white" : "black",
              }}
              label={t("finance.advance")}
              value="/finance/advance"
              component={NavLink}
              to="/finance/advance"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/finance/expenses-type" ? "#007174" : "white",
                color: value === "/finance/expenses-type" ? "white" : "black",
              }}
              label={t("finance.expenseType")}
              value="/finance/expenses-type"
              component={NavLink}
              to="/finance/expenses-type"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/finance/expenses" ? "#007174" : "white",
                color: value === "/finance/expenses" ? "white" : "black",
              }}
              label={t("finance.expenses")}
              value="/finance/expenses"
              component={NavLink}
              to="/finance/expenses"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/finance/account" ? "#007174" : "white",
                color: value === "/finance/account" ? "white" : "black",
              }}
              label={t("finance.accounts")}
              value="/finance/account"
              component={NavLink}
              to="/finance/account"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/finance/approve" ? "#007174" : "white",
                color: value === "/finance/approve" ? "white" : "black",
              }}
              label={t("finance.approve")}
              value="/finance/approve"
              component={NavLink}
              to="/finance/approve"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/finance/approve-discount" ? "#007174" : "white",
                color:
                  value === "/finance/approve-discount" ? "white" : "black",
              }}
              label={t("finance.approveDiscount")}
              value="/finance/approve-discount"
              component={NavLink}
              to="/finance/approve-discount"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/finance/report" ? "#007174" : "white",
                color: value === "/finance/report" ? "white" : "black",
              }}
              label={t("finance.report")}
              value="/finance/report"
              component={NavLink}
              to="/finance/report"
            />
          </Tabs>
        </Paper>
      </Box>
      <Outlet />
    </Grid>
  );
};

export default FinanceNav;
