import React, { useState } from "react";
import { Grid, Typography, Paper, Box, Divider } from "@mui/material";
import RoomForm from "./RoomForm/RoomForm";
import RoomList from "./RoomList/RoomList";
import { useTranslation } from "react-i18next";

const RoomIndex = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={4}
      maxWidth={"xl"}
      mt={2}
      justifyContent={"center"}
      mb={10}
    >
      <Grid item xs={12} lg={10}>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          {/* Room Summary Section */}

          {/* Room Form Section */}
          <Box mb={4}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("room.addNewRoom")}
            </Typography>
            <RoomForm />
          </Box>

          <Divider sx={{ marginY: 3 }} />

          {/* Room List Section */}
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("room.roomList")}
            </Typography>
            <RoomList />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default RoomIndex;
