import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
  Tooltip,
} from "@mui/material";
import { Delete, Visibility } from "@mui/icons-material";
import useFetchObjects from "../../../../../api/useFetchObjects";
import useDelete from "../../../../../api/useDelete";
import { useAuth } from "../../../../../context";
import { useTranslation } from "react-i18next";

const ListReturnMedicine = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const pharmacy = auth?.user?.user.pharmacy_type || "";

  const { data, refetch, isLoading, isError } = useFetchObjects(
    ["return-medicines"],
    `return-medicines/?pharmacy=${pharmacy}`,
    token
  );

  const { handleDelete, ConfirmDialog } = useDelete("return-medicines", token);

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedReturn, setSelectedReturn] = useState(null);

  const handleOpenDialog = (medicineReturn) => {
    setSelectedReturn(medicineReturn);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedReturn(null);
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Box>
      <Paper
        elevation={2}
        sx={{
          padding: 3,
          margin: "2em auto",
          maxWidth: "1200px",
          borderRadius: 4,
          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          variant="h5"
          fontWeight={600}
          textAlign="center"
          gutterBottom
        >
          {t("pharmacy.returnMedicineList")}
        </Typography>
        <Divider sx={{ marginY: 2 }} />

        {isLoading ? (
          <Grid container justifyContent="center" sx={{ mt: 4 }}>
            <CircularProgress />
          </Grid>
        ) : isError ? (
          <Typography color="error" textAlign="center">
            {t("pharmacy.failedToLoad")}
          </Typography>
        ) : (
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: "#f9f9f9" }}>
                <TableRow>
                  <TableCell align="center">#</TableCell>
                  <TableCell align="center">{t("pharmacy.date")}</TableCell>
                  <TableCell align="center">
                    {t("pharmacy.totalAmount")}
                  </TableCell>
                  <TableCell align="center">{t("pharmacy.actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((medicineReturn, index) => (
                  <TableRow key={medicineReturn.id} hover>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{medicineReturn.date}</TableCell>
                    <TableCell align="center">
                      {medicineReturn.total_amount}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip>
                        <IconButton
                          color="primary"
                          onClick={() => handleOpenDialog(medicineReturn)}
                        >
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                      <Tooltip>
                        <IconButton
                          color="error"
                          onClick={() => handleDelete(medicineReturn.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      {/* View Details Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{t("pharmacy.returnDetails")}</DialogTitle>
        <DialogContent dividers>
          {selectedReturn ? (
            <>
              <Typography variant="body1" fontWeight={600}>
                {t("pharmacy.id")}: {selectedReturn.id}
              </Typography>
              <Typography variant="body1">
                {t("pharmacy.date")}: {selectedReturn.date}
              </Typography>
              <Typography variant="body1">
                {t("pharmacy.totalAmount")}: {selectedReturn.total_amount}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6">{t("pharmacy.items")}</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>{t("pharmacy.category")}</TableCell>
                    <TableCell>{t("pharmacy.medicineName")}</TableCell>
                    <TableCell align="center">
                      {t("pharmacy.quantity")}
                    </TableCell>
                    <TableCell align="center">
                      {t("pharmacy.pricePerUnit")}
                    </TableCell>
                    <TableCell align="center">
                      {t("pharmacy.totalPrice")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedReturn.items.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.medicine_category}</TableCell>
                      <TableCell>{item.medicine_name}</TableCell>
                      <TableCell align="center">{item.quantity}</TableCell>
                      <TableCell align="center">{item.price}</TableCell>
                      <TableCell align="center">{item.total_price}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : (
            <Typography>{t("pharmacy.noDetailsAvailable")}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            color="primary"
          >
            {t("pharmacy.close")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage("")}
        message={snackbarMessage}
      />

      <ConfirmDialog />
    </Box>
  );
};

export default ListReturnMedicine;
