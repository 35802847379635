import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../../../context";
import { useFetchData } from "../../../../api/api/FetchData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerStyles.css";
import AddExpenseTypeDialog from "./AddExpenseTypeDialog";
import ExpensesList from "./ExpensesList";
import { useTranslation } from "react-i18next";
import useAdd from "../../../../api/useAdd";

const buttonStyle = {
  textTransform: "capitalize",
  backgroundColor: "#071952",
  margin: "0px 10px",
};

const primaryStyle = {
  backgroundColor: "#a7e2f9",
  color: "#071952",
  textTransform: "capitalize",
  margin: "0px 5px",
  fontWeight: "bold",
  marginLeft: 2,
};

const Expenses = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const { expenseTypeData } = useFetchData();
  const [openExpenseTypeDialog, setOpenExpenseTypeDialog] = useState(false);

  const [formData, setFormData] = useState({
    amount: "",
    expenseType: "",
    description: "",
    date: new Date(),
    time: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, date });
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    if (formData.amount && formData.amount <= 0) {
      newErrors.amount = t("finance.validAmountRequired");
      isValid = false;
    }

    if (!formData.amount) {
      newErrors.amount = t("finance.amountRequired");
      isValid = false;
    }
    if (!formData.expenseType) {
      newErrors.expenseType = t("finance.expenseRequired");
      isValid = false;
    }
    if (!formData.date) {
      newErrors.date = t("finance.expenseDateRequired");
      isValid = false;
    }
    if (!formData.time) {
      newErrors.time = t("finance.expenseTimeRequired");
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const { handleAdd } = useAdd(
    "expenses",
    token,
    t("finance.addSuccess"),
    t("finance.failedAdd"),
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = new FormData();
      data.append("amount", formData.amount);
      data.append("expenseType", formData.expenseType);
      data.append("date", formData.date.toISOString().split("T")[0]);
      data.append("time", formData.time);
      data.append("description", formData.description);
      handleAdd(data);
    }
  };

  const handleOpenExpenseTypeDialog = () => {
    setOpenExpenseTypeDialog(true);
  };

  const handleCloseExpenseTypeDialog = () => {
    setOpenExpenseTypeDialog(false);
  };

  return (
    <Grid container maxWidth={"xl"} mt={2} justifyContent={"center"} mb={10}>
      <Grid item xs={12} lg={11.8} component="form" onSubmit={handleSubmit}>
        <Paper sx={{ boxShadow: "0px 0px 0px ", padding: "20px", pb: 10 }}>
          <Grid item xs={12} md={10} margin={"0px auto"}>
            <Grid
              container
              rowGap={2}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item xs={12} md={2}>
                <Typography variant="h4">{t("finance.expenses")}</Typography>
              </Grid>

              <Grid item xs={12} md={3.5}>
                <FormLabel>{t("finance.expenseAmount")}</FormLabel>
                <TextField
                  fullWidth
                  type="number"
                  name="amount"
                  size="small"
                  error={!!errors.amount}
                  helperText={errors.amount}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid container gap={2} justifyContent={"space-between"} mt={2}>
                <Grid item xs={12} md={3.5}>
                  <FormControl
                    size="small"
                    fullWidth
                    error={!!errors.expenseType}
                  >
                    <FormLabel>{t("finance.expenseType")}</FormLabel>
                    <Select
                      labelId="expense-type-label"
                      id="expense-type"
                      name="expenseType"
                      value={formData.expenseType}
                      onChange={handleInputChange}
                    >
                      {expenseTypeData.map((item, index) => (
                        <MenuItem value={item.id} key={index}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.expenseType && (
                      <FormHelperText>{errors.expenseType}</FormHelperText>
                    )}
                  </FormControl>

                  {/* Add New Expense Type Button */}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleOpenExpenseTypeDialog}
                    sx={{ display: "block", mt: 1 }}
                  >
                    {t("finance.addNewExpenseType")}
                  </Button>
                </Grid>

                {/* Other Fields */}
                <Grid item xs={12} md={3.5}>
                  <FormControl fullWidth size="small">
                    <FormLabel>{t("finance.expenseDate")}</FormLabel>
                    <DatePicker
                      selected={formData.date}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="form-control date-picker-input"
                    />
                    {errors.date && (
                      <FormHelperText>{errors.date}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={3.5}>
                  <FormControl fullWidth size="small">
                    <FormLabel>{t("finance.expenseTime")}</FormLabel>
                    <TextField
                      fullWidth
                      type="time"
                      name="time"
                      size="small"
                      error={!!errors.time}
                      helperText={errors.time}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              {/* Description Field */}
              <Grid item xs={12} mt={3}>
                <FormLabel>{t("finance.description")}</FormLabel>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  name="description"
                  size="small"
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid container mt={2} justifyContent={"end"}>
                <Button variant="contained" type="submit" style={buttonStyle}>
                  {t("finance.saveNew")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Box mt={3}>
            <Divider />
            <ExpensesList />
          </Box>
        </Paper>
      </Grid>

      {/* Add Expense Type Dialog */}
      <AddExpenseTypeDialog
        open={openExpenseTypeDialog}
        onClose={handleCloseExpenseTypeDialog}
        token={token}
      />
    </Grid>
  );
};

export default Expenses;
