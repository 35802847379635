import {
  Grid,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import { Months } from "../../../../utils";
import useDelete from "../../../../api/useDelete";
import useUpdate from "../../../../api/useUpdate";
import EditEmployeeLeaveDialog from "./EditEmployeeLeaveDialog"; // Import the new dialog component
import { useTranslation } from "react-i18next";

const EmployeeLeaveList = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [employee, setEmployee] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [currentLeave, setCurrentLeave] = useState(null);
  const [employeeData, setEmployeeData] = useState([]); // Initialized as empty array

  const { data: employeeFetchData } = useFetchObjects(
    ["employee"],
    "users/",
    token,
  );

  const { data, refetch, isLoading, isError } = useFetchObjects(
    ["employee-leave"],
    `employee-leave/?month=${month}&year=${year}&employee=${employee}`,
    token,
  );

  const { handleDelete, ConfirmDialog } = useDelete("employee-leave", token);
  const { handleUpdate } = useUpdate("employee-leave", token);

  useEffect(() => {
    refetch();
    setEmployeeData(employeeFetchData || []); // Ensure this is always an array
  }, [month, year, employee, refetch, employeeFetchData]);

  const handleEditClick = (leave) => {
    setCurrentLeave(leave);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentLeave(null);
  };

  const handleSaveChanges = (editedLeave) => {
    handleUpdate(editedLeave.id, editedLeave);
    handleCloseDialog();
  };

  return (
    <Grid container justifyContent={"center"} mb={4} pb={5}>
      <Grid item xs={12}>
        <Grid padding={"0px 25px"} backgroundColor="white" pt={2}>
          {/* Enhanced Filtering UI */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              mb: 3,
              alignItems: "center",
              padding: "20px",
              borderRadius: 2,
              boxShadow: 1,
              backgroundColor: "#f7f9fc",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "primary.main",
                mb: { xs: 2, sm: 0 },
              }}
            >
              {t("hr.filterEmployeeLeaves")}
            </Typography>

            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexWrap: "wrap",
                justifyContent: "flex-end",
              }}
            >
              <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                <InputLabel id="month-label"> {t("hr.month")}</InputLabel>
                <Select
                  size="small"
                  labelId="month-label"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  label={t("hr.month")}
                >
                  {Months.map((m) => (
                    <MenuItem key={m.id} value={m.id}>
                      {m.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                <InputLabel id="year-label">{t("hr.year")}</InputLabel>
                <Select
                  size="small"
                  labelId="year-label"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  label={t("hr.year")}
                >
                  {Array.from(
                    { length: 4 },
                    (_, index) => new Date().getFullYear() - index,
                  ).map((y) => (
                    <MenuItem key={y} value={y}>
                      {y}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                <InputLabel id="employee-label">{t("hr.employee")}</InputLabel>
                <Select
                  size="small"
                  labelId="employee-label"
                  value={employee}
                  onChange={(e) => setEmployee(e.target.value)}
                  label={t("hr.employee")}
                >
                  {employeeData?.map((emp) => (
                    <MenuItem key={emp.id} value={emp.id}>
                      {emp.first_name + " " + emp.last_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          {isLoading ? (
            <Box>{t("hr.loading")}</Box>
          ) : isError ? (
            <Box color="red">{t("hr.errorFetchingData")}</Box>
          ) : (
            <TableContainer
              sx={{ padding: "25px", boxShadow: "0px 0px 0px", pb: 4 }}
              component={Paper}
            >
              <Table
                sx={{ minWidth: 650, border: "1px solid #dbd8ce" }}
                aria-label="simple table"
              >
                <TableHead style={{ backgroundColor: "#f2f2f2" }}>
                  <TableRow style={{ fontWeight: "bold" }}>
                    <TableCell align="center"> {t("hr.month")}</TableCell>
                    <TableCell align="center"> {t("hr.year")}</TableCell>
                    <TableCell align="center"> {t("hr.employee")}</TableCell>
                    <TableCell align="center"> {t("hr.days")}</TableCell>
                    <TableCell align="center"> {t("hr.description")}</TableCell>
                    <TableCell align="center"> {t("hr.dateCreated")}</TableCell>
                    <TableCell align="center"> {t("hr.action")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((leave, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">
                        {leave.month_display}
                      </TableCell>
                      <TableCell align="center">{leave.year}</TableCell>
                      <TableCell align="center">
                        {leave.employee_display}
                      </TableCell>
                      <TableCell align="center">{leave.days}</TableCell>
                      <TableCell align="center">{leave.description}</TableCell>
                      <TableCell align="center">
                        {new Date(leave.created_at).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => handleEditClick(leave)}
                          color="primary"
                          size="small"
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDelete(leave.id)}
                          color="error"
                          size="small"
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {currentLeave && (
            <EditEmployeeLeaveDialog
              open={openDialog}
              onClose={handleCloseDialog}
              evaluation={currentLeave}
              employees={employeeData}
              onSave={handleSaveChanges}
            />
          )}
        </Grid>
      </Grid>
      <ConfirmDialog />
    </Grid>
  );
};

export default EmployeeLeaveList;
