import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import useUpdate from "../../../../api/useUpdate";
import { useTranslation } from "react-i18next";

const UpdateXrayTypeDialog = ({ open, onClose, type, token, refetch }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: type.name,
    description: type.description,
    cost: type.cost,
  });

  const { handleUpdate, loading } = useUpdate("xray-types", token); // Update to X-ray types

  const handleSubmit = () => {
    // Update the selected X-ray type
    handleUpdate(type.id, formData);
    refetch(); // Refetch the list after update
    onClose(); // Close the dialog after submission
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("x_ray.xrayUpdateType")}</DialogTitle>
      <DialogContent>
        <TextField
          label={t("x_ray.xrayTypeName")}
          fullWidth
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          sx={{ mb: 3 }}
        />
        <TextField
          label={t("x_ray.description")}
          fullWidth
          multiline
          rows={4}
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          sx={{ mb: 3 }}
        />
        <TextField
          label={t("x_ray.xrayCost")}
          fullWidth
          type="number"
          value={formData.cost}
          onChange={(e) => setFormData({ ...formData, cost: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          {t("x_ray.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={loading}
          sx={{ textTransform: "none", fontWeight: "bold", margin: "0px 10px" }}
        >
          {loading ? t("x_ray.updating") : t("x_ray.update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateXrayTypeDialog;
