import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useAdd from "../../../../../api/useAdd";
import { useAuth } from "../../../../../context";
import useFetchObjects from "../../../../../api/useFetchObjects";
import { useNavigate } from "react-router-dom";

const EmployeeAdd = () => {
  const auth = useAuth();
  const token = auth?.user?.token;
  const navigate = useNavigate();

  const [positionData, setPositionData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);

  const { data: positions, isSuccess: positionSuccess } = useFetchObjects(
    ["position"],
    "position",
    token,
  );

  const { data: departments, isSuccess: departmentSuccess } = useFetchObjects(
    ["departments"],
    "departments",
    token,
  );

  useEffect(() => {
    if (positionSuccess) {
      setPositionData(positions);
    }
  }, [positionSuccess, positions]);

  useEffect(() => {
    if (departmentSuccess) {
      setDepartmentData(departments);
    }
  }, [departmentSuccess, departments]);

  const { handleAdd, resetForm } = useAdd("users", token);

  useEffect(() => {
    if (resetForm) {
      navigate(-1);
    }
  }, [resetForm]);

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    first_name: "",
    last_name: "",
    salary_amount: "",
    contract_date: "",
    available_contract_date: "",
    department: "",
    position: "",
    pharmacy_type: "", // Added for pharmacy type selection
    password: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState({});
  const [permissions, setPermissions] = useState({
    reception: false,
    pharmacy: false,
    doctor: false,
    nurse: false,
    x_ray: false,
    ultrasounds: false,
    laboratory: false,
    echo: false,
    finance: false,
    hr: false,
    rooms: false,
    operations: false,
  });

  const [limitedDataView, setLimitedDataView] = useState(false);

  const validate = () => {
    let tempErrors = {};
    if (!formData.username) tempErrors.username = "Username is required";
    if (!formData.email) tempErrors.email = "Email is required";
    if (!formData.phone) tempErrors.phone = "Phone number is required";
    if (!formData.first_name) tempErrors.first_name = "First Name is required";
    if (!formData.last_name) tempErrors.last_name = "Last Name is required";
    if (!formData.salary_amount)
      tempErrors.salary_amount = "Salary Amount is required";
    if (!formData.contract_date)
      tempErrors.contract_date = "Contract Date is required";
    if (!formData.available_contract_date)
      tempErrors.available_contract_date =
        "Available Contract Date is required";
    if (!formData.password) tempErrors.password = "Password is required";
    if (!formData.confirm_password)
      tempErrors.confirm_password = "Confirm Password is required";
    if (formData.password && formData.password !== formData.confirm_password) {
      tempErrors.confirm_password = "Passwords do not match";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePermissionChange = (event) => {
    setPermissions({
      ...permissions,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      const data = new FormData();

      // Append form data fields
      Object.keys(formData).forEach((key) => {
        data.append(key, formData[key]);
      });

      // Append permissions and limited data view
      Object.keys(permissions).forEach((key) => {
        data.append(key, permissions[key]);
      });
      data.append("limited_data_view", limitedDataView);
      handleAdd(data);
    }
  };

  return (
    <Container>
      <Box sx={{ paddingY: 4, maxWidth: "800px", marginX: "auto" }}>
        <Typography variant="h5" fontWeight={600} align="center" gutterBottom>
          Add New Employee
        </Typography>

        <Box sx={{ marginTop: 3 }}>
          <Typography fontWeight={500} gutterBottom>
            Personal Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="dense"
                error={!!errors.username}
                helperText={errors.username}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                type="email"
                variant="outlined"
                fullWidth
                margin="dense"
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="dense"
                error={!!errors.first_name}
                helperText={errors.first_name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="dense"
                error={!!errors.last_name}
                helperText={errors.last_name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                type="number"
                variant="outlined"
                fullWidth
                margin="dense"
                error={!!errors.phone}
                helperText={errors.phone}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ marginTop: 4 }}>
          <Typography fontWeight={500} gutterBottom>
            Office Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Salary Amount"
                name="salary_amount"
                value={formData.salary_amount}
                onChange={handleChange}
                type="number"
                variant="outlined"
                fullWidth
                margin="dense"
                error={!!errors.salary_amount}
                helperText={errors.salary_amount}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Contract Date"
                name="contract_date"
                value={formData.contract_date}
                onChange={handleChange}
                type="date"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                margin="dense"
                error={!!errors.contract_date}
                helperText={errors.contract_date}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Available Contract Date"
                name="available_contract_date"
                value={formData.available_contract_date}
                onChange={handleChange}
                type="date"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                margin="dense"
                error={!!errors.available_contract_date}
                helperText={errors.available_contract_date}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Position"
                name="position"
                value={formData.position}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="dense"
              >
                {positionData.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Department"
                name="department"
                value={formData.department}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="dense"
              >
                {departmentData.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Pharmacy Type"
                name="pharmacy_type"
                value={formData.pharmacy_type}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="dense"
              >
                <MenuItem value={1}>Pharmacy 1</MenuItem>
                <MenuItem value={2}>Pharmacy 2</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ marginTop: 4 }}>
          <Typography fontWeight={500} gutterBottom>
            Password Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                type="password"
                variant="outlined"
                fullWidth
                margin="dense"
                error={!!errors.password}
                helperText={errors.password}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Confirm Password"
                name="confirm_password"
                value={formData.confirm_password}
                onChange={handleChange}
                type="password"
                variant="outlined"
                fullWidth
                margin="dense"
                error={!!errors.confirm_password}
                helperText={errors.confirm_password}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ marginTop: 4 }}>
          <Typography fontWeight={500} gutterBottom>
            Permissions
          </Typography>
          <Grid container spacing={2}>
            {Object.keys(permissions).map((permission) => (
              <Grid item xs={6} sm={4} md={3} key={permission}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions[permission]}
                      onChange={handlePermissionChange}
                      name={permission}
                    />
                  }
                  label={
                    permission.charAt(0).toUpperCase() +
                    permission.slice(1).replace("_", " ")
                  }
                />
              </Grid>
            ))}
            <Grid item xs={6} sm={4} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={limitedDataView}
                    onChange={(e) => setLimitedDataView(e.target.checked)}
                    name="limitedDataView"
                  />
                }
                label="Limited Data View"
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Add Employee
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default EmployeeAdd;
