import {
  Grid,
  Paper,
  Tabs,
  Tab,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import useUpdate from "../../../../api/useUpdate";
import NoRecordMessage from "../../../../Components/GeneralComponents/noRecordMessage/NoRecordMessage";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const getButtonColor = (status) => {
  switch (status) {
    case 1:
      return "#FFC107";
    case 2:
      return "#4CAF50";
    case 3:
      return "#F44336";
    default:
      return "#a7e2f9";
  }
};

const Appointments = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [status, setStatus] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const open = Boolean(anchorEl);
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const { handleUpdate } = useUpdate("appointments", token);

  const today = dayjs().format("YYYY-MM-DD");

  const { data, refetch } = useFetchObjects(
    ["AppointmentsList"],
    `appointments/?status=${status}&doctor=${user?.user.id}&dateOfVisit=${today}`,
    token,
  );

  useEffect(() => {
    refetch();
  }, [status]);

  const handleStatusChange = (event, newValue) => {
    setValue(newValue);
    setStatus(newValue === 0 ? 1 : newValue === 1 ? 2 : 3);
  };

  const handleClick = (event, appointment) => {
    setAnchorEl(event.currentTarget);
    setSelectedAppointment(appointment);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedAppointment(null);
  };

  const handleAppointmentStatusChange = (newStatus) => {
    const formData = new FormData();
    formData.append("status", newStatus);
    handleUpdate(selectedAppointment.id, formData);
    handleClose();
  };

  return (
    <Grid item xs={12}>
      <Paper sx={{ boxShadow: "0px 0px 0px" }}>
        <Grid container padding={"10px"}>
          <Grid item>
            <Tabs
              value={value}
              onChange={handleStatusChange}
              aria-label="appointment status tabs"
            >
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={t("doctor.toBeChecked")}
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={t("doctor.checked")}
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={t("doctor.canceled")}
              />
            </Tabs>
          </Grid>
          <Divider sx={{ mt: 2, width: "100%" }} />

          <TableContainer sx={{ mt: 2 }}>
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#f2f2f2" }}>
                <TableRow>
                  <TableCell align="center">{t("doctor.time")}</TableCell>
                  <TableCell align="center">{t("doctor.date")}</TableCell>
                  <TableCell align="center">
                    {t("doctor.patientName")}
                  </TableCell>
                  <TableCell align="center">{t("doctor.action")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.results.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} style={{ textAlign: "center" }}>
                      <NoRecordMessage message={t("doctor.noRecord")} />
                    </TableCell>
                  </TableRow>
                ) : (
                  data?.results.map((item, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">{item.timeOfVisit}</TableCell>
                      <TableCell align="center">{item.dateOfVisit}</TableCell>
                      <TableCell align="center">
                        {item.patient_name_display}
                      </TableCell>
                      {item.status === 1 && (
                        <TableCell align="center">
                          <Button
                            id="appointment-action-button"
                            aria-controls={
                              open ? "appointment-action-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={(event) => handleClick(event, item)}
                          >
                            <MoreHorizIcon />
                          </Button>
                          <Menu
                            id="appointment-action-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            PaperProps={{
                              sx: {
                                boxShadow: "0px 20px 10px rgba(0, 0, 0, 0.02)",
                                border: "1px solid lightgray",
                                borderRadius: "1em",
                                padding: "10px",
                              },
                            }}
                          >
                            <Button
                              variant="none"
                              onClick={handleClose}
                              style={{
                                position: "absolute",
                                top: "0.01em",
                                right: "0.01em",
                              }}
                            >
                              <CancelTwoToneIcon style={{ color: "#a7e2f9" }} />
                            </Button>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                color: "#081c54",
                                margin: "1em",
                              }}
                            >
                              {t("doctor.manageAppointment")}
                            </Typography>
                            <MenuItem
                              sx={{
                                color: getButtonColor(2),
                                border: `2px solid ${getButtonColor(2)}`,
                                borderRadius: "0.5em",
                                margin: "1em 0",
                              }}
                              onClick={() => handleAppointmentStatusChange(2)}
                            >
                              {t("doctor.markAsChecked")}
                            </MenuItem>
                            <MenuItem
                              sx={{
                                color: getButtonColor(3),
                                border: `2px solid ${getButtonColor(3)}`,
                                borderRadius: "0.5em",
                              }}
                              onClick={() => handleAppointmentStatusChange(3)}
                            >
                              {t("doctor.markAsCancelled")}
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Appointments;
