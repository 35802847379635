import { Box, Grid, Paper, Tabs, Tab } from "@mui/material";
import React, { useState, useEffect, useTransition } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const tabStyle = {
  textTransform: "capitalize",
  fontWeight: "bold",
  borderRadius: "10px",
};

const PatientProfileNav = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("/patient-profile");
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    const path = location.pathname;
    const validPaths = [
      "/patient-profile",
      "/patient-profile/history",
      "/patient-profile/prescriptions",
    ];

    if (validPaths.includes(path)) {
      setValue(path);
    } else {
      setValue("/patient-profile");
    }
  }, [location.pathname]);

  return (
    <Grid container maxWidth="xl">
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Paper sx={{ padding: "10px 0px", boxShadow: "0px 0px 0px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{ ml: "5px", "& .MuiTabs-indicator": { display: "none" } }}
          >
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/patient-profile" ? "#a7e2f9" : "white",
              }}
              label={t("profile.dashboard")}
              value="/patient-profile"
              component={NavLink}
              to="/patient-profile"
            />
          </Tabs>
        </Paper>
      </Box>
      <Outlet />
    </Grid>
  );
};

export default PatientProfileNav;
