// src/routes/receptionRoutes.js
import RecepionNav from "../../pages/Dashboard/Reception/RecepionNav";
import ReceptionIndex from "../../pages/Dashboard/Reception/ReceptionIndex";
import NewPatient from "../../pages/Dashboard/Reception/NewPatient/NewPatient";
import PatientList from "../../pages/Dashboard/Reception/PatientList/PatientList";
import Appointments2 from "../../pages/Dashboard/Reception/Appointments2/Appointments2";
import CollectPrescriptionFees from "../../pages/Dashboard/Reception/CollectPrescriptionFees/CollectPrescriptionFees";
import CollectMedicineOrderFees from "../../pages/Dashboard/Reception/CollectMedicineOrderFees.js/CollectMedicineOrderFees";
import UltrasoundReferral from "../../pages/Dashboard/Reception/ultrasoundCollectFees/UltrasoundReferral";
import XRayReferral from "../../pages/Dashboard/Reception/xrayCollectFees/XRayReferral";
import EchoReferral from "../../pages/Dashboard/Reception/echoCollectFees/EchoReferral";
import PatientTest from "../../pages/Dashboard/Reception/CollectLaboratoryFees/PatientTest";

const receptionRoutes = {
  path: "/reception",
  element: <RecepionNav />,
  children: [
    { path: "/reception", element: <ReceptionIndex /> },
    { path: "/reception/new-patient", element: <NewPatient /> },
    { path: "/reception/patient-list", element: <PatientList /> },
    { path: "/reception/appointments", element: <Appointments2 /> },
    {
      path: "/reception/collect-prescription-fees",
      element: <CollectPrescriptionFees />,
    },
    {
      path: "/reception/collect-medicine-order-fees",
      element: <CollectMedicineOrderFees />,
    },
    {
      path: "/reception/collect-xray-fees",
      element: <XRayReferral />,
    },
    {
      path: "/reception/collect-ultrasound-fees",
      element: <UltrasoundReferral />,
    },
    {
      path: "/reception/collect-laboratory-fees",
      element: <PatientTest />,
    },
    {
      path: "/reception/collect-echo-fees",
      element: <EchoReferral />,
    },
  ],
};

export default receptionRoutes;
