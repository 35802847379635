import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { alpha, useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";

import { bgGradient } from "../../../theme/css";

import Iconify from "../../../Components/iconify/iconify";

import { useAuth } from "../../../context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAdd from "../../../api/useAdd";

const SignInView = () => {
  const { t } = useTranslation();
  const authLogin = useAuth();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const { handleAdd, loading, resetForm, responseData } = useAdd(
    "login",
    null,
    "Welcome Again!",
  );

  useEffect(() => {
    if (resetForm) {
      authLogin.login(responseData);
      navigate("/");
    }
  }, [resetForm]);

  const handleSignIn = (e) => {
    e.preventDefault();
    let isValid = true;

    if (!username) {
      setUsernameError(t("sign_in.userNameRequired"));
      isValid = false;
    } else {
      setUsernameError("");
      isValid = true;
    }

    if (!password) {
      setPasswordError(t("sign_in.passwordRequired"));
      isValid = false;
    } else {
      setPasswordError("");
      isValid = true;
    }

    if (isValid) {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      handleAdd(formData);
    }
  };

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: `${process.env.REACT_APP_MEDIA_URL}/images/background/overlay.jpg`,
        }),
        height: "100vh",
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: 1 }}
        component={"form"}
        onSubmit={handleSignIn}
      >
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Typography variant="h4" textAlign={"center"}>
            {t("sign_in.signIn")}
          </Typography>

          <Typography
            variant="body2"
            sx={{ mt: 2, mb: 5, textAlign: "center" }}
          >
            {t("sign_in.dontHaveAccount")}
            <Link variant="subtitle2" sx={{ ml: 0.5 }}>
              {t("sign_in.getStarted")}
            </Link>
          </Typography>

          <Stack direction="row" spacing={2}>
            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
              sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
            >
              <Iconify icon="eva:google-fill" color="#DF3E30" />
            </Button>

            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
              sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
            >
              <Iconify icon="eva:facebook-fill" color="#1877F2" />
            </Button>

            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
              sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
            >
              <Iconify icon="eva:twitter-fill" color="#1C9CEA" />
            </Button>
          </Stack>

          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {t("sign_in.or")}
            </Typography>
          </Divider>

          <Stack spacing={3}>
            <TextField
              autoFocus
              type="username"
              name="username"
              label={t("sign_in.username")}
              error={!!usernameError}
              helperText={usernameError}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="off" // Disable autocomplete
            />

            <TextField
              name="password"
              label={t("sign_in.password")}
              error={!!passwordError}
              helperText={passwordError}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="off" // Disable autocomplete
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ my: 3 }}
          >
            <Link variant="subtitle2" underline="hover">
              {t("sign_in.forgotPassword")}
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="warning"
            loading={loading}
          >
            {t("sign_in.login")}
          </LoadingButton>
        </Card>
      </Stack>
    </Box>
  );
};

export default SignInView;
