import { Box, Grid, Paper, Tabs, Tab } from "@mui/material";
import React, { useState, useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const tabStyle = {
  textTransform: "capitalize",
  fontWeight: "bold",
  borderRadius: "10px",
};

const HumanResourceNav = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("/human-resource");
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    const path = location.pathname;
    const validPaths = [
      "/human-resource",
      "/human-resource/departments",
      "/human-resource/positions",
      "/human-resource/employees",
      "/human-resource/attendance",
      "/human-resource/employee-leave",
      "/human-resource/evaluation",
      "/human-resource/backup",
    ];

    if (validPaths.includes(path)) {
      setValue(path);
    } else {
      setValue("/human-resource");
    }
  }, [location.pathname]);

  return (
    <Grid container maxWidth="xl">
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Paper sx={{ padding: "10px 0px", boxShadow: "0px 0px 0px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              ml: "5px",
              "& .MuiTabs-indicator": { display: "none" },
              "& .MuiTab-root": { minWidth: "120px" },
            }}
          >
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/human-resource" ? "#007174" : "white",
                color: value === "/human-resource" ? "white" : "black",
              }}
              label={t("hr.dashboard")}
              value="/human-resource"
              component={NavLink}
              to="/human-resource"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/human-resource/departments" ? "#007174" : "white",
                color:
                  value === "/human-resource/departments" ? "white" : "black",
              }}
              label={t("hr.departments")}
              value="/human-resource/departments"
              component={NavLink}
              to="/human-resource/departments"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/human-resource/positions" ? "#007174" : "white",
                color:
                  value === "/human-resource/positions" ? "white" : "black",
              }}
              label={t("hr.positions")}
              value="/human-resource/positions"
              component={NavLink}
              to="/human-resource/positions"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/human-resource/employees" ? "#007174" : "white",
                color:
                  value === "/human-resource/employees" ? "white" : "black",
              }}
              label={t("hr.employees")}
              value="/human-resource/employees"
              component={NavLink}
              to="/human-resource/employees"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/human-resource/attendance" ? "#007174" : "white",
                color:
                  value === "/human-resource/attendance" ? "white" : "black",
              }}
              label={t("hr.attendance")}
              value="/human-resource/attendance"
              component={NavLink}
              to="/human-resource/attendance"
            />

            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/human-resource/employee-leave"
                    ? "#007174"
                    : "white",
                color:
                  value === "/human-resource/employee-leave"
                    ? "white"
                    : "black",
              }}
              label={t("hr.employee_leave")}
              value="/human-resource/employee-leave"
              component={NavLink}
              to="/human-resource/employee-leave"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/human-resource/evaluation" ? "#007174" : "white",
                color:
                  value === "/human-resource/evaluation" ? "white" : "black",
              }}
              label={t("hr.evaluation")}
              value="/human-resource/evaluation"
              component={NavLink}
              to="/human-resource/evaluation"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/human-resource/backup" ? "#007174" : "white",
                color: value === "/human-resource/backup" ? "white" : "black",
              }}
              label={t("hr.backup")}
              value="/human-resource/backup"
              component={NavLink}
              to="/human-resource/backup"
            />
          </Tabs>
        </Paper>
      </Box>
      <Outlet />
    </Grid>
  );
};

export default HumanResourceNav;
