import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Divider,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Stack,
} from "@mui/material";
import { RemoveCircleOutline, Search } from "@mui/icons-material";
import useAdd from "../../../../../api/useAdd";
import useFetchObjects from "../../../../../api/useFetchObjects";
import { useAuth } from "../../../../../context";
import { useTranslation } from "react-i18next";
import ListReturnMedicine from "./ListReturnMedicine";

const AddReturnMedicine = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const pharmacy = auth?.user?.user.pharmacy_type || "";

  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const autocompleteRef = useRef(null);

  const { data: medicinesData } = useFetchObjects(
    ["stock-medicines"],
    `stock-medicines/?pharmacy=${pharmacy}`,
    token
  );

  const [formErrors, setFormErrors] = useState({});
  const [input, setInput] = useState({
    items: [],
    total_amount: 0,
    pharmacy: pharmacy,
  });

  const { handleAdd, resetForm, loading } = useAdd(
    "return-medicines",
    token,
    t("pharmacy.returnAddedSuccess"),
    t("pharmacy.returnAddFailed")
  );

  useEffect(() => {
    if (resetForm) {
      setInput({
        items: [],
        total_amount: 0,
      });
      setFormErrors({});
    }
  }, [resetForm]);

  const handleAddNewItem = (medicine) => {
    if (medicine) {
      setInput((prevInput) => {
        const newItems = [
          ...prevInput.items,
          {
            stock_medicine: medicine.id,
            name: medicine.medicine_display,
            quantity: 1,
            price: parseFloat(medicine.sell_price).toFixed(2),
            total_price: parseFloat(medicine.sell_price).toFixed(2),
          },
        ];
        return {
          ...prevInput,
          items: newItems,
          total_amount: calculateTotalAmount(newItems),
        };
      });

      setAutocompleteInputValue("");
      if (autocompleteRef.current) {
        autocompleteRef.current.focus();
      }
    }
  };

  const handleChangeItemField = (index, e) => {
    const { name, value } = e.target;
    const items = [...input.items];

    if (name === "quantity" || name === "price") {
      items[index][name] = value ? parseFloat(value) : "";
      items[index].total_price = (
        parseFloat(items[index].price || 0) *
        parseFloat(items[index].quantity || 0)
      ).toFixed(2);
    }

    setInput({ ...input, items, total_amount: calculateTotalAmount(items) });
  };

  const handleRemoveItem = (index) => {
    const items = [...input.items];
    items.splice(index, 1);
    setInput({ ...input, items, total_amount: calculateTotalAmount(items) });
  };

  const calculateTotalAmount = (items) => {
    return items
      .reduce((sum, item) => sum + parseFloat(item.total_price || 0), 0)
      .toFixed(2);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (input.items.length === 0) {
      setFormErrors({ items: t("pharmacy.itemsRequired") });
      return;
    }

    const success = await handleAdd(input);
    if (success) {
      setInput({ items: [], total_amount: 0 });
      setFormErrors({});
    }
  };

  return (
    <>
      <Grid container justifyContent="center" mt={2} mb={5}>
        <Grid item xs={11} md={8}>
          <Box component="form" padding="30px" onSubmit={handleSave}>
            <Typography variant="h4" fontWeight="bold" mb={1}>
              {t("pharmacy.addReturnMedicine")}
            </Typography>
            <Divider sx={{ mb: 3 }} />

            {/* Add Medicine Section */}
            <Box mb={4}>
              <Typography variant="subtitle1" fontWeight={500} mb={2}>
                {t("pharmacy.addMedicine")}
              </Typography>
              <Autocomplete
                ref={autocompleteRef}
                value={null}
                inputValue={autocompleteInputValue}
                onInputChange={(event, newInputValue) =>
                  setAutocompleteInputValue(newInputValue)
                }
                onChange={(event, value) => handleAddNewItem(value)}
                options={
                  medicinesData
                    ? medicinesData.filter((medicine) =>
                        medicine.medicine_display
                          .toLowerCase()
                          .startsWith(autocompleteInputValue.toLowerCase())
                      )
                    : []
                }
                getOptionLabel={(option) =>
                  `${option.medicine_display} (${option.category_display})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("pharmacy.searchMedicine")}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: 2,
                    }}
                  />
                )}
              />
            </Box>

            {/* Items Table */}
            <TableContainer component={Paper} elevation={1} sx={{ mb: 4 }}>
              <Table>
                <TableHead sx={{ backgroundColor: "#eef2f5" }}>
                  <TableRow>
                    <TableCell>{t("pharmacy.medicineName")}</TableCell>
                    <TableCell align="center">
                      {t("pharmacy.quantity")}
                    </TableCell>
                    <TableCell align="center">{t("pharmacy.price")}</TableCell>
                    <TableCell align="center">
                      {t("pharmacy.totalPrice")}
                    </TableCell>
                    <TableCell align="center">{t("pharmacy.action")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {input.items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell align="center">
                        <TextField
                          name="quantity"
                          type="number"
                          size="small"
                          value={item.quantity}
                          onChange={(e) => handleChangeItemField(index, e)}
                          sx={{ backgroundColor: "#fff", borderRadius: 2 }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          name="price"
                          type="number"
                          size="small"
                          value={item.price}
                          onChange={(e) => handleChangeItemField(index, e)}
                          sx={{ backgroundColor: "#fff", borderRadius: 2 }}
                        />
                      </TableCell>
                      <TableCell align="center">{item.total_price}</TableCell>
                      <TableCell align="center">
                        <Tooltip title={t("pharmacy.removeItem")} arrow>
                          <IconButton
                            color="error"
                            onClick={() => handleRemoveItem(index)}
                          >
                            <RemoveCircleOutline />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Save Button */}
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Typography variant="h6" fontWeight={600}>
                {t("pharmacy.totalAmount")}: {input.total_amount}
              </Typography>
              <Button
                size="small"
                onClick={handleSave}
                variant="contained"
                disabled={loading}
                sx={{
                  fontWeight: 700,
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.dark",
                  },
                }}
              >
                {loading ? t("pharmacy.saving") : t("pharmacy.save")}
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid xs={12}>
          <Divider />
          <ListReturnMedicine />
        </Grid>
      </Grid>
    </>
  );
};

export default AddReturnMedicine;
