import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
  Paper,
  Divider,
  Alert,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../context";
import useAdd from "../../../../api/useAdd";
import useFetchObjects from "../../../../api/useFetchObjects";
import ListSurgeryEchoPatient from "./ListSurgeryEchoPatient";

const FullWidthPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#ffffff",
  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 700,
  color: "#3a3a3a",
  textAlign: "left",
  marginBottom: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  fontWeight: 700,
  fontSize: "1rem",
  padding: "12px 16px",
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const EchoSurgeryPatient = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const { data: echoTypesData } = useFetchObjects(
    ["echo-types"],
    "echo-types/",
    token
  );
  const { data: surgeries } = useFetchObjects(
    ["surgeries"],
    "surgeries/",
    token
  );
  const { data: doctors } = useFetchObjects(["doctors"], "users/", token);

  const { handleAdd, resetForm } = useAdd("surgery-echo-patient", token);

  const echoTypes = echoTypesData || [];
  const [echoDetails, setEchoDetails] = useState({
    surgery: null,
    echoType: "",
    doctor: null,
    testDate: new Date().toISOString().split("T")[0],
    isUrgent: false,
    totalAmount: "",
  });

  const [errors, setErrors] = useState({});
  const [echoPercentage, setEchoPercentage] = useState(0);
  const [existingEchoAmount, setExistingEchoAmount] = useState(0);
  const [isOverLimit, setIsOverLimit] = useState(false);
  const [overLimitAmount, setOverLimitAmount] = useState(0);

  useEffect(() => {
    if (resetForm) {
      setEchoDetails({
        surgery: null,
        echoType: "",
        doctor: null,
        testDate: new Date().toISOString().split("T")[0],
        isUrgent: false,
        totalAmount: "",
      });
      setErrors({});
      setEchoPercentage(0);
      setExistingEchoAmount(0);
      setIsOverLimit(false);
      setOverLimitAmount(0);
    }
  }, [resetForm]);

  useEffect(() => {
    if (echoDetails.surgery && surgeries) {
      const selectedSurgery = surgeries.find(
        (surgery) => surgery.id === echoDetails.surgery
      );
      if (selectedSurgery) {
        setEchoPercentage(selectedSurgery.echo_percentage || 0);
        setExistingEchoAmount(selectedSurgery.total_echo_amount || 0);
      }
    } else {
      setEchoPercentage(0);
      setExistingEchoAmount(0);
    }
  }, [echoDetails.surgery, surgeries]);

  useEffect(() => {
    const totalCost = parseFloat(echoDetails.totalAmount || 0);
    const newTotal = existingEchoAmount + totalCost;
    if (newTotal > echoPercentage) {
      setIsOverLimit(true);
      setOverLimitAmount(newTotal - echoPercentage);
    } else {
      setIsOverLimit(false);
      setOverLimitAmount(0);
    }
  }, [echoDetails.totalAmount, existingEchoAmount, echoPercentage]);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setEchoDetails((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "echoType") {
      const selectedType = echoTypes.find(
        (type) => type.id.toString() === value
      );
      setEchoDetails((prev) => ({
        ...prev,
        totalAmount: selectedType ? selectedType.cost.toString() : "",
      }));
    }

    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!echoDetails.surgery) tempErrors.surgery = t("doctor.surgeryRequired");
    if (!echoDetails.echoType)
      tempErrors.echoType = t("doctor.echoTypeRequired");
    if (!echoDetails.doctor) tempErrors.doctor = t("doctor.doctorRequired");
    if (!echoDetails.testDate)
      tempErrors.testDate = t("doctor.testDateRequired");
    if (!echoDetails.totalAmount)
      tempErrors.totalAmount = t("doctor.totalAmountRequired");
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const payload = {
        patient: echoDetails.surgery,
        echo_type: echoDetails.echoType,
        doctor: echoDetails.doctor,
        date: echoDetails.testDate,
        is_urgent: echoDetails.isUrgent,
        total_amount: echoDetails.totalAmount,
      };
      handleAdd(payload);
    }
  };

  return (
    <FullWidthPaper>
      <SectionTitle>{t("doctor.echoReferralForm")}</SectionTitle>
      <Grid container spacing={3}>
        {/* Surgery Selection */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={surgeries || []}
            getOptionLabel={(option) =>
              `${option.patient_display} (${t("doctor.surgeryId")}: ${
                option.id
              })`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("doctor.selectSurgery")}
                variant="outlined"
                fullWidth
                error={!!errors.surgery}
                helperText={errors.surgery}
              />
            )}
            value={
              surgeries?.find(
                (surgery) => surgery.id === echoDetails.surgery
              ) || null
            }
            onChange={(event, newValue) => {
              setEchoDetails((prev) => ({
                ...prev,
                surgery: newValue ? newValue.id : null,
              }));
              setErrors((prev) => ({ ...prev, surgery: "" }));
            }}
          />
        </Grid>

        {/* Doctor Selection */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={doctors || []}
            getOptionLabel={(option) =>
              `${option.first_name || t("doctor.unknown")} ${
                option.last_name || ""
              }`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("doctor.selectDoctor")}
                variant="outlined"
                fullWidth
                error={!!errors.doctor}
                helperText={errors.doctor}
              />
            )}
            value={
              doctors?.find((doctor) => doctor.id === echoDetails.doctor) ||
              null
            }
            onChange={(event, newValue) => {
              setEchoDetails((prev) => ({
                ...prev,
                doctor: newValue ? newValue.id : null,
              }));
              setErrors((prev) => ({ ...prev, doctor: "" }));
            }}
          />
        </Grid>

        {/* Echo Type */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>{t("doctor.echoType")}</InputLabel>
            <Select
              name="echoType"
              value={echoDetails.echoType}
              onChange={handleChange}
              displayEmpty
              error={!!errors.echoType}
            >
              {echoTypes.map((type) => (
                <MenuItem key={type.id} value={type.id.toString()}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
            {errors.echoType && (
              <Typography color="error" variant="caption">
                {errors.echoType}
              </Typography>
            )}
          </FormControl>
        </Grid>

        {/* Test Date */}
        <Grid item xs={12} sm={6}>
          <TextField
            name="testDate"
            label={t("doctor.testDate")}
            type="date"
            fullWidth
            value={echoDetails.testDate}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            error={!!errors.testDate}
            helperText={errors.testDate}
          />
        </Grid>

        {/* Total Amount */}
        <Grid item xs={12} sm={6}>
          <TextField
            name="totalAmount"
            label={t("doctor.totalAmount")}
            type="number"
            fullWidth
            value={echoDetails.totalAmount}
            onChange={handleChange}
            error={!!errors.totalAmount}
            helperText={errors.totalAmount}
          />
        </Grid>

        {/* Urgent Switch */}
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={echoDetails.isUrgent}
                onChange={handleChange}
                name="isUrgent"
              />
            }
            label={t("doctor.urgent")}
          />
        </Grid>

        {/* Warning if Over Limit */}
        {isOverLimit && (
          <Grid item xs={12}>
            <Alert severity="warning">
              {t("doctor.totalCostExceedsEchoLimit", {
                high_amount:
                  existingEchoAmount + parseFloat(echoDetails.totalAmount || 0),
                echo_percentage: echoPercentage,
              })}
            </Alert>
          </Grid>
        )}

        {/* Submit Button */}
        <Grid item xs={12}>
          <StyledButton fullWidth onClick={handleSubmit}>
            {t("doctor.submit")}
          </StyledButton>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 4 }} />

      <Box sx={{ mt: 3 }}>
        <ListSurgeryEchoPatient />
      </Box>
    </FullWidthPaper>
  );
};

export default EchoSurgeryPatient;
