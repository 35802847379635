import {
  Divider,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import { useTranslation } from "react-i18next";

const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const Appointments = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const [date] = useState(getTodayDate());

  const {
    data = [],
    isLoading,
    isError,
  } = useFetchObjects(
    ["prescriptions", date],
    `prescriptions/?date=${date}`,
    token
  );

  return (
    <Grid item xs={12}>
      <Paper sx={{ boxShadow: "0px 0px 0px" }}>
        <Grid container padding={"20px"}>
          <Grid container justifyContent={"space-between"}>
            <Typography variant="h5">{t("pharmacy.todayCustomer")}</Typography>
          </Grid>
          <Divider sx={{ mt: 1, width: "100%" }} />

          {isLoading ? (
            <Box>{t("pharmacy.loading")}</Box>
          ) : isError ? (
            <Box color="red">{t("pharmacy.errorLoading")}</Box>
          ) : (
            <TableContainer sx={{ mt: 4 }}>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableHead style={{ backgroundColor: "#f2f2f2" }}>
                  <TableRow>
                    <TableCell align="center">{t("pharmacy.time")}</TableCell>
                    <TableCell align="center">{t("pharmacy.date")}</TableCell>
                    <TableCell align="center">
                      {t("pharmacy.patientName")}
                    </TableCell>
                    <TableCell align="center">
                      {t("pharmacy.patientAge")}
                    </TableCell>
                    <TableCell align="center">{t("pharmacy.doctor")}</TableCell>
                    <TableCell align="center">{t("pharmacy.due")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((appointment, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {appointment.time}
                      </TableCell>
                      <TableCell align="center">{appointment.date}</TableCell>
                      <TableCell align="center" component="th">
                        {appointment.patient_display}
                      </TableCell>
                      <TableCell align="center">
                        {appointment.patient_age_display}
                      </TableCell>
                      <TableCell align="center">
                        {appointment.doctor_display}
                      </TableCell>
                      <TableCell align="center">
                        {appointment.total_price}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Appointments;
