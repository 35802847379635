import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import useAdd from "../../../../api/useAdd"; // Hook to handle adding data
import { useAuth } from "../../../../context";
import ListUltrasoundTypes from "./ListUltrasoundTypes"; // Import the new list component
import { useTranslation } from "react-i18next";

const UltrasoundType = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    cost: "",
  });

  const [errors, setErrors] = useState({}); // To store validation errors

  const auth = useAuth();
  const token = auth?.user?.token;

  const { handleAdd, loading } = useAdd("ultrasound-types", token);

  // Validation function
  const validate = () => {
    let newErrors = {};

    // Validate name
    if (!formData.name) {
      newErrors.name = t("ultrasound.ultrasoundTypeRequired");
    }

    // Validate cost
    if (formData.cost === "") {
      newErrors.cost = t("ultrasound.costRequired");
    } else if (Number(formData.cost) <= 0) {
      newErrors.cost = t("ultrasound.costPositiveNumber");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // If no errors, return true
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleAdd(formData); // Submit the form data to the API
      setFormData({
        name: "",
        description: "",
        cost: "",
      }); // Clear form after successful submission
      setErrors({}); // Clear errors
    }
  };

  return (
    <Grid container maxWidth={"xl"} mt={2} justifyContent={"center"} mb={10}>
      <Grid item xs={12} lg={8}>
        <Paper
          component="form"
          onSubmit={handleSubmit}
          sx={{
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Add shadow for a clean appearance
            padding: "30px 40px", // Add padding for internal spacing
            borderRadius: "8px", // Rounded corners for better UI aesthetics
            mb: 5,
          }}
        >
          <Typography variant="h5" gutterBottom>
            {t("ultrasound.addNewUltType")}
          </Typography>

          {/* Ultrasound Type Name Input */}
          <TextField
            label={t("ultrasound.ultTypeName")}
            fullWidth
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            sx={{ mb: 3 }}
            error={!!errors.name} // Display error
            helperText={errors.name} // Show error message
          />

          {/* Description Input */}
          <TextField
            label={t("ultrasound.description")}
            fullWidth
            multiline
            rows={4}
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
            sx={{ mb: 3 }}
          />

          {/* Cost Input */}
          <TextField
            label={t("ultrasound.cost")}
            fullWidth
            type="number"
            value={formData.cost}
            onChange={(e) => setFormData({ ...formData, cost: e.target.value })}
            sx={{ mb: 3 }}
            error={!!errors.cost} // Display error
            helperText={errors.cost} // Show error message
          />

          {/* Submit Button */}
          <Button
            variant="contained"
            disabled={loading}
            type="submit"
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              padding: "10px 20px",
              backgroundColor: "#071952",
              ":hover": {
                backgroundColor: "#071952",
              },
            }}
          >
            {loading ? t("ultrasound.saving") : t("ultrasound.addType")}
          </Button>
        </Paper>

        {/* Divider between the form and the list */}
        <Divider sx={{ marginY: 4 }} />

        {/* List ultrasound types */}
        <ListUltrasoundTypes />
      </Grid>
    </Grid>
  );
};

export default UltrasoundType;
