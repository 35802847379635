import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import useUpdate from "../../../../api/useUpdate";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";

const PurchaseDrugEdit = ({ purchaseItem, onClose, token }) => {
  const { t } = useTranslation();
  const { data: medicines, isLoading: loadingMedicines } = useFetchObjects(
    ["medicine"],
    "medicines",
    token,
  );

  const [input, setInput] = useState({
    medicine: purchaseItem?.medicine || "",
    quantity: purchaseItem?.quantity || "",
    purchase_price: purchaseItem?.purchase_price || "",
    sell_price: purchaseItem?.sell_price || "",
    expiry_date: purchaseItem?.expiry_date || "",
  });

  const [formErrors, setFormErrors] = useState({});
  const { handleUpdate, isLoading: updating } = useUpdate(
    "purchase-items",
    token,
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    let isValid = true;

    if (!input.medicine) {
      errors.medicine = t("pharmacy.medicineRequired");
      isValid = false;
    }

    if (!input.quantity) {
      errors.quantity = t("pharmacy.quantityRequired");
      isValid = false;
    }

    if (!input.purchase_price) {
      errors.purchase_price = t("pharmacy.purchasePriceRequired");
      isValid = false;
    }

    if (!input.sell_price) {
      errors.sell_price = t("pharmacy.sellPriceRequired");
      isValid = false;
    }

    if (!input.expiry_date) {
      errors.expiry_date = t("pharmacy.expiryDateRequired");
      isValid = false;
    }

    setFormErrors(errors);

    if (isValid) {
      const formData = new FormData();
      formData.append("medicine", input.medicine);
      formData.append("quantity", input.quantity);
      formData.append("purchase_price", input.purchase_price);
      formData.append("sell_price", input.sell_price);
      formData.append("expiry_date", input.expiry_date);

      handleUpdate(purchaseItem.id, formData);
      onClose();
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
      {loadingMedicines ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ py: 3 }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TextField
            select
            fullWidth
            label={t("pharmacy.medicine")}
            name="medicine"
            size="small"
            margin="normal"
            value={input.medicine}
            onChange={handleChange}
            error={!!formErrors.medicine}
            helperText={formErrors.medicine}
            disabled={loadingMedicines}
          >
            {medicines?.map((medicine) => (
              <MenuItem key={medicine.id} value={medicine.id}>
                {medicine.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            label={t("pharmacy.quantity")}
            name="quantity"
            type="number"
            margin="normal"
            size="small"
            error={!!formErrors.quantity}
            helperText={formErrors.quantity}
            value={input.quantity}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            label={t("pharmacy.purchasePrice")}
            name="purchase_price"
            type="number"
            margin="normal"
            size="small"
            error={!!formErrors.purchase_price}
            helperText={formErrors.purchase_price}
            value={input.purchase_price}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            label={t("pharmacy.sellPrice")}
            name="sell_price"
            type="number"
            margin="normal"
            size="small"
            error={!!formErrors.sell_price}
            helperText={formErrors.sell_price}
            value={input.sell_price}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            name="expiry_date"
            type="date"
            label={t("pharmacy.expiryDate")}
            margin="normal"
            size="small"
            InputLabelProps={{ shrink: true }}
            error={!!formErrors.expiry_date}
            helperText={formErrors.expiry_date}
            value={input.expiry_date}
            onChange={handleChange}
          />

          <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
            <Button onClick={onClose} variant="outlined" size="small">
              {t("pharmacy.cancel")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="small"
              sx={{ backgroundColor: "green", color: "white" }}
              disabled={updating}
            >
              {updating ? <CircularProgress size={20} /> : t("pharmacy.save")}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default PurchaseDrugEdit;
