import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Paper,
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  CircularProgress,
  Alert,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import useDelete from "../../../../api/useDelete";
import UpdateEchoResultDialog from "./UpdateEchoResultDialog"; // Import the dialog component
import { useTranslation } from "react-i18next";

const ListEchoResult = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedResult, setSelectedResult] = useState(null); // State to store selected result for editing
  const [openDialog, setOpenDialog] = useState(false); // State to control dialog open/close
  const imageRef = useRef();

  const { data, isLoading, isError, error, refetch } = useFetchObjects(
    ["echo-results"],
    `echo-results/?search=${searchTerm}`,
    token,
  );
  const { handleDelete, ConfirmDialog } = useDelete("echo-results", token);

  useEffect(() => {
    refetch();
  }, [searchTerm]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    const imgElement = imageRef.current;
    if (imgElement && imgElement.requestFullscreen) {
      imgElement.requestFullscreen();
    }
  };

  const handleEdit = (result) => {
    setSelectedResult(result); // Set selected result
    setOpenDialog(true); // Open the dialog
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog
    setSelectedResult(null); // Clear selected result
  };
  const tableCellStyle = {
    fontWeight: "bold",
    textAlign: "center",
  };
  const tableStyle = {
    textAlign: "center",
  };

  return (
    <Box>
      {/* Search Bar */}
      <Box mb={4}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {t("echo.echoResult")}
        </Typography>
        <TextField
          label={t("echo.searchByPatientOrEchoType")}
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: "100%", mt: 2 }}
        />
      </Box>

      <Divider sx={{ marginY: 3 }} />

      {/* Loading State */}
      {isLoading && (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      )}

      {/* Error State */}
      {isError && (
        <Box mt={4}>
          <Alert severity="error">
            {t("echo.error")}: {error.message}
          </Alert>
        </Box>
      )}

      {/* Echo Result List */}
      {data && data.length > 0 && (
        <TableContainer
          component={Paper}
          sx={{ marginTop: 3, boxShadow: 3, borderRadius: 2 }}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                <TableCell style={tableCellStyle}>
                  {t("echo.patientName")}{" "}
                </TableCell>
                <TableCell style={tableCellStyle}>
                  {t("echo.echoType")}{" "}
                </TableCell>
                <TableCell style={tableCellStyle}>
                  {t("echo.findings")}
                </TableCell>
                <TableCell style={tableCellStyle}>
                  {t("echo.resultDate")}{" "}
                </TableCell>
                <TableCell style={tableCellStyle}>{t("echo.image")}</TableCell>
                <TableCell style={tableCellStyle}>
                  {t("echo.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((result) => (
                <TableRow
                  key={result.id}
                  hover
                  sx={{
                    transition: "0.3s",
                    "&:hover": { backgroundColor: "#f0f0f0" },
                  }}
                >
                  <TableCell style={tableStyle}>
                    {result.patient_display}
                  </TableCell>
                  <TableCell style={tableStyle}>
                    {result.echo_type_display}
                  </TableCell>
                  <TableCell style={tableStyle}>
                    {result.findings || t("echo.noFindings")}
                  </TableCell>
                  <TableCell style={tableStyle}>
                    {new Date(result.result_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell style={tableStyle}>
                    {result.image ? (
                      <img
                        src={result.image}
                        alt="Echo"
                        width="50"
                        height="50"
                        style={{ cursor: "pointer", borderRadius: "5px" }}
                        onClick={() => handleImageClick(result.image)}
                        ref={imageRef}
                      />
                    ) : (
                      t("echo.noImage")
                    )}
                  </TableCell>
                  <TableCell style={tableStyle}>
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(result)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(result.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!isLoading && data?.length === 0 && (
        <Box mt={4}>
          <Alert severity="info"> {t("echo.noEchoResult")}</Alert>
        </Box>
      )}
      <ConfirmDialog />

      {selectedResult && (
        <UpdateEchoResultDialog
          open={openDialog}
          onClose={handleCloseDialog}
          result={selectedResult}
          token={token}
          refetch={refetch}
        />
      )}
    </Box>
  );
};

export default ListEchoResult;
