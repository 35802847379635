import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Switch,
  FormControlLabel,
  Paper,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/system";
import useFetchObjects from "../../../../../api/useFetchObjects";
import useAdd from "../../../../../api/useAdd";
import { useAuth } from "../../../../../context";
import ListXrayReferral from "./ListXrayReferral";
import { useTranslation } from "react-i18next";

// Styled Components
const FullWidthPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#ffffff",
  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 700,
  color: "#3a3a3a",
  textAlign: "left",
  marginBottom: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  fontWeight: 700,
  fontSize: "1rem",
  padding: "12px 16px",
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const XRayReferral = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const { data: xrayTypesData } = useFetchObjects(
    ["xray-types"],
    "xray-types/",
    token,
  );
  const { data: patientsData } = useFetchObjects(
    ["patients"],
    "patients/",
    token,
  );

  const { handleAdd, resetForm } = useAdd("patient-xray", token);

  const xrayTypes = xrayTypesData || [];
  const patients = patientsData?.results || [];

  const getCurrentDate = () => new Date().toISOString().split("T")[0];

  const [xrayDetails, setXrayDetails] = useState({
    xrayType: "",
    testDate: getCurrentDate(),
    notes: "",
    isUrgent: false,
    totalAmount: "",
    patient: null,
    doctor: auth?.user?.user?.id,
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (resetForm) {
      setXrayDetails({
        xrayType: "",
        testDate: getCurrentDate(),
        notes: "",
        isUrgent: false,
        totalAmount: "",
        patient: null,
        doctor: auth?.user?.user?.id,
      });
      setErrors({});
    }
  }, [resetForm]);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setXrayDetails((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "xrayType") {
      const selectedType = xrayTypes.find(
        (type) => type.id.toString() === value,
      );
      setXrayDetails((prev) => ({
        ...prev,
        totalAmount: selectedType ? selectedType.cost.toString() : "",
      }));
    }

    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!xrayDetails.patient) {
      tempErrors.patient = t("doctor.patientRequired");
    }
    if (!xrayDetails.xrayType) {
      tempErrors.xrayType = t("doctor.xrayTypeRequired");
    }
    if (!xrayDetails.testDate) {
      tempErrors.testDate = t("doctor.testDateRequired");
    }
    if (!xrayDetails.totalAmount) {
      tempErrors.totalAmount = t("doctor.totalAmountRequired");
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const payload = {
        patient: xrayDetails.patient.id,
        assigned_doctor: xrayDetails.doctor,
        xray_type: xrayDetails.xrayType,
        date: xrayDetails.testDate,
        is_urgent: xrayDetails.isUrgent,
        total_amount: xrayDetails.totalAmount,
        doctor_notes: xrayDetails.notes,
      };
      handleAdd(payload);
    }
  };

  return (
    <FullWidthPaper>
      <SectionTitle>{t("doctor.x-rayReferralForm")}</SectionTitle>

      <Grid container spacing={3}>
        {/* Patient Autocomplete */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={patients}
            getOptionLabel={(option) =>
              option
                ? `${option.id} - ${option.firstname} ${option.lastname}`
                : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("doctor.selectPatient")}
                variant="outlined"
                fullWidth
                error={!!errors.patient}
                helperText={errors.patient}
              />
            )}
            value={xrayDetails.patient}
            onChange={(event, newValue) => {
              setXrayDetails((prev) => ({ ...prev, patient: newValue }));
              setErrors((prev) => ({ ...prev, patient: "" }));
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!errors.xrayType}>
            <InputLabel>{t("doctor.xrayType")}</InputLabel>
            <Select
              name="xrayType"
              value={xrayDetails.xrayType}
              onChange={handleChange}
              displayEmpty
            >
              {xrayTypes.map((type) => (
                <MenuItem key={type.id} value={type.id.toString()}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
            {errors.xrayType && (
              <Typography color="error" variant="caption">
                {errors.xrayType}
              </Typography>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="testDate"
            label={t("doctor.testDate")}
            type="date"
            fullWidth
            value={xrayDetails.testDate}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            error={!!errors.testDate}
            helperText={errors.testDate}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="totalAmount"
            label={t("doctor.totalAmount")}
            type="number"
            fullWidth
            value={xrayDetails.totalAmount}
            onChange={handleChange}
            error={!!errors.totalAmount}
            helperText={errors.totalAmount}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={xrayDetails.isUrgent}
                onChange={handleChange}
                name="isUrgent"
              />
            }
            label={t("doctor.urgent")}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="notes"
            label={t("doctor.notes")}
            multiline
            rows={4}
            fullWidth
            value={xrayDetails.notes}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <StyledButton fullWidth onClick={handleSubmit}>
            {t("doctor.submit")}
          </StyledButton>
        </Grid>
      </Grid>

      <Box mt={5}>
        <ListXrayReferral />
      </Box>
    </FullWidthPaper>
  );
};

export default XRayReferral;
