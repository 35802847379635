import React from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Paper,
} from "@mui/material";
import { useTranslation } from "react-i18next";

// Function to calculate the percentage of an amount from the total amount
const calculatePercentage = (amount, totalAmount) => {
  return totalAmount ? ((amount / totalAmount) * 100).toFixed(2) : "0";
};

const SurgeryDetailsDialog = ({ open, onClose, surgery }) => {
  const { t } = useTranslation();
  if (!surgery) return null;

  const { total_surgery_amount: totalAmount } = surgery;
  const isCotreh = surgery.surgery_type_display === "Cotreh";

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h5" fontWeight="bold" color="primary">
          {t("operations.surgeryDetails")}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        {/* Patient Information */}
        <Box mb={3}>
          <Typography
            variant="h6"
            fontWeight="bold"
            color="textSecondary"
            gutterBottom
          >
            {t("operations.patientInfo")}
          </Typography>
          <Paper
            elevation={1}
            sx={{ p: 2, borderRadius: "8px", backgroundColor: "#f9f9f9" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  <strong>{t("operations.patientName")}:</strong>{" "}
                  {surgery.patient_display}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  <strong>{t("operations.surgeryDate")}:</strong>{" "}
                  {new Date(surgery.surgery_date).toLocaleDateString()}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  <strong>{t("operations.surgeryType")}:</strong>{" "}
                  {surgery.surgery_type_display}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  <strong>{t("operations.status")}:</strong>{" "}
                  {surgery.surgery_state_display}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Box>

        {/* Surgery Team Details */}
        <Box mb={3}>
          <Typography
            variant="h6"
            fontWeight="bold"
            color="textSecondary"
            gutterBottom
          >
            {t("operations.surgeryTeam")}
          </Typography>
          <Paper
            elevation={1}
            sx={{ p: 2, borderRadius: "8px", backgroundColor: "#f9f9f9" }}
          >
            <Grid container spacing={2}>
              {surgery.doctor && (
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>{t("operations.doctor")}:</strong> {surgery.doctor}
                  </Typography>
                  {surgery.doctor_percentage && (
                    <Typography variant="body2" color="textSecondary">
                      <strong>{t("operations.amount")}:</strong>{" "}
                      {`؋${surgery.doctor_percentage}`}
                    </Typography>
                  )}
                  {surgery.doctor_percentage && (
                    <Typography variant="body2" color="textSecondary">
                      <strong>{t("operations.percentage")}:</strong>{" "}
                      {`${calculatePercentage(
                        surgery.doctor_percentage,
                        totalAmount
                      )}%`}
                    </Typography>
                  )}
                </Grid>
              )}
              {surgery.co_doctor && (
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>{t("operations.coDoctor")}:</strong>{" "}
                    {surgery.co_doctor}
                  </Typography>
                  {surgery.co_doctor_percentage && (
                    <Typography variant="body2" color="textSecondary">
                      <strong>{t("operations.amount")}:</strong>{" "}
                      {`؋${surgery.co_doctor_percentage}`}
                    </Typography>
                  )}
                  {surgery.co_doctor_percentage && (
                    <Typography variant="body2" color="textSecondary">
                      <strong>{t("operations.percentage")}:</strong>{" "}
                      {`${calculatePercentage(
                        surgery.co_doctor_percentage,
                        totalAmount
                      )}%`}
                    </Typography>
                  )}
                </Grid>
              )}
              {surgery.anesthetist && (
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>{t("operations.anesthetist")}:</strong>{" "}
                    {surgery.anesthetist}
                  </Typography>
                  {surgery.anesthetist_percentage && (
                    <Typography variant="body2" color="textSecondary">
                      <strong>{t("operations.amount")}:</strong>{" "}
                      {`؋${surgery.anesthetist_percentage}`}
                    </Typography>
                  )}
                  {surgery.anesthetist_percentage && (
                    <Typography variant="body2" color="textSecondary">
                      <strong>{t("operations.percentage")}:</strong>{" "}
                      {`${calculatePercentage(
                        surgery.anesthetist_percentage,
                        totalAmount
                      )}%`}
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </Paper>
        </Box>

        {/* Financial Breakdown */}
        <Box mb={3}>
          <Typography
            variant="h6"
            fontWeight="bold"
            color="textSecondary"
            gutterBottom
          >
            {t("operations.financialBreakdown")}
          </Typography>
          <Paper
            elevation={1}
            sx={{ p: 2, borderRadius: "8px", backgroundColor: "#f9f9f9" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  <strong>{t("operations.totalSurgeryAmount")}:</strong>{" "}
                  {totalAmount} ؋
                </Typography>
              </Grid>
              {isCotreh && (
                <>
                  {surgery.pharmacy_percentage && (
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        <strong>{t("operations.pharmacy")}:</strong>
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        <strong>{t("operations.amount")}:</strong>{" "}
                        {`؋${surgery.pharmacy_percentage}`}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        <strong>{t("operations.percentage")}:</strong>{" "}
                        {`${calculatePercentage(
                          surgery.pharmacy_percentage,
                          totalAmount
                        )}%`}
                      </Typography>
                    </Grid>
                  )}
                  {surgery.xray_percentage && (
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        <strong>{t("operations.xray")}:</strong>
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        <strong>{t("operations.amount")}:</strong>{" "}
                        {`؋${surgery.xray_percentage}`}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        <strong>{t("operations.percentage")}:</strong>{" "}
                        {`${calculatePercentage(
                          surgery.xray_percentage,
                          totalAmount
                        )}%`}
                      </Typography>
                    </Grid>
                  )}
                  {surgery.laboratory_percentage && (
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        <strong>{t("operations.laboratory")}:</strong>
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        <strong>{t("operations.amount")}:</strong>{" "}
                        {`؋${surgery.laboratory_percentage}`}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        <strong>{t("operations.percentage")}:</strong>{" "}
                        {`${calculatePercentage(
                          surgery.laboratory_percentage,
                          totalAmount
                        )}%`}
                      </Typography>
                    </Grid>
                  )}
                  {surgery.ultrasound_percentage && (
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        <strong>{t("operations.ultrasound")}:</strong>
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        <strong>{t("operations.amount")}:</strong>{" "}
                        {`؋${surgery.ultrasound_percentage}`}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        <strong>{t("operations.percentage")}:</strong>{" "}
                        {`${calculatePercentage(
                          surgery.ultrasound_percentage,
                          totalAmount
                        )}%`}
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          {t("operations.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SurgeryDetailsDialog;
